import * as React from 'react';
import { useHistory } from 'react-router';

import { PaymentService } from 'app2/api';
import { Body, Dropdown, Link, Form, finishAllScrolling, Modal, SaveableResult, useForm, Wizard, WizardApi, useDeepCompareMemo, useCurrentShieldProvider, useLifecycle } from 'app2/components';
import { clearCartNotificationTimeouts, EmbedBackLink, errorPathTransform, useFamilyParameter } from 'app2/views';

import { ParentPage } from '../shared';

import { parentCheckOut, useParentUserWithCartQuery } from './generated'
import { CheckoutModel } from './CheckoutModel';
import { Cart } from './Cart'
import { AdditionalInfo } from './AdditionalInfo';
import { Payment, affirmCheckout, alipayCheckout } from './payment'
import { PurchaseSummary } from './PurchaseSummary';
import { CheckoutFamilyPicker } from './family-picker';

export function Checkout() {
  const [userResult] = useParentUserWithCartQuery();
  const user = useDeepCompareMemo(userResult?.data?.user);
  const cart = user?.cart;
  const [purchase, setPurchase] = React.useState<{purchase:CheckoutModel, statementDescriptor:string}>(null);

  const form = useForm<CheckoutModel>(() => {
    const existingCard = user?.cart?.family?.creditCard;
    const existingUsBankAccount = user?.cart?.family?.usBankAccount;

    return {
      user,
      cart,
      type: user?.cart?.paymentService != PaymentService.Card || !!existingCard ? user?.cart?.paymentService : null,
      existingCard,
      existingUsBankAccount
    };
  }, [user], {alwaysSave: true})

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const autoModeSubmitted = React.useRef(false);
  const {autoMode, shield, wizard} = checkForAutoMode();

  ensureFamilyIdCorrect();
  calcCheckoutDisabled();

  function render() {
    return <ParentPage navigation={false} useMaxWidth={true} back={<EmbedBackLink />} pt={0} opacity={autoMode ? 0 : 1} familyDropdown={{onChange: onFamilyChange}}>
      {!cartLoaded()
        ? renderLoading()
        : purchase
          ? renderPurchaseSummary()
          : !hasCartItems()
            ? renderEmpty()
            : renderCheckout()}
    </ParentPage>
  }

  function renderLoading() {
    return '';
  }

  function renderEmpty() {
    return <Body>Your cart is empty</Body>
  }

  function renderPurchaseSummary() {
    return <PurchaseSummary purchase={purchase?.purchase || form.values} statementDescriptor={purchase?.statementDescriptor} />
  }

  function renderCheckout() {
    return <Form form={form} onNavigation="nothing" onOk={onSubmit} editing overflow='unset' pr='0' mr='0'>
      <Wizard ref={wizard} type='hide' containerBreakpoints={false} history='replace' steps title width="100%" maxWidth="1100px" baseUrl="/checkout/:family#:hash" tabs={[{
            icon: 'ShoppingCart',
            label: 'Cart',
            content: <Cart form={form} />,
            prev: <Link button="secondary" to={`/sites/${getMainCartSite()}`}>Add more activities</Link>,
            next: 'Continue',
            name: 'cart',
          }, {
            icon: 'Info',
            label: 'Additional info',
            content: <AdditionalInfo user={user} />,
            next: 'Continue to payment',
            name: 'info'
          }, {
            icon: 'DollarSign',
            label: 'Payment',
            content: <Payment />,
            name: 'payment'
          }
        ]}
      />
    </Form>
  }

  function checkForAutoMode() {
    const autoMode = getIsAutoMode();

    useLifecycle({onUpdate});
    const shield = useCurrentShieldProvider();
    const wizard = React.useRef<WizardApi>();

    return {history, autoMode, shield, wizard};
  }

  function getIsAutoMode() {
    return params.has('auto') || getIsAlipayRedirect()
  }

  function getIsAlipayRedirect() {
    return params.has('redirect_status');
  }

  function getAlipayFailed() {
    return params.get('redirect_status') != 'succeeded';
  }

  function getAlipayPi() {
    return params.get('payment_intent');
  }

  function removeAutoMode() {
    if (!getIsAutoMode()) {
      return;
    }

    history.replace(history.location.pathname + history.location.hash);
  }

  async function onUpdate() {
    if (!autoMode || !cartLoaded() || shield.showingEither || autoModeSubmitted.current) {
      return;
    }

    const results = await form.presubmitAll({skipValidatingEmpty:false, markSubmitted:false});

    // if during presubmit a loader kicked in, then something 
    // else started loading and we'll get called again when its finished

    if (shield.showingEither) {
      return;
    }

    if (!getIsAutoMode()) {
      return;
    }

    if (!results) {
      removeAutoMode();
      return;
    }

    // if its auto, from family purchasing options, we stop
    // before submit so they can enter a discount
    if (cart.total > 0 && !getIsAlipayRedirect()) {
      removeAutoMode();
      return;
    }

    if (wizard.current.selected < 2) {
      wizard.current.next();
    }
    else if (getAlipayFailed()) {
      removeAutoMode();
    }
    else {
      autoModeSubmitted.current = true;      
      finishAllScrolling();
      onSubmit();
    }
  }

  function cartLoaded() {
    return !!form.values.cart;
  }

  function hasCartItems() {
    return !!form.values?.cart?.enrollments?.length;
  }

  function getMainCartSite() {
    // the assumption is that the user is probably only purchasing
    // from one site so we just take the first cart items products site
    return  form.values.cart.sites[0].slug;
  }

  async function onSubmit() {
    const preCheckOutResult  = await preCheckOut();

    if (!preCheckOutResult) {
      removeAutoMode();
      Modal.error('Error', 'Please select a payment method');
      return;
    }

    if (!preCheckOutResult.success) {
      removeAutoMode();
      return;
    }

    const { paymentService, paymentMethod } = preCheckOutResult;

    clearCartNotificationTimeouts();

    const [success, result] = await parentCheckOut({
      variables: { cart: buildCartInput(), paymentService, paymentMethod },
      successMsg: 'Success',
      error: { handler: form, transform: [errorPathTransform('paymentService', 'type')] }
    });

    if (success) {
      setPurchase({purchase:form.values, statementDescriptor:result.data?.cartCheckout?.statementDescriptor});
    }

    removeAutoMode();

    return success;
  }

  async function preCheckOut(): Promise<{ paymentService: PaymentService, paymentMethod?: string; success: boolean; }> {
    let paymentService = form.values.type;
    let paymentMethod = '';
    let success = true;

    if (paymentService == PaymentService.Affirm && form.values.cart.total > 0) {
      const result = await affirmCheckout(form.values.cart);

      paymentMethod = result?.checkout_token;
      success = paymentMethod != null && !result.reason
    }
    else 
    if (paymentService == PaymentService.Ach) {
      paymentMethod = form.values.existingUsBankAccount?.fingerprint;
      success = paymentMethod != null
    }
    else
    if (paymentService == PaymentService.Card) {
      paymentMethod = form.values.existingCard?.last4;
      success = paymentMethod != null
    }
    else
    if (paymentService == PaymentService.Alipay) {
      if (!getIsAlipayRedirect() && !cart.alipayToken?.completed) {
        if (cart.alipayToken) {
          // because this generally navigates the page, if this returns it can only mean it was canceled
          // it was cancelled or the payment intent was already processed
          const result = await alipayCheckout(cart);
          success = result.action == SaveableResult.ok;
          paymentMethod = result.result;
        }
        else {
          success = false;
        }
      }
      else {
        paymentMethod = getAlipayPi() || cart.alipayToken?.id;
        success = paymentMethod != null;
      }
    }

    return { paymentService, paymentMethod, success };
  }

  function calcCheckoutDisabled() {
    let paymentService = form.values.type;

    let disabled = form.values.checkoutDisabled || !form.values?.cart;

    if (form.values?.cart && form.values.cart.total == 0) {
      disabled = false;
    }
    else
    if (paymentService == PaymentService.Affirm && form.values.cart.total > 0) {
      // affirm is processed on checkout
      disabled = false;
    }
    else
    if (paymentService == PaymentService.Ach) {
      disabled = !form.values.existingUsBankAccount?.fingerprint;
    }
    else
    if (paymentService == PaymentService.Card) {
      disabled = !form.values.existingCard?.last4;
    }

    if (form.values.checkoutDisabled != disabled) {
      form.setValue('checkoutDisabled', disabled);
    }
  }

  function buildCartInput() {
    const cart = form.values.cart;
    const enrollments = cart.enrollments.map(i => ({
      id: i.id,
      discountIds: i.discountUses.map(use => use.id),
      dismissal: i.dismissal,
      pickup: i.pickup,
      studentId: form.values.user.cart.family.students.find(s => s.id === i?.student?.id)?.id || i.student?.id,
      enrollmentPriceTierUseId: i.enrollmentPriceTierUse?.id || null
    }));

    return {
      donation: buildDonationInput(),
      enrollments
    };
  }

  function buildDonationInput() {
    if (!cart.donation?.initialAmount) {
      return null;
    }

    return {
      id: cart.donation?.id,
      coverTxnFees: cart.donation?.coverTxnFees,
      initialAmount: cart.donation.initialAmount
    };
  }

  function onFamilyChange(event:React.ChangeEvent<Dropdown>) {
    const family = event.target.value;
    CheckoutFamilyPicker.setFamily(family.id)
  }

  function ensureFamilyIdCorrect() {
    const history = useHistory();
    const familyUrlParam = useFamilyParameter();
    useLifecycle({onUpdate});

    function onUpdate() {
      if (purchase || !cart?.enrollments?.length) {
        return;
      }

      if (cart && !cart.family) {
        CheckoutFamilyPicker.show();
        return;
      }

      if (!cart?.family?.id || cart.family.id == familyUrlParam) {
        return;
      }

      history.replace(`/checkout/${cart.family.id}`);
    }
  }

  return render();
}
