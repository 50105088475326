import * as React from 'react';

import { caselessEqual, DiscountDefinition, DiscountDefinitionFormat, DiscountKind, Student } from 'app2/api';
import { InputField, DataTableColumn, DropdownField, FieldInfo, FieldProps, FieldRendererProps, formatCurrency, formatPercent, PercentField, Tag, palette, parseBoolean } from 'app2/components';
import { purchaseableKinds } from 'app2/views/shared-public';

import { StudentDropdown } from '../student';
import { CoursePicker, ownerToCourseQueryVars, PurchaseableCourseKindField } from '../course';
import { createRateCombo } from '../currency';

interface DiscountFieldProps {
  // the discounts owner (which can be site or season or theoritically course)
  ownerKind:string;
  ownerId:string;

  // the users current entity (site or company)
  entityKind:string;
  entityId:string;
}

type PartialDiscDef = Partial<DiscountDefinition>;
type DiscDefProps = FieldProps<PartialDiscDef, any, any>;
type DiscDefInfo = FieldInfo<PartialDiscDef>;

export function discountDefinitionFields<T extends PartialDiscDef = any>(props:DiscountFieldProps) {
  const amount =  {
    label: "Amount off",
    name: 'amount',
    names: ['amount', 'format'],
    required: true,
    edit: DiscountCombo,
    display: DiscountCombo,
    width: 200,
    sortable: true,
    filterable: true,
    copy: (value: any, field?:DiscDefProps, info?:DiscDefInfo) => formatDiscount(info.record),
    paste: (value: any) => parseDiscount(value),
    meta: {
      serverName: 'formattedAmount'
    }
  };

  return [{
    label: "Code",
    name: 'code' as keyof T,
    required: true,
    component: InputField,
    format: (val: string) => val?.replace(/\s/g, '')?.toUpperCase(),
    sortable: true,
    filterable: true
  }, {
    label: "Status",
    name: 'active',
    required: true,
    none: false,
    edit: { ...DropdownField, placeholder: 'Select a status', options: statusOptions },
    display: { render: renderStatus, stretch: false },
    paste: parseStatus,
    sortable: true,
    filterable: true,
    meta: {
      serverName: 'formattedActive'
    }
  }, {
    label:"Type",
    name: "kind",
    required: true,
    none: false,
    edit: { ...DropdownField, placeholder: 'Select a type', options: kindOptions, minDropdownWidth: 150 },
    display: { format: (val:DiscountKind) => kindLabels[val] },
    sortable: true,
    filterable: true,
    meta: {
      serverName: 'formattedKind'
    }
  }, 
  amount, {
    ...amount,
    ...PercentField,
    paste: (value: any) => parseDiscount(value, DiscountDefinitionFormat.Percentage),
    onChange: (val:any, info:FieldInfo<Partial<PartialDiscDef>, 'amount'>) => info.form.setValue(info.parents, 'format', DiscountDefinitionFormat.Percentage),
    id: 'percentAmount',
  }, {
    label:"Used",
    name:"usesCount",
    readOnly:true
  },  {
    name: 'student',
    label: 'Student',
    width: 250,
    required: true,
    sortable: true,
    filterable: true,
    edit: <StudentDropdown entityKind={props.entityKind} entityId={props.entityId} />,
    display: <StudentDropdown entityKind={props.entityKind} entityId={props.entityId} />,
    copy: StudentDropdown.copy,
    paste: (student:string | Partial<Student>) => StudentDropdown.find(student, props),
  }, {
    ...PurchaseableCourseKindField,
    name: 'scope.courseKinds',
    label: 'Apply to specific activity types',
    placeholder: 'Select activity types',
    width: 250,
  }, props.ownerKind != 'site' && {
    name: 'courses',
    label:'Apply to specific activities',
    edit: CoursePicker,
    display: CoursePicker,
    placeholder: 'Select activities',
    multiple: true,
    queryVars: {...ownerToCourseQueryVars(props), kinds: purchaseableKinds, valueType: 'id'},
    paste: (val:any) => Array.isArray(val) ? val : [],
    width: 250,
  }]  as DataTableColumn<T>[];
}


function renderStatus(props: FieldRendererProps<boolean>) {
  const option = statusOptions.find(o => o.value === props.info.value);
  return <Tag label={option.label} bg={option.bg} icon={null} />;
}

function parseStatus(status:any) {
  const boolVal = parseBoolean(status);
  const option = statusOptions.find(o => caselessEqual(o.label, status) || o.value === boolVal);

  return option ? option.value : status;
}

const statusOptions = [
  { label: 'Active', value: true, bg: palette.secondary.lightGreen.hex },
  { label: 'Draft', value: false, bg: palette.secondary.lightYellow.hex }
];

const kindLabels = {
  [DiscountKind.Activity]: 'Code',
  [DiscountKind.Sibling]: 'Sibling (automatic)'
};

const kindOptions = Object.values(DiscountKind).map(k => ({ label: kindLabels[k], value: k }));

const DiscountCombo = createRateCombo<'format', 'amount', DiscountDefinitionFormat>('format', 'amount', DiscountDefinitionFormat.Fixed, DiscountDefinitionFormat.Percentage, ['fixed', 'percentage']);

function formatDiscount(dd:PartialDiscDef) {
  return dd.format == DiscountDefinitionFormat.Fixed ? formatCurrency(dd.amount) : formatPercent(dd.amount);
}

function parseDiscount(dd:any, format?:DiscountDefinitionFormat) {
  if (dd && typeof dd == 'object' && 'format' in dd && 'amount' in dd && (dd.format == DiscountDefinitionFormat.Fixed || dd.format == DiscountDefinitionFormat.Percentage)) {
    return {format: format || dd.format, amount: Number(dd.amount)};
  }

  if (typeof dd == 'number') {
    return {format: format || DiscountDefinitionFormat.Fixed, amount: dd}
  }

  if (typeof dd != 'string') {
    return {format: format || DiscountDefinitionFormat.Fixed, amount: 0}
  }

  dd = dd.trim();

  if (dd.endsWith('%')) {
    return {format: format || DiscountDefinitionFormat.Percentage, amount: parseFloat(dd.substring(0, dd.length - 1))}
  }

  if (dd.startsWith('$')) {
    return {format: format || DiscountDefinitionFormat.Fixed, amount: parseFloat(dd.substring(1))}
  }

  return {format: format || DiscountDefinitionFormat.Fixed, amount: parseFloat(dd)}
}
