import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerUpdateProrationMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  prorationConfig: Types.ProrationConfigInput;
}>;

export type OrganizerUpdateProrationMutation = { siteUpdateGeneralInfo?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerUpdateProrationDocument = gql`
  mutation OrganizerUpdateProration($siteId: ID!, $prorationConfig: ProrationConfigInput!) {
    siteUpdateGeneralInfo(site: $siteId, prorationConfig: $prorationConfig) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerUpdateProrationOptions = ExecMutationOptions<OrganizerUpdateProrationMutationVariables>;

export function organizerUpdateProration(options: OrganizerUpdateProrationOptions) {
  return executeMutation<OrganizerUpdateProrationMutation, OrganizerUpdateProrationMutationVariables>(OrganizerUpdateProrationDocument, options);
}
