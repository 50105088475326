import * as React from 'react';

import { Enrollment, EnrollmentStatusEnum, PaymentStatus } from 'app2/api';
import { HBox, BoxProps, theme, FieldInfo } from 'app2/components';

interface Props extends BoxProps {
  paymentStatus: PaymentStatus;
  paymentFailureReason?: string;
  // label is an alternative label than the default
  // for example if you want to use the status bg color and tooltip but put an amount in
  label?: React.ReactNode;
  info?: FieldInfo<Enrollment, 'paymentStatus', 'paymentFailureReason'>;
}

export function PaymentStatusTag(props: Props) {
  const { paymentStatus, info, label, paymentFailureReason:propsPaymentFailureReason, ...remaining } = props;
  const statusProps = paymentStatuses[paymentStatus?.toUpperCase() as PaymentStatus];
  const removed = info?.record?.status === EnrollmentStatusEnum.RemovedFromCourse;
  const paymentFailureReason = propsPaymentFailureReason || info?.record?.paymentFailureReason;

  if (!statusProps) {
    return <></>;
  }

  return (
    <>
      <HBox bg={statusProps.bg} color={statusProps.color} text="formlabel" borderRadius="standard" px="$12" py="4.5px" {...remaining} fontWeight={700} width="fit-content" cursor="default" tooltip={paymentFailureReason ? paymentFailureReason : undefined}>
        {label || statusProps.label}
      </HBox>
      {removed && (
        <HBox ml="8px" text="formlabel" bg={theme.colors.error} borderRadius="standard" px="$12" py="4.5px" {...remaining} fontWeight={700} color="white">
          Removed
        </HBox>
      )}
    </>
  );
}

PaymentStatusTag.fieldProps = {
  valueProperty: 'paymentStatus',
  fieldProperty: 'info'
};

// NOTE: this component is used for charges and refunds.
// The statuses are slightly different for each, though they should be unified.
export const paymentStatuses = {
  [PaymentStatus.Pending]: { bg: theme.colors.disabledBackground, color: undefined as string, label: 'Pending' },
  [PaymentStatus.Invoiced]: { bg: theme.colors.warningFill, color: undefined as string, label: 'Invoiced' },
  [PaymentStatus.Processing]: { bg: theme.colors.warningFill, color: undefined as string, label: 'Processing' },
  [PaymentStatus.Canceled]: { bg: theme.colors.errorFill, color: undefined as string, label: 'Canceled' },
  [PaymentStatus.Paid]: { bg: theme.colors.successFill, color: undefined as string, label: 'Paid' },
  [PaymentStatus.RetriableError]: { bg: theme.colors.errorFill, color: undefined as string, label: 'Failed' },
  [PaymentStatus.Failed]: { bg: theme.colors.errorFill, color: undefined as string, label: 'Permanently failed' },
  // for refunds
  [PaymentStatus.Completed]: { bg: theme.colors.successFill, color: undefined as string, label: 'Completed' },
  [PaymentStatus.Ready]: { bg: theme.colors.warningFill, color: undefined as string, label: 'Processing' }
};
