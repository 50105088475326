import * as React from 'react';

import { EnrollmentUtils, Enrollment } from 'app2/api';
import { Body, VBox, Link, Modal, formatDate } from 'app2/components';

import { parentWithdraw } from './generated';


interface Props {
  enrollment:Enrollment;
}

export function WithdrawAction(props:Props) {
  const enrollment = props.enrollment;
  
  if (!enrollment) {
    return <></>;
  }

  function render() {
    return <VBox height="28px" hAlign="center" vAlign="center">
      <Link tooltip={`Withdraw ${enrollment.student.name} from ${enrollment.course.name}`} onClick={renderModal}>
        {enrollment.withdrawable ? 'Cancel' : ''}
      </Link>
    </VBox>
  }

  function renderModal() {
    return (
      <Modal title="Withdraw from activity?" ok="Yes, withdraw" onOk={onClickWithdraw}>
        <Body>Are you sure you want to withdraw <b>{enrollment.student.name}</b> from <b>{enrollment.course.name}</b>?</Body>
        <br />
        {!EnrollmentUtils.usingOngoing(enrollment) && <Body>You can withdraw up until {formatDate(enrollment.course.lastWithdrawDate, 'abbreviated')}.</Body>}
      </Modal>
    );
  }

  async function onClickWithdraw() {
    const [success] = await parentWithdraw({ variables: { enrollmentId: enrollment.id } });
    return success;
  }

  return render();
}

WithdrawAction.fieldProps = {
  valueProperty: 'enrollment'
}
