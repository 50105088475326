import * as React from 'react'
import pluralize from 'pluralize';

import { Button, Modal, Text, PopupManager, PopupManagerProps, Repeater, VBox } from 'app2/components';

import { CheckoutFamiliesSelections, setCartFamily, useCheckoutFamiliesQuery } from './generated';

export function CheckoutFamilyPicker(props:PopupManagerProps) {
  const [result] = useCheckoutFamiliesQuery();
  const user = result.data?.user;
  const families = user?.families;

  function render() {
    return <Modal title='Multiple family accounts' ok={null} cancelable={false}>
      <VBox gap='$16'>
        <Text text='body'>Hi {user?.name}!  Your account is linked to more than one family. Please choose which family you'd like to book.</Text>
        <Repeater items={families} render={renderFamily} layout='vbox' gap='$16' />
      </VBox>
    </Modal>
  }

  function renderFamily(family:CheckoutFamiliesSelections['families'][0]) {
    const parents = family.participants.filter(p => p.kind != 'contact')
    const parentNames = parents.map(m => m.name).join(', ');

    return <VBox hAlign='left' borderTop='solid 1px' borderWidth='standard' borderColor='border' py='$16' gap='$4'>
      <Text text='body'>{family.students.map(s => s.name).join(', ')}</Text>
      <Text text='body' bold>{pluralize('Parent', parents.length)}: {parentNames}</Text>
      <Text text='body'>{family.creditCard?.brand || family.usBankAccount?.bankName} xxxxxxxx{family.creditCard?.last4 || family.usBankAccount?.last4}</Text>
      <Button onClick={() => selectFamily(family.id)}>Select this family</Button>
    </VBox>
  }

  async function selectFamily(family:string) {
    const success = await CheckoutFamilyPicker.setFamily(family);

    if (success) {
      props.manager.remove();
    }

    return success;
  }
  
  return render();
}

CheckoutFamilyPicker.show = function() {
  if (PopupManager.hasModal) {
    return;
  }

  return PopupManager.addModal(<CheckoutFamilyPicker />, {cancelable: false});
}

CheckoutFamilyPicker.setFamily = async function(family:string) {
  const [success] = await setCartFamily({variables: {family}});

  return success
}