import moment from 'moment-timezone'
import { isFinite } from 'lodash-es';

import { parseTime } from '../TimePicker';

import { FieldProps } from "./Field";
import { FieldInfo } from "./FormModel";

export function stringToArray(s?: string) {
  return onlyString(s).split(',').map(entry => entry.trim());
}

export const floatParser = (amount: string, min?:number, max?:number) => {
  if (typeof amount == 'number') {
    return amount;
  }

  const amountString = String(amount || '').trim();
  const negative = amountString.startsWith('-');
  const stripped = amountString.replace(/[^0-9.]/g, '');

  if (!stripped) {
    return;
  }

  let parsedAmount = Number(stripped);
  parsedAmount = !isFinite(parsedAmount) ? 0 : negative ? -parsedAmount : parsedAmount;

  if (!isFinite(min)) {
    min = Number.MIN_SAFE_INTEGER;
  }

  if (!isFinite(max)) {
    max = Number.MAX_SAFE_INTEGER;
  }

  return Math.min(Math.max(parsedAmount, min), max);
};

// takes the incoming time and sets it on the existing date value in a form
export function dateFromTime(time:string, props:FieldProps<any, any, any>, info:FieldInfo<any>) {
  let existing = moment(info.value).clone();

  if (!existing.isValid()) {
    existing = moment();
  }

  const parts = parseTime(time);

  if (!parts) {
    return existing;
  }

  return existing.set({
    hour: parts.hours24,
    minute: parts.minutes,
    second: 0,
  });
}

export function createDateFromTimeParserTz(timezone: string) {
  return function (time:string, props:FieldProps<any, any, any>, info:FieldInfo<any>) {
    let existing = moment.tz(info.value, timezone).clone();

    if (!existing.isValid()) {
      existing = moment.tz("",timezone);
    }
  
    const parts = parseTime(time);
  
    if (!parts) {
      return existing;
    }

    return existing.set({
      hour: parts.hours24,
      minute: parts.minutes,
      second: 0,
    });
  }
}

// for an incoming date value, this copies the time
// value from the previous date value.  if there was
// no previous value, the default time is used.
export function defaultTime(time:string, timezone: string) {
  const defaultTimeStamp = moment(time, 'h:mm A');

  return (val:moment.Moment, props:FieldProps<any, any, any>, info:FieldInfo<any>) => {
    const copyFrom = info.value ? moment.tz(info.value, timezone) : defaultTimeStamp
    return !val ? null : val.clone().set({
      hour: copyFrom.get('hour'),
      minute: copyFrom.get('minute'),
      second: copyFrom.get('second'),
      millisecond: copyFrom.get('millisecond')
    });
  }
}

export function parsePhone(phone:string) {
  return phone?.replace(/\(|\)|\-|\s/g, '') || '';
}

export function isPhoneLike(phone:string) {
  return /^\d{0,10}$/.test(parsePhone(phone));
}

export function onlyString(value:any):string {
  if (Array.isArray(value)) {
    return value.map(onlyString).join(', ');
  }

  if (typeof value == 'string') {
    return value;
  }

  if (value === undefined || value === null) {
    return '';
  }

  if (typeof value === 'object' && (value.name || value.label || value.text)) {
    return onlyString(value.name || value.label || value.text);
  }

  return '';
}

export function onlyStringArray(value:any) {
  if (Array.isArray(value)) {
    return value.map(onlyString).filter(g => !!g);
  }
  else
  if (typeof value == 'string') {
    return stringToArray(value);
  }
  else {
    return [];
  }
}

// these take a momemnt and return an updated moment
// in the start/end of the day FOR the current timezone
// in the moment object
export function startOfDay(d:moment.Moment) {
  d = d ? moment(d) : null;
  return d && d.isValid() ? d.clone().startOf('day') : null;
}

export function endOfDay(d:moment.Moment) {
  d = d ? moment(d) : null;
  return d && d.isValid() ? d.clone().endOf('day') : null;
}

export function parseBoolean(val:string) {
  if (typeof val === 'boolean') {
    return val;
  }

  if (typeof val != 'string') {
    return undefined;
  }

  val = val.toLocaleLowerCase();

  return val == 'true' ? true : val == 'false' ? false : undefined;
}
