import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentProcessPaymentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ParentProcessPaymentMutation = { parentProcessPayment?: { status?: Types.PaymentStatus | null | undefined; message?: string | null | undefined } | null | undefined };

export const ParentProcessPaymentDocument = gql`
  mutation ParentProcessPayment($id: ID!) @urql(additionalTypenames: ["Receipt", "Order"]) {
    parentProcessPayment(id: $id) {
      status
      message
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentProcessPaymentOptions = ExecMutationOptions<ParentProcessPaymentMutationVariables>;

export function parentProcessPayment(options: ParentProcessPaymentOptions) {
  return executeMutation<ParentProcessPaymentMutation, ParentProcessPaymentMutationVariables>(ParentProcessPaymentDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Receipt', 'Order']) }
  });
}
