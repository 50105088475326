import * as React from 'react';

import { Button, CurrencyField, DateField, Field, FieldRendererProps, NotificationManager, NotificationProps } from 'app2/components';

import { CourseLink, QueryList, StudentLink, UserLink } from '../shared';

import { useAdminSiteProcessingRefundsQuery } from './generated';

export function ProcessingRefunds() {
  function render() {
    return <QueryList icon='Home' title='Processing refunds' query={useAdminSiteProcessingRefundsQuery} name='processingRefunds' fields={[
      <Field name='createdAt' label='Created' component={DateField} />,
      <Field name='parent.name' label='Parent' component={UserLink} />,
      <Field name='student.name' label='Student' component={StudentLink} />,
      <Field name='course.name' label='Activity' component={CourseLink} />,
      <Field name='amount' label='Amount' component={CurrencyField} />,
      <Field name='id' />,
    ]} />
  }

  return render();
}
