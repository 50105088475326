import * as React from 'react';

import { ParentPage } from 'app2/views';

import { AutomaticCharges } from './AutomaticCharges';
import { PaymentMethod } from './PaymentMethod';
import { Payments } from './Payments';
import { BilledLater } from './BilledLater';

export function Payment() {
  function render() {
    return <ParentPage title='Payment'>
      <PaymentMethod />
      <AutomaticCharges />
      <BilledLater />
      <Payments />
    </ParentPage>
  }

  return render();
}
