import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TableViewsQueryVariables = Types.Exact<{
  entityKind: Types.Scalars['String'];
  entityId: Types.Scalars['ID'];
  table: Types.Scalars['OptionValue'];
}>;

export type TableViewsQuery = {
  entity?:
    | {
        tableViewsUpsert?: boolean | null | undefined;
        tableViewsUpsertPersonal?: boolean | null | undefined;
        tableViews?:
          | Array<{
              id: string;
              updatedAt: string;
              name: string;
              visibility?: Types.TableViewVisibility | null | undefined;
              lockedCol?: number | null | undefined;
              groups?: Array<{ name: string; direction?: Types.SortDirection | null | undefined }> | null | undefined;
              cols: Array<{ name: string; width?: number | null | undefined }>;
              sorts?: Array<{ name: string; direction?: Types.SortDirection | null | undefined }> | null | undefined;
              filters?: Array<{ name: string; op?: Types.FilterOperator | null | undefined; value?: Array<string | number | null | undefined> | null | undefined }> | null | undefined;
            }>
          | null
          | undefined;
      }
    | {
        tableViewsUpsert?: boolean | null | undefined;
        tableViewsUpsertPersonal?: boolean | null | undefined;
        tableViews?:
          | Array<{
              id: string;
              updatedAt: string;
              name: string;
              visibility?: Types.TableViewVisibility | null | undefined;
              lockedCol?: number | null | undefined;
              groups?: Array<{ name: string; direction?: Types.SortDirection | null | undefined }> | null | undefined;
              cols: Array<{ name: string; width?: number | null | undefined }>;
              sorts?: Array<{ name: string; direction?: Types.SortDirection | null | undefined }> | null | undefined;
              filters?: Array<{ name: string; op?: Types.FilterOperator | null | undefined; value?: Array<string | number | null | undefined> | null | undefined }> | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const TableViewsDocument = gql`
  query tableViews($entityKind: String!, $entityId: ID!, $table: OptionValue!) {
    entity(entityKind: $entityKind, entityId: $entityId) {
      ... on WithTableViews {
        tableViewsUpsert
        tableViewsUpsertPersonal
        tableViews(filters: [{ name: "table", op: EQ, value: $table }]) {
          id
          updatedAt
          name
          visibility
          lockedCol
          groups {
            name
            direction
          }
          cols {
            name
            width
          }
          sorts {
            name
            direction
          }
          filters {
            name
            op
            value
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type TableViewsArgs = MakeOptional<UseQueryArgs<TableViewsQueryVariables, TableViewsQuery>, 'query'>;

export function useTableViewsQuery(options: TableViewsArgs = {}) {
  return useQuery<TableViewsQueryVariables, TableViewsQuery>({ query: TableViewsDocument, ...options });
}

export type TableViewsOptions = ExecQueryOptions<TableViewsQueryVariables>;

export function tableViews(options: TableViewsOptions) {
  return executeQuery<TableViewsQuery, TableViewsQueryVariables>(TableViewsDocument, options);
}

export type TableViewsSelections = TableViewsQuery['entity'];
