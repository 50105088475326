import { DataTable, DataTableColumn, Option, colName } from 'app2/components';

interface Options {
  variables:{filterOptions:string};
}

export type GetStandardFilterOptionsQuery = (options:Options | any) => Promise<any>;

export function getStandardFilterOptions<T>(query:GetStandardFilterOptionsQuery, variables:any) {
  return async function(table:DataTable<T>, col:DataTableColumn<T>):Promise<Option[]> {
    const [success, result] = await query({variables:{...variables, filterOptions: colName(col)}});
    let data = result.data;

    while (data && !data.filterOptions) {
      data = Object.values(data)[0];
    }

    return data?.filterOptions;
  }
}
