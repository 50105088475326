import * as React from 'react';

import { DiscountUse } from 'app2/api';
import { Tag, TagProps } from 'app2/components';

interface DiscountTagProps extends Omit<TagProps, 'id'>, Pick<DiscountUse, 'code' | 'formattedAmount'> {
}

export function DiscountTag(props: DiscountTagProps) {
  const { code, formattedAmount, ...rest } = props;
  return <Tag label={code} tooltip={formattedAmount} bg="border" {...rest} />;
}

DiscountTag.defaultProps = {
  fontSize: 12
}