import * as React from 'react';
import { pick } from 'lodash-es';

import { EntityKind, TableViewInput, TableViewVisibility } from 'app2/api';
import { colId, DataTable, dataTable, DataTableHandle, Dropdown, DROPDOWN_SEPARATOR, RadioGroup, ExpressionNode, FilterParser, FormModel, MenuDropdown, MenuItem, MenuSeparator, HBox, IconNames, Form, GroupedDataTableHandle, InputField, Icon, Modal, NEW_ITEM_PREFIX, SaveableResult, Section, TabletOrDesktop, TablePrefs, Text, TextAreaField } from 'app2/components';
import { Beta } from 'app2/views/shared-public';

import { buildSortFilter } from '../useSortFilterV2';
import { ChooseCols } from './ChooseCols';
import { TableViewsSelections, useTableViewsQuery, tableViewRemove, tableViewUpsert } from './generated';

export type TableView = TableViewsSelections['tableViews'][0];
const DEFAULT_VIEW_ID = '__default';

export interface TableViewProps {
  entityKind:EntityKind;
  entityId:string;
  table:string;
  tableRef:React.MutableRefObject<DataTableHandle>;
  prefs:TablePrefs;
  selected?:string;
  advancedFilter?:ExpressionNode;
  resetToDefaultView:() => void;
  // todo - it would be best if we could encapsulate TableViewsDropdown and not
  // need these callbacks and instead call directly into the prefs
  onChange:(view:TableView) => void;
  onChangeAdvancedFilter?:(filter:ExpressionNode) => void;
}

export function TableViewsDropdown(props:TableViewProps) {
  const {entityKind, entityId, table: tableName, tableRef} = props;

  const {views, permissions} = getTableViews();
  const allowSaveTeam = permissions.tableViewsUpsert;

  const selected = getSelectedView();
  const customViewSelected = selected?.id != DEFAULT_VIEW_ID;

  const viewItems = renderViewItems();
  const menuItems = renderMenuItems();
  const {options, items} = renderCombinedItemsList();

  const selectedItem = getSelectedViewOption();

  function render() {
    return <HBox gap='$4' flexWrap='nowrap' vAlign='center' minWidth='100px'>
      <TabletOrDesktop><Text text='formlabel'>View: </Text></TabletOrDesktop><MenuDropdown label={renderMenuLabel()} tag bg={customViewSelected ? 'enrollment' : '#fff'}>{items}</MenuDropdown>
    </HBox>
  }

  function getTableViews() {
    const [tableViews] = useTableViewsQuery({variables:{entityKind, entityId, table: tableName}});
    const views = (tableViews.data?.entity?.tableViews || []).
      concat({id: DEFAULT_VIEW_ID, updatedAt: undefined, visibility: TableViewVisibility.Team, name: 'Default view', cols:[]});

    return {views, permissions: pick(tableViews?.data?.entity, ['tableViewsUpsert', 'tableViewsUpsertPersonal'])};
  }

  function renderViewItems() {
    return React.useMemo(() => {
      return views?.map(v => renderViewName(v)) || [];
    }, [views]);
  }

  function renderViewName(v:TableView) {
    return renderItem(v.name, v, () => onChangeView(v), iconMap[v.visibility], undefined, v.id != DEFAULT_VIEW_ID ? tooltipMap[v.visibility] : undefined);
  }

  function renderMenuItems() {
    const allowMutateCurrent = customViewSelected && (allowSaveTeam || selected.visibility == TableViewVisibility.Personal);
    const allowSaveAs = permissions.tableViewsUpsertPersonal;

    return React.useMemo(() => {
      return [
        renderItem('Choose columns...', 'chooseCols', chooseCols),
        getTable()?.props?.group && renderItem('Group...', 'groupCols', groupCols),
        allowMutateCurrent && renderItem('Save view', 'save', onSave),
        allowSaveAs && renderItem('Save view as...','saveAs', onSaveAs),
        renderItem('Reset view', 'resetView', resetView),
        allowMutateCurrent && renderItem('Delete view', 'remove', onRemove),
        customViewSelected && selected.visibility != TableViewVisibility.Personal && allowSaveTeam && renderItem('Make private to me', 'makePrivate', onMakePrivate),
        customViewSelected && selected.visibility != TableViewVisibility.Team && allowSaveTeam && renderItem('Set as team view', 'makePublic', onMakePublic),
        // allowSaveTeam && renderItem('Custom filter...', 'customFilter', onCustomFilter, null, true),
        viewItems.length > 1 && DROPDOWN_SEPARATOR,
    ]}, [selected, permissions]);
  }

  function renderItem(label:string, value:any, onClick?:() => any, icon?:IconNames, beta?:boolean, tooltip?:string) {
    return {text: label, label: <HBox gap='$8' flexWrap='nowrap' vAlign='center'>
      <Text maxLines={1}>{label}</Text>
      {icon && <Icon name={icon} size='small' tooltip={tooltip} />}
    </HBox>, value, onClick, beta}
  }

  function renderMenuItem(option:Partial<typeof viewItems[0]>, index:number, selected:boolean) {
    if (selected) {
      return;
    }

    const menuItem = option.value == DROPDOWN_SEPARATOR.value ? <MenuSeparator key={index} /> : <MenuItem key={index} onClick={option.onClick}>{option.label}</MenuItem>

    return option.beta ? <Beta key={index}>{menuItem}</Beta> : menuItem;
  }

  function renderCombinedItemsList() {
    return React.useMemo(() => {
      const options = menuItems.concat(viewItems).filter(item => !!item);
      return {options, items: options.map((o, index) => renderMenuItem(o, index, o.value == selected))}
    }, [menuItems, viewItems]);
  }

  function renderMenuLabel() {
    return <HBox vAlign='center' gap='$8'>{selectedItem?.label}</HBox>
  }

  function getTable() {
    return dataTable(tableRef.current);
  }

  function getGroupedTable() {
    return tableRef.current as GroupedDataTableHandle;
  }

  function getSelectedView() {
    const selectedId = getSelectedViewId();
    return views?.find(v => v.id == selectedId) || views?.find(v => v.id == DEFAULT_VIEW_ID);
  }

  function getSelectedViewOption() {
    const selected = getSelectedView();
    return options?.find(v => v.value == selected);
  }

  function getSelectedViewId() {
    return props.prefs?.id;
  }

  async function chooseCols() {
    const t = getTable();
    const result = await Modal.show(<ChooseCols cols={t.allCols} />)

    if (result.action != SaveableResult.ok) {
      return;
    }

    // todo - it would be best if this went through prefs not the table
    t.setVisibleCols(result.result.visible);
  }

  async function groupCols() {
    //wip
    const t = getTable();
    const gt = getGroupedTable();
    const all = t.allCols.map(c => ({label: c.label, value: colId(c)}))
    const grouped = gt.props.groupBy?.map(g => (colId(g))) || [];
    const result = await Modal.show(<Modal title='Group columns'>
      <Form initialValues={{grouped}} onNavigation='nothing'>
       <Section name='grouped' component={Dropdown} options={all} selectedStyle='checkbox' multiple inlinelist list={{makeSelectionVisible:false}} />
      </Form>
    </Modal>)

    if (result.action != SaveableResult.ok) {
      return;
    }

    // todo - it would be best if this went through prefs not the table
    // gt.updateGroupedCols(result.result.grouped);
  }


  function onChangeView(view:TableView) {
    if (view.id == DEFAULT_VIEW_ID) {
      props.resetToDefaultView();
    }
    else {
      props.onChange(view);
    }
  }

  function resetView() {
    if (selected.id == DEFAULT_VIEW_ID) {
      props.resetToDefaultView();
    }
    else {
      props.onChange(selected);
    }
  }

  function onMakePrivate() {
    doSave(false, undefined, TableViewVisibility.Personal);
  }

  function onMakePublic() {
    doSave(false, undefined, TableViewVisibility.Team);
  }

  function getTeacherView() {
    return views.find(v => v.visibility == TableViewVisibility.Teacher);
  }

  async function onMakeTeacher() {
    const existing = getTeacherView();

    if (existing) {
      const result = await Modal.warning('New instructor view', `There can only be one instructor view.  Are you sure you want to remove ${existing.name}?`, true);

      if (result.action != SaveableResult.ok) {
        return;
      }
    }

    doSave(false, undefined, TableViewVisibility.Teacher);
  }

  async function onRemove() {
    const result = await Modal.warning('Delete view', `Are you sure you want to delete view ${selected.name}?`, true);

    if (result.action != SaveableResult.ok) {
      return;
    }

    const [success] = await tableViewRemove({variables: {entityKind, entityId, view: selected.id}, successMsg:`View ${selected.name} removed}`});

    return success;
  }

  async function onSave() {
    doSave(false);
  }

  async function onSaveAs() {
    const result = await Modal.show(<Modal title='Save view' ok='Save'>
      <Form initialValues={{visibility:  allowSaveTeam ? TableViewVisibility.Team : TableViewVisibility.Personal }}>
        <Section label='View name' placeholder='Enter a name for this view' name='name' component={InputField} />
        <Section label='Who can see this view? ' name='visibility' disabled={!permissions.tableViewsUpsert} component={RadioGroup} options={viewVisibilityOptions} />
      </Form>
    </Modal>);

    if (result.action != SaveableResult.ok) {
      return;
    }

    doSave(true, result.result.name, result.result.visibility);
  }

  async function doSave(saveAs?:boolean, name?:string, visibility?:TableViewVisibility) {
    const t = getTable();
    const sortFilter = buildSortFilter(t.allCols);
    const id = saveAs ? NEW_ITEM_PREFIX + '0' : selected.id;
    
    name ||= selected.name;

    const view:TableViewInput = {
      id,
      name,
      lockedCol: t.lockedCol,
      visibility: visibility ?? selected?.visibility,
      cols: t.cols.map(c => ({name: colId(c).toString(), width: c.width})),
      sorts: sortFilter.sorts,
      filters: sortFilter.filters,
      table: tableName
    }

    const [success, result] = await tableViewUpsert({variables: {entityKind, entityId, view}, successMsg:`View ${name} ${saveAs ? 'created' : 'updated'}`});

    if (success && saveAs) {
      const updatedViewsList = result.data.tableViewUpsert.entity.tableViews;
      const viewId = updatedViewsList.find(v => v.name == name)?.id;
      props.onChange({...view, id: viewId, updatedAt: new Date().toISOString()} as unknown as TableView);
    }

    return success;
  }

  async function onCustomFilter() {
    // wip
    const parser = new FilterParser(getTable().allCols);
    let filter = '';

    try {
     filter = parser.toString(props.advancedFilter);
    }
    catch(e) {
    }
    
    const result = await Modal.show(<Modal title='Custom filter'>
      <Form initialValues={{filter}} onOk={(form:FormModel) => parser.parse(form.values.filter)} onNavigation='nothing'>
        <Section name='filter' label='Filter' component={TextAreaField} validators={parser.validate} />
      </Form>
    </Modal>);

    if (result.action != SaveableResult.ok) {
      return;
    }

    const parsed = result.result?.filter !== undefined && result.result?.filter == '' ? null : result.result;
    props.onChangeAdvancedFilter(parsed)
  }

  return render();
}

const viewVisibilityOptions = [{
  label: 'Everyone on my team',
  value: TableViewVisibility.Team,
}, {
  label: 'Only me',
  value: TableViewVisibility.Personal,
}]

const iconMap:Record<TableViewVisibility, IconNames> = {
  [TableViewVisibility.Team]: 'Users',
  [TableViewVisibility.Personal]: 'User',
  [TableViewVisibility.Teacher]: 'CheckCircle'
}

const tooltipMap = {
  [TableViewVisibility.Team]: 'Team view',
  [TableViewVisibility.Personal]: 'Private view',
  [TableViewVisibility.Teacher]: 'Instructor view',
}
