import * as React from 'react'
import { useLocation, useParams } from 'react-router';
import { groupBy } from 'lodash-es';

import { CourseFilterOptions, CourseUtils } from 'app2/api';
import { hasAppMode, HBox, Tabs, Text, useForm, VBox } from 'app2/components';
import { PublicPage, InactiveWarning, useCart } from 'app2/views/shared-public';

import { PublicSiteParams } from '../../publicRoutes';
import { SiteHeader } from '../shared';

import { Filter } from './Filter';
import { Season } from './Season';
import { PublicSeason } from './PublicSeason';
import { usePublicSiteQuery } from './generated';

export function Site() {
  const location = useLocation();
  const privateSeasons = location.pathname.startsWith('/sites/private');

  const filterOptions = useForm<CourseFilterOptions>({}, []);
  const site = getSite();
  const seasons = getSeasons();
  const anySeasons = seasons?.filter(s => s.seasons?.length > 0)?.length != 0;
  const { cart } = useCart();

  const embedded = hasAppMode('embed');

  function render() {
    return <PublicPage above={renderSiteHeader()} useMaxWidth pt={null} markingLinksWhenLoggedIn={false}>
      {!site
      ? <HBox minHeight='200px' />
      : !site.recentSeasons.length
        ? renderNone()
        : renderListings()}
    </PublicPage>
  }

  function renderSiteHeader() {
    return !embedded ? <SiteHeader site={site} /> : undefined;
  }

  function renderNone(type:string = '') {
    return <HBox minHeight='200px'><Text text='subtitle1' mb='$60'>{site.name} doesn't have any {type.toLocaleLowerCase()} activities ready for enrollment. Check back soon or let us know if something is missing!</Text></HBox>
  }

  function renderListings() {
    if (!anySeasons) {
      return renderNone();
    }

    const prefix = privateSeasons ? '/sites/private' : '/sites';
    const mode = location.pathname.includes('/group/') ? 'hide-tabs' : undefined;

    return <>
      <InactiveWarning site={site} />
      <Tabs width='100%' baseUrl={`${prefix}/${site.slug}/:tab`} urlParameter='tab' urlHideSuffix='-listing' preserveQuery={false} mode={mode}
        tabs={seasons.filter(s => s.seasons?.length).map((s, index) => ({label:s.label, name: s.name, content: renderTab(s, index)}))}/>
    </>
  }

  function renderTab(group:ReturnType<typeof getSeasons>[0], index:number) {
    if (!group.seasons?.length) {
      return renderNone(group.label);
    }

    return <div key={index}>
      {!embedded && <Filter filterOptions={filterOptions} site={site.id} seasons={group.seasons.map(s => s.season)} />}
      <VBox gap='$40'>{group.seasons?.map(s => <Season key={s.season.id} filterOptions={filterOptions} site={site} season={s.season} courses={s.courses} cart={cart} />)}</VBox>
      </div>
  }

  function getSite() {
    const { site:siteId } = useParams<PublicSiteParams>();
    const [result] = usePublicSiteQuery({variables:{id:siteId, private: privateSeasons}});

    return result?.data?.site;
  }

  function getSeasons() {
    return React.useMemo(() => {
      const seasons = site?.recentSeasons;
      const groupedByLabel = groupBy(seasons, 'registrationGroup');

      return Object.keys(groupedByLabel).sort().map(label => {
        return {
          seasons: groupedByLabel[label].map(filterSeason),
          name: encodeURIComponent(label.split(/[ \(\)\/\.]/).map(w => w.toLowerCase()).join('-')),
          label
        }
      });
    }, [site, filterOptions.version])

    function filterSeason(season:PublicSeason) {
      return {
        season, 
        courses: CourseUtils.filterCourses(season.courses.filter(c => c.searchable), filterOptions.values) 
      }
    }  
  }

  return render();
}
