import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type SiteQuery = {
  site?:
    | {
        id: string;
        active?: boolean | null | undefined;
        attendanceQrCode?: string | null | undefined;
        attendanceUrl?: string | null | undefined;
        checkoutDestinations?: Array<string> | null | undefined;
        city?: string | null | undefined;
        userSiteRole?: Types.MemberRole | null | undefined;
        feeRate: number;
        feeType: Types.SiteFeeTypeEnum;
        name?: string | null | undefined;
        hasSiteCompanies: boolean;
        phone?: string | null | undefined;
        partner: boolean;
        pickupDestinations?: Array<string> | null | undefined;
        proration: Types.ProrationKind;
        searchable?: boolean | null | undefined;
        slug: string;
        state?: string | null | undefined;
        streetAddress?: string | null | undefined;
        timezone?: string | null | undefined;
        timezoneDisplay?: string | null | undefined;
        usingCompliance: boolean;
        usingPickups: boolean;
        usingPriceTiers: boolean;
        usingAch: boolean;
        usingAlipay: boolean;
        usingTrackingParameters?: boolean | null | undefined;
        restrictedAccountRegistration?: boolean | null | undefined;
        zipcode?: string | null | undefined;
        branding?: { logo?: string | null | undefined } | null | undefined;
        parentAttendanceConfig: { enabled: boolean; notify: boolean; earlyCheckIn: boolean };
        payoutConfig: { autoSiteCompanyPayouts?: boolean | null | undefined; pause?: boolean | null | undefined };
        prorationConfig: { noActivityDays: boolean; enrollmentStartMidMonth: boolean; enrollmentEndMidMonth: boolean };
      }
    | null
    | undefined;
};

export const SiteDocument = gql`
  query Site($siteId: ID!) {
    site(site: $siteId) {
      id
      active
      attendanceQrCode
      attendanceUrl
      branding {
        logo
      }
      checkoutDestinations
      city
      userSiteRole
      feeRate
      feeType
      name
      hasSiteCompanies
      parentAttendanceConfig {
        enabled
        notify
        earlyCheckIn
      }
      payoutConfig {
        autoSiteCompanyPayouts
        pause
      }
      prorationConfig {
        noActivityDays
        enrollmentStartMidMonth
        enrollmentEndMidMonth
      }
      phone
      partner
      pickupDestinations
      proration
      searchable
      slug
      state
      streetAddress
      timezone
      timezoneDisplay
      usingCompliance
      usingPickups
      usingPriceTiers
      usingAch
      usingAlipay
      usingTrackingParameters
      restrictedAccountRegistration
      zipcode
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteArgs = MakeOptional<UseQueryArgs<SiteQueryVariables, SiteQuery>, 'query'>;

export function useSiteQuery(options: SiteArgs = {}) {
  return useQuery<SiteQueryVariables, SiteQuery>({ query: SiteDocument, ...options });
}

export type SiteOptions = ExecQueryOptions<SiteQueryVariables>;

export function site(options: SiteOptions) {
  return executeQuery<SiteQuery, SiteQueryVariables>(SiteDocument, options);
}

export type SiteSelections = SiteQuery['site'];
