import * as React from 'react';

import { CheckboxField, FormModel, Info, Panel, Field, Section, VBox, useForm } from 'app2/components';

import { useCurrentSite } from '../shared';

import { organizerUpdateProration } from './gql';

export function Proration() {
  const { site } = useCurrentSite();
  const form = useForm({ prorationConfig: site?.prorationConfig }, [site?.prorationConfig]);

  function render() {
    return (
      <Panel icon="DollarSign" title="Subscription proration" onOk={onOk} type="toggle" form={form}>
        <Section
          name="prorationConfig"
          description="Choose how subscription billing adjusts based on activity schedules and enrollment dates."
        >
          <VBox>
            {prorationOptions.map(option => (
              <Field key={option.label} name={option.configKey} label={option.label} infoTip={option.description} component={CheckboxField} />
            ))}
          </VBox>
        </Section>
        <Info type="warning" title="Exceptions" message={warnings} />
      </Panel>
    );
  }

  async function onOk(form: FormModel) {
    const [success] = await organizerUpdateProration({ variables: { siteId: site.id, prorationConfig: form.values.prorationConfig }, error:form });

    return success;
  }
  return render();
}

const prorationOptions = [
  { label: 'No-activity days', description: 'Adjust charges for billing periods with "no-activity days", e.g. a holiday.', configKey: 'noActivityDays' as ProrationConfigKey },
  { label: 'Mid-period start dates', description: 'Adjust charges when enrollments start partway through a billing period.', configKey: 'enrollmentStartMidMonth' as ProrationConfigKey },
  { label: 'Mid-period end dates', description: 'Adjust charges when enrollments end partway through a billing period.', configKey: 'enrollmentEndMidMonth' as ProrationConfigKey }
];

const warnings = [
  `When "${prorationOptions[1].label}" proration is enabled, late-start enrollments aren't charged for no-activity days.`,
  'Mid-period subscription changes are fully prorated.'
];

type ProrationConfigKey = 'noActivityDays' | 'enrollmentStartMidMonth' | 'enrollmentEndMidMonth';
