import * as React from 'react'
import { useHistory } from 'react-router';

import { login } from 'app2/api';
import { Panel, FormModel, HBox, EmailField, Link, InputField, Section } from 'app2/components';
import { PublicPage, useRestrictedSiteAccountRegistration } from 'app2/views/shared-public';

import { handleAuthResponse } from './handleAuthResponse';

export function Login() {
  const history = useHistory();
  const restricted = useRestrictedSiteAccountRegistration();

  function render() {
    return <PublicPage title='Login' useMaxWidth>
      <Panel type='edit' onOk={submit} ok='Login' footerActions={renderFooter()} onNavigation='nothing'>
        <Section name='email' label='Email address' component={EmailField} />
        <Section name='password' label='Password' component={InputField} type='password' />
        <Link to='/password_reset'>Forgot password?</Link>
      </Panel>
    </PublicPage>
  }

  function renderFooter() {
    if (restricted) {
      return;
    }

    return <HBox text='body' vAlign='center' gap='$8'>New to Homeroom? <Link to={`/register`+ window.location.search + window.location.hash}>Sign up here</Link></HBox>
  }

  async function submit(form:FormModel) {
    const response = await login(form.values)
    return handleAuthResponse(form, response, history);
  }

  return render();
}
