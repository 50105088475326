import * as React from 'react';
import moment from 'moment';
import pluralize from 'pluralize';
import { omit, intersection } from 'lodash-es';

import { CourseUtils, CustomPriceConfig, CustomPriceConfigInput, DropInPriceConfig, DropInPriceConfigInput, InputMaybe, PriceConfig, PriceConfigKind, RecurringPrice, RecurringPriceConfig, RecurringPriceConfigInput, RecurringUnit, Scalars, SeasonPrice, SeasonPriceConfig, SeasonPriceConfigInput } from 'app2/api';
import { CheckboxGroup, DatePicker, FieldInfo, flattenChildren, Form, FormModel, RadioGroup, Section, TextProps, VBox, makeRequiredValidator, useLifecycle, TextAreaField, CurrencyInput, greaterThanZero, HBox, formatCurrency, Body } from 'app2/components';

import { useEnrollmentBreakdown } from '../../shared/course-page/enrollments/actions/price-config';

import { formatDropInPrice, formatRecurringMonthlyPrices, formatRecurringPrice, formatSeasonPrice, formatConfigurableSeasonPrice, formatConfigurableSeasonPrices, formatRecurringWeeklyPrices, useCourseDates } from '../course';
import { courseKindBehavior } from '..';

import { CartCourse } from './Cart';

export interface EnrollmentForm {
  id?: Scalars['ID'];
  kind?: InputMaybe<PriceConfigKind>;
  recurring?: InputMaybe<RecurringPriceConfigInput>;
  dropIn?: InputMaybe<DropInPriceConfigInput>;
  configurableSeason?: InputMaybe<SeasonPriceConfigInput>;
  custom?: InputMaybe<CustomPriceConfigInput>;
  recurringConfig?: RecurringPrice;
  configurableSeasonConfig?: SeasonPrice;
}

// these are the props to clear when changing the config type
const configProps = ['kind', 'recurring', 'dropIn', 'configurableSeason', 'custom', 'recurringConfig', 'configurableSeasonConfig'];

interface EnrollmentConfigurationOptions<T extends EnrollmentForm> {
  course: CartCourse;
  form: FormModel<T>;
  recurring?: { startDate?: boolean };
  stepped?: boolean;
  // force a specific kind and not allow the user to choose
  kind?: PriceConfigKind;
}

export function EnrollmentConfigurationForm<T extends EnrollmentForm>(props: EnrollmentConfigurationOptions<T>) {
  const { course, form, stepped, kind } = props;
  const prices = course.prices;
  const dates = useCourseDates(course);
  const defaultStartDate = dates.start?.isAfter(moment()) ? dates.start : dates.sessions?.find(s => s.isSameOrAfter(moment())) || dates.start;
  const days = new Set(CourseUtils.getCourseDaysSet(course).keys());
  const [step, setStep] = React.useState(kind || form.values.kind);
  const seasonBehavior = getSeasonBehavior();
  const breakdown = useEnrollmentBreakdown({course: course.id, config: getEnrollmentConfig(form), pause: props.kind !== PriceConfigKind.Custom});

  // variants cause the form's course to change
  const configuredCourse = React.useRef<CartCourse>();

  form.assignIds = false;
  useLifecycle({onUpdate})

  function render() {
    return stepped
      ? renderStep(step)
      : renderInline();
  }

  function renderStep(step?: PriceConfigKind) {
    if (step === undefined) {
      return renderChooseType();
    } else if (step === PriceConfigKind.Recurring) {
      return renderRecurring();
    } else if (step === PriceConfigKind.DropIn) {
      return renderDropIn();
    } else if (step === PriceConfigKind.ConfigurableSeason) {
      return renderConfigurableSeason();
    } else if (step === PriceConfigKind.Custom) {
      return renderCustom();
    } else {
      return <></>;
    }
  }

  function renderInline() {
    const mixed = CourseUtils.usingMultipleAdvancedRates(prices) || CourseUtils.usingMultipleSeasonRatesOrPrices(prices);

    return (
      <>
        {Boolean(!kind && mixed) && renderChooseType()}
        {form.values.kind === PriceConfigKind.Recurring && renderRecurring()}
        {form.values.kind === PriceConfigKind.DropIn && renderDropIn()}
        {form.values.kind === PriceConfigKind.ConfigurableSeason && renderConfigurableSeason()}
        {form.values.kind === PriceConfigKind.Custom && renderCustom()}
        {!form.values.kind && null}
      </>
    );
  }

  function renderChooseType() {
    const recurring = CourseUtils.usingRecurringRatesOrPrices(prices);
    const dropIns = CourseUtils.usingDropInRateOrPrices(prices);
    const season = CourseUtils.usingBasicSeasonRateOrPrice(prices);
    const seasons = CourseUtils.usingConfigurableSeasonRatesOrPrices(prices);
    const labelProps: TextProps = { text: 'subtitle2' };
    const options = [season && renderSeasonChoice(labelProps), seasons && renderConfigurableSeasonChoice(labelProps), recurring && renderRecurringChoice(labelProps), dropIns && renderDropInChoice(labelProps)].filter(Boolean);

    return renderForm('kind', onNextStep, 
      <Section
        name="kind"
        component={RadioGroup}
        required
        options={options}
        onChange={onChangeType}
      />
    )
  }

  function renderRecurring() {
    const needsConfiguration = CourseUtils.usingConfigurationRequiredRecurringRatesOrPrices(course);

    if (!needsConfiguration && !props.recurring?.startDate) {
      return <></>;
    }

    const recurring = CourseUtils.getValidRecurringRatesOrPrices(prices)
      .slice()
      .sort(compareRecurring);
    const requiredDays = form?.values?.recurringConfig?.days;

    return renderForm('recurring', getEnrollmentConfig, 
      <VBox gap="$30">
        {Boolean(needsConfiguration) && <Section
          label="Select plan"
          name="recurringConfig"
          required
          component={RadioGroup}
          onChange={onChangeRecurring}
          options={recurring.map((p, index) => ({ label: formatRecurringPrice(p), value: p}))}
        />}
        {Boolean(needsConfiguration) && <Section<any>
          label={daysLabel(requiredDays)}
          name="recurring.weekdays"
          required
          component={CheckboxGroup}
          validators={validateRecurringRate}
          options={dayOptions()}
        />}
        {Boolean(props.recurring?.startDate) && <Section<any> label="Select a start date" name="recurring.startDate" component={DatePicker} legend={dates.legend} start={defaultStartDate} min={dates.start} max={dates.end} required />}
      </VBox>
    );
  }

  function compareRecurring(a: RecurringPrice, b: RecurringPrice) {
    if (a.unit == RecurringUnit.Month && b.unit == RecurringUnit.Week) {
      return -1;
    }
  
    if (a.unit == RecurringUnit.Week && b.unit == RecurringUnit.Month) {
      return 1;
    }
  
    return a.days - b.days;
  }

  function renderConfigurableSeason() {
    const needsConfiguration = CourseUtils.usingConfigurationRequiredSeasonRatesOrPrices(course);

    if (!needsConfiguration) {
      return <></>;
    }

    const ratesOrPrices = CourseUtils.getValidConfigurableSeasonRatesOrPrices(prices)
      .slice()
      .sort(compareConfigurableSeason);
    const requiredDays = form?.values?.configurableSeasonConfig?.days;

    return renderForm('configurableSeason', getEnrollmentConfig,
      <VBox gap="$30">
        {needsConfiguration && <Section
          label="Select plan"
          name="configurableSeasonConfig"
          required
          component={RadioGroup}
          onChange={onChangeConfigurableSeason}
          options={ratesOrPrices.map(p => ({ label: formatConfigurableSeasonPrice(seasonBehavior, p), value: p}))}
        />}
        {needsConfiguration && <Section<any>
          label={daysLabel(requiredDays)}
          name="configurableSeason.weekdays"
          required
          component={CheckboxGroup}
          validators={validateConfigurableSeasonRate}
          options={dayOptions()}
        />}
      </VBox>
    );
  }

  function compareConfigurableSeason(a: SeasonPrice, b: SeasonPrice) {  
    return a.days - b.days;
  }

  function daysLabel(days?: number) {
    return `Select ${days || ''} ${pluralize('days', days)}`
  }

  function dayOptions() {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(d => days.has(d) && { label: d, value: d });
  }

  function renderCustom() {
    return renderForm('custom', getEnrollmentConfig, 
      <VBox gap="$30">
        <HBox gap="$30">
          <Section
            label="Amount (before fees)"
            name="custom.amount"
            required
            component={CurrencyInput}
            validators={greaterThanZero}
            placeholder="Enter amount"
            min={1}
            max={1000}
          />
          <Section
            label="Amount family will be charged"
            name="familyCharged"
            render={() => formatCurrency(breakdown?.first?.listPrice || 0)}
          />
        </HBox>
        <Section<string>
          label="Description"
          name="custom.description"
          required
          autoSize
          minHeight='unset'
          component={TextAreaField}
        />
      </VBox>
    );
  }
    
  function renderDropIn() {
    return renderForm('dropIn', getEnrollmentConfig,
      <Section name="dropIn.dates" label="Choose drop in dates" component={DatePicker} start={defaultStartDate} min={dates.start} max={dates.end} legend={dates.legend} type="multiple" required inline />
    )
  }

  function renderForm(name:string, onNext:(form: FormModel<T>) => any, fields:JSX.Element) {
    return stepped
      ? <Form key={name} form={form} overflow="visible" onOk={onNext}>{fields}</Form>
      : fields
  }

  function renderSeasonChoice(labelProps: TextProps) {
    return {
      label: `Full ${seasonBehavior.terms.season} (${formatSeasonPrice(seasonBehavior, prices)})`,
      labelProps,
      value: PriceConfigKind.Season
    };
  }

  function renderConfigurableSeasonChoice(labelProps: TextProps) {
    return {
      label: `Full ${seasonBehavior.terms.season}, choose schedule (${formatConfigurableSeasonPrices(seasonBehavior, prices)})`,
      labelProps,
      value: PriceConfigKind.ConfigurableSeason
    };
  }

  function getSeasonBehavior() {
    return courseKindBehavior[course.kind];
  }

  function renderRecurringChoice(labelProps: TextProps) {
    return {
      label: `Regular schedule (${[formatRecurringMonthlyPrices(prices), formatRecurringWeeklyPrices(prices)].filter(p => !!p).join(', ')})`,
      labelProps,
      description: 'Pick the same days of the week for every week.',
      value: PriceConfigKind.Recurring
    };
  }

  function renderDropInChoice(labelProps: TextProps) {
    return {
      label: `Drop-in (${formatDropInPrice(prices)})`,
      labelProps,
      description: 'Book by the day without having a set schedule.',
      value: PriceConfigKind.DropIn
    };
  }

  function onUpdate() {
    if (configuredCourse.current == course) {
      return
    }

    configuredCourse.current = course;
    
    const values = getInitialValues(kind);
    form.setValues(values);

    if (values.kind) {
      setStep(values.kind);
    }
  }

  function getInitialValues(initialKind?: PriceConfigKind): Partial<T> {
    initialKind = initialKind || CourseUtils.getDefaultPriceConfigKindForPrices(course.prices);
    
    return !initialKind
      ? {}
      : initialKind == PriceConfigKind.Recurring
        ? getInitialRecurring(course)
        : initialKind == PriceConfigKind.DropIn
          ? getInitialDropIn()
          : initialKind == PriceConfigKind.ConfigurableSeason
            ? getInitialConfigurableSeason(course)
            : { kind: initialKind } as Partial<T>;
  }
  
  function getInitialRecurring(course: CartCourse) {
    const initialRecurring = form.initialValues.recurring;
    const prices = course.prices;
    const multipleRecurringUnits = CourseUtils.usingMultipleRecurringUnitTypes(prices);
    const recurringConfig = CourseUtils.findRecurringPriceForConfig(prices, initialRecurring) || (multipleRecurringUnits ? null : CourseUtils.getValidRecurringRatesOrPrices(prices)[0]);
    const weekdays = initialRecurring?.weekdays ? intersection(initialRecurring?.weekdays, Array.from(days)) : getDefaultDaysForConfig(recurringConfig);

    return { kind:PriceConfigKind.Recurring, recurring: { startDate: initialRecurring?.startDate as string, weekdays, unit: initialRecurring?.unit || recurringConfig?.unit }, recurringConfig } as Partial<T>;
  }

  function getInitialConfigurableSeason(course: CartCourse) {
    const initialConfigurableSeason = form.initialValues.configurableSeason;
    const prices = course.prices;
    const configurableSeasonConfig = CourseUtils.findConfigurableSeasonPriceForConfig(prices, initialConfigurableSeason) || CourseUtils.getValidConfigurableSeasonRatesOrPrices(prices)[0];
    const weekdays = initialConfigurableSeason?.weekdays ? intersection(initialConfigurableSeason?.weekdays, Array.from(days)) : getDefaultDaysForConfig(configurableSeasonConfig);

    return { kind:PriceConfigKind.ConfigurableSeason, configurableSeason: { weekdays }, configurableSeasonConfig } as Partial<T>;
  }

  function getDefaultDaysForConfig(config: RecurringPrice | SeasonPrice): string[] {
    return config?.days == days.size ? Array.from(days) : [];
  }

  function getInitialDropIn() {
    return { kind:PriceConfigKind.DropIn, dropIn: { dates: [] as string[] } } as Partial<T>;
  }
  
  function onChangeType(value: PriceConfigKind, _info: FieldInfo<EnrollmentForm>) {
    form.reset({ values: { ...omit(form.values, configProps), ...getInitialValues(value) } });
  }

  function onChangeRecurring(recurringConfig: RecurringPrice, info: FieldInfo<EnrollmentForm>) {
    info.form.setValue('recurring', { weekdays: getDefaultDaysForConfig(recurringConfig), unit: recurringConfig.unit, startDate: form.values.recurring?.startDate });
  }

  function onChangeConfigurableSeason(config: SeasonPrice, info: FieldInfo<EnrollmentForm>) {
    info.form.setValue('configurableSeason', { weekdays: getDefaultDaysForConfig(config) });
  }

  function onNextStep() {
    const next = renderStep(form.values.kind);

    if (flattenChildren(next).length == 0) {
      return getEnrollmentConfig(form)
    }
    else {
      // resets the errors for the next step
      form.reset({ values: form.values });
      setStep(form.values.kind);
    }

    return false;
  }

  return render();
}

const validateRecurringRate = makeRequiredValidator(function(days: string[], info: FieldInfo<EnrollmentForm>) {
  const recurringConfig = info.form.values.recurringConfig;
  const required = recurringConfig ? `${recurringConfig.days} ${pluralize('day', recurringConfig.days)}` : 'days';

  return !recurringConfig || days?.length != recurringConfig.days ? `You must select ${required}` : null;
});

const validateConfigurableSeasonRate = makeRequiredValidator(function(days: string[], info: FieldInfo<EnrollmentForm>) {
  const configurableSeasonConfig = info.form.values.configurableSeasonConfig;
  const required = configurableSeasonConfig ? `${configurableSeasonConfig.days} ${pluralize('day', configurableSeasonConfig.days)}` : 'days';

  return !configurableSeasonConfig || days?.length != configurableSeasonConfig.days ? `You must select ${required}` : null;
});


export type EnrollmentConfig = EnrollmentForm;

export function getEnrollmentConfig<T extends EnrollmentForm>(form: FormModel<T>) {
  const kind = form.values.kind;

  // only validate if the form is valid because else
  // running validations will cause previous server errors to
  // get cleared - its a bit of a hack, we should have the form 
  // track client vs server errors and only clear client errors

  if (form.valid) {
    form.validateSync();
  }

  const recurring = form.values.recurring;
  const configurableSeason = form.values.configurableSeason;

  return kind == PriceConfigKind.Season || kind == PriceConfigKind.Usage
    ? {kind}
      // can't rely on form validation because we dont render recurring form 
      // when there are no options for the user to choose
    : kind == PriceConfigKind.Recurring && (form.fieldValid([], 'recurring') || (recurring?.unit && recurring?.weekdays?.length))
      ? {kind, recurring: {...form.values.recurring}}
      : kind == PriceConfigKind.DropIn && form.fieldValid([], 'dropIn')
        ? {kind, dropIn: {...form.values.dropIn}}
        : kind == PriceConfigKind.ConfigurableSeason && form.fieldValid([], 'configurableSeason') || configurableSeason?.weekdays?.length
          ? {kind, configurableSeason: {weekdays: configurableSeason.weekdays}}
          : kind == PriceConfigKind.Custom && form.fieldValid(['custom'], 'amount' as any)
            ? {kind, custom: {...form.values.custom}}
            : null;
}

export function getEnrollmentConfigFromPriceConfig(priceConfig:Partial<PriceConfig>):EnrollmentConfig {
  const dropIn = priceConfig as DropInPriceConfig;
  const recurring = priceConfig as RecurringPriceConfig;
  const season = priceConfig as SeasonPriceConfig;
  const custom = priceConfig as CustomPriceConfig;

  return (priceConfig.kind == PriceConfigKind.Season && !season.weekdays?.length) || priceConfig.kind == PriceConfigKind.Usage
    ? {kind: priceConfig.kind}
    : priceConfig.kind == PriceConfigKind.Recurring
      ? {kind: priceConfig.kind, recurring: {unit: recurring.unit, weekdays: recurring.weekdays, startDate: recurring.startDate}}
      : priceConfig.kind == PriceConfigKind.DropIn
        ? {kind: priceConfig.kind, dropIn: {dates: dropIn.dates}}
        : priceConfig.kind == PriceConfigKind.Season
          ? {kind: priceConfig.kind, configurableSeason: {weekdays: season.weekdays}}
          : priceConfig.kind == PriceConfigKind.Custom
            ? {kind: priceConfig.kind, custom: {amount: custom.amount, description: custom.description}}
            : null;

}
