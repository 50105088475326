import * as React from 'react'
import { StripeElementsOptions } from "@stripe/stripe-js";

import { Modal } from 'app2/components';
import { StripeElements2 } from 'app/scriptLoading';

// ensurs stripe is loaded before the modal shows

export async function showStripeModal(modal:React.ReactElement, options?:StripeElementsOptions) {
  return Modal.show(<StripeElements2 options={options}>{modal}</StripeElements2>);
}