import * as React from 'react'
import { useHistory } from 'react-router';

import { useLifecycle } from 'app2/components';

import { useCart } from './CartImpl'

export function useRestrictedSiteAccountRegistration() {
  const history = useHistory();
  const { cart } = useCart();

  const [restrictedAccountRegistration, setRestrictedAccountRegistration] = React.useState(false);
  useLifecycle({onUpdate:checkForRestrictedAccountRegistration});

  function checkForRestrictedAccountRegistration() {
    if (!cart?.data?.enrollments) {
      return;
    }

    const restricted = cart.data.enrollments.some(e => e.course.site.restrictedAccountRegistration);

    if (restrictedAccountRegistration != restricted) {
      setRestrictedAccountRegistration(restricted)
    }

    if (!restricted || history.location.pathname != '/register') {
      return;
    }

    history.replace(getLoginUrl());
  }

  return restrictedAccountRegistration;
}

export function getLoginUrl() {
  return `/login`+ window.location.search + window.location.hash
}
