import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSiteProcessingRefundsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminSiteProcessingRefundsQuery = {
  site?:
    | {
        id: string;
        processingRefunds?:
          | Array<{
              id: string;
              createdAt: string;
              amount: number;
              parent?: { id: string; name?: string | null | undefined } | null | undefined;
              course?: { id: string; name?: string | null | undefined } | null | undefined;
              student?: { id: string; name: string } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminSiteProcessingRefundsDocument = gql`
  query AdminSiteProcessingRefunds($id: ID!) {
    site(site: $id) {
      id
      processingRefunds {
        id
        createdAt
        amount
        parent {
          id
          name
        }
        course {
          id
          name
        }
        student {
          id
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminSiteProcessingRefundsArgs = MakeOptional<UseQueryArgs<AdminSiteProcessingRefundsQueryVariables, AdminSiteProcessingRefundsQuery>, 'query'>;

export function useAdminSiteProcessingRefundsQuery(options: AdminSiteProcessingRefundsArgs = {}) {
  return useQuery<AdminSiteProcessingRefundsQueryVariables, AdminSiteProcessingRefundsQuery>({ query: AdminSiteProcessingRefundsDocument, ...options });
}

export type AdminSiteProcessingRefundsOptions = ExecQueryOptions<AdminSiteProcessingRefundsQueryVariables>;

export function adminSiteProcessingRefunds(options: AdminSiteProcessingRefundsOptions) {
  return executeQuery<AdminSiteProcessingRefundsQuery, AdminSiteProcessingRefundsQueryVariables>(AdminSiteProcessingRefundsDocument, options);
}

export type AdminSiteProcessingRefundsSelections = AdminSiteProcessingRefundsQuery['site'];
