import * as React from 'react'

// React only allows one context per class component
// without having to resort to render props.  this 
// is a context where you can keep appending values
// and class components can get access to them all.
//
// if a value is specified for an existing name
// it will be overwritten.
//
// to add a new value, render it like a normal context
//  <MultiContextProvider propertyName={propertyValue}>children</MultiContextProvider>
//
// you can add as many properties as you want.
//
// to consume, in a class component
//  static contextType = MultiContext<TypeDefinitionsOfTheValues | YouWillAccess>;
//
//  this.context.name of value you want to acess
//
// to consume, in a function component
//  const foo = useContext<TypeDefinitionsOfTheValues | YouWillAccess>(MultiContext)
//  foo.name of value you want to acess

export const MultiContext = React.createContext(null);

interface MultiContextProviderProps<T> {
  [name:string]:any;
  children:React.ReactNode;
}

export function MultiContextProvider<T>(props:MultiContextProviderProps<T>) {
  const context = React.useContext<T>(MultiContext);
  const updated:any = {...context};

  for (const name in props) {
    if (name == 'children') {
      continue;
    }

    updated[name] = props[name] === undefined ? updated[name] : props[name];
  }

  return <MultiContext.Provider value={updated}>{props.children}</MultiContext.Provider>;
}

export function useMultiContext<T>() {
  return React.useContext<T>(MultiContext);
}
