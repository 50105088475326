import * as React from 'react';
import { useParams } from 'react-router';

import { FilterOperator } from 'app2/api';
import { Tabs } from 'app2/components';

import { AdminPage } from '../AdminPage';
import { AdminSiteRouteParams } from '../adminRoutes';
import { HistoryVersions, MutationChangesPanel, ReviewsTable } from '../shared';

import { Vendors } from './Vendors';
import { SiteInfo } from './SiteInfo';
import { Seasons } from './season';
import { Team } from './Team';
import { Payouts } from './Payouts';
import { PendingRefunds } from './PendingRefunds';
import { ProcessingRefunds } from './ProcessingRefunds';

export function Site() {
  const { id } = useParams<AdminSiteRouteParams>();

  function render() {
    return <AdminPage title='School'>
      <Tabs baseUrl={`/admin2/schools/${id}/:tab`} urlParameter='tab' tabs={[
      {label:'Information', name: 'info', content: <SiteInfo />}, 
      {label:'Providers', name:'vendors', content: <Vendors />},
      {label:'Seasons', name:'seasons', content: <Seasons />},
      {label:'Team', name:'team', content: <Team />},
      {label:'Payouts', name: 'payouts', content: <Payouts />},
      {label:'Pending refunds', name:'pending-refunds', content: <PendingRefunds />},
      {label:'Processing refunds', name:'processing-refunds', content: <ProcessingRefunds />},
      {label:'Reviews', name:'reviews', content: <ReviewsTable filter={{name:'site.id', op:FilterOperator.Eq, value:id}} />},
      {label:'History', name:'mutations', content: <MutationChangesPanel type='Site' id={id} />},
      {label:'History (old)', name:'history', content: <HistoryVersions type='Site' id={id} />},
    ]} />
    </AdminPage>
  }

  return render();
}
