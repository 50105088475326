import * as React from 'react'

import { cmdCtrlKey, ClipboardUtils } from '../../dom-utils';
import { UndoManager } from '../../undo';
import { theme } from '../../theme';
import { TableSection } from '../../virtualized';

import { DataTable, Selection } from '..'
import { PasteTableCommand } from '../commands';

interface Props {
  table:DataTable;
  section:TableSection;
}

interface State {
  copySelection:Selection;
}

export class ClipboardManager extends React.Component<Props, State> {
  get table():DataTable {
    return this.props.table;
  }

  get copySelection() {
    return this.state?.copySelection;
  }

  set copySelection(copySelection:Selection) {
    if (this.copySelection == copySelection) {
      return;
    }

    this.setState({copySelection});
  }

  render() {
    return <div onCopy={this.onCopy} onPaste={this.onPaste} onBlur={this.onBlur} onKeyDown={this.onKeyDown}>
      {this.renderCopySelection()}
      {this.props.children}
    </div>
  }

  renderCopySelection() {
    const copySelection = this.copySelection;

    if (!copySelection) {
      return;
    }

    const table = this.table;
    const section = this.props.section;

    const topLeftPos = copySelection.topLeft.clone().offset(table.rowHeaderCount, table.colHeaderCount);
    const bottomRightPos = copySelection.bottomRight.clone().offset(table.rowHeaderCount, table.colHeaderCount);
    const topLeft = table.getCellCoordinates(topLeftPos).clone().offset(-section.coords.left, -section.coords.top);
    const bottomRight = table.getCellCoordinates(bottomRightPos).clone().offset(-section.coords.left, -section.coords.top);

    const left = topLeft.left + 'px';
    const top = topLeft.top + 'px';
    const width = (bottomRight.right - topLeft.left) + 'px';
    const height = (bottomRight.bottom - topLeft.top) + 'px';

    const style = {position:'absolute', border: 'dotted', borderColor: theme.colors.primaryFocused, borderRadius: theme.radii.standard, borderWidth: theme.borderWidths.borderWidths, left, top, width, height, pointerEvents: 'none', zIndex:10} as React.CSSProperties;

    return <div style={style} /> 
  }

  onCopy = (event:React.ClipboardEvent<HTMLElement>) => {
    const selection = this.table.selection.clone();
    const values = selection.formattedValues;

    const headers = selection.headers;
    const valuesAndMaybeHeaders = selection.allRowsSelected ? [headers].concat(values) : values;

    const csv = ClipboardUtils.formatCsv(valuesAndMaybeHeaders);
    event.clipboardData.setData('text/csv', csv);

    const tsv = ClipboardUtils.formatTsv(valuesAndMaybeHeaders);
    event.clipboardData.setData('text/tsv', tsv);

    // google docs appears to use tsv for text/plain
    // but if its a single cell then use formatted
    // value so we don't have the extra quotes which
    // matters when pasting into the cell editor
    
    if (selection.singleCellSelected) {
      event.clipboardData.setData('text/plain', values[0][0]);
    }
    else {
      // when people use the paste button in our app, we dont
      // get access to all the formats, and we get text/plain format
      // because of that, we remove the headers
      event.clipboardData.setData('text/plain', ClipboardUtils.formatTsv(values));
    }

    const html = ClipboardUtils.formatHtml(valuesAndMaybeHeaders);
    event.clipboardData.setData('text/html', html);

    const json = JSON.stringify(selection.rawValues);
    event.clipboardData.setData('text/hr', json);

    event.preventDefault();

    this.copySelection = selection;
  }

  onPaste = (event:React.ClipboardEvent<HTMLElement>) => {
    if (!this.table.editable) {
      return;
    }

    const values = this.valuesFromEvent(event);
  
    if (values?.length) {
      UndoManager.instance.push(new PasteTableCommand(this.table, values));
    }
  }

  valuesFromEvent(event:React.ClipboardEvent<HTMLElement>):any[][] {
    const clipboard = event.clipboardData;

    try {
      const json = JSON.parse(event.clipboardData.getData('text/hr'));

      if (json !== '') {
        return json;
      }
    }
    catch(e) {
    }

    const tsv = clipboard.getData('text/tsv');

    if (tsv) {
      return ClipboardUtils.parseTsv(tsv);
    }

    const csv = clipboard.getData('text/csv');

    if (csv) {
      return ClipboardUtils.parseCsv(csv);
    }

    const text = clipboard.getData('text/plain');

    if (text) {
      return ClipboardUtils.parseTsv(text);
    }

    return null;
  }

  onKeyDown = (event:React.KeyboardEvent) => {
    const allowableKeys = new Set(['arrowleft', 'arrowup', 'arrowright', 'arrowdown', 'end', 'home', 'pageup', 'pagedown', 'alt', 'capslock', 'control', 'enter', 'meta', 'numlock', 'scrolllock', 'shift', 'tab'])
    const char = event.key.toLowerCase();

    if (allowableKeys.has(char) || (char == 'v' && cmdCtrlKey(event))) {
      return;
    }

    this.copySelection = null;
  }

  onBlur = () => {
    this.copySelection = null;
  }
}
