import * as React from 'react';

import { authorizedPreferences, Course, CourseUtils, CourseStatus, DeepPartial } from 'app2/api'
import { VBox, Modal, Text, Checkbox, ObservableCollection, collectionToArray } from 'app2/components'
import { CourseStatusTag } from 'app2/views/shared-public';

type Courses = ObservableCollection<DeepPartial<Course>> | DeepPartial<Course>;

interface Props {
  courses:Courses;
  onOk:() => void;
}

export function SaveAndTransitionModal(props:Props) {
  const [dontShowAgain, setDontShowAgain] = React.useState(false);

  function render() {
    const types = calcTransitionType(props.courses);

    return <Modal title={SaveAndTransitionModal.title(props.courses)} ok='Continue' onOk={onOk} footerActions={<Checkbox label="Don't show this again" checked={dontShowAgain} onClick={() => setDontShowAgain(!dontShowAgain)} />}>
      <VBox gap='8px' mt='$4' mb='$16'>
        {types.request ?
          <Text text='body'>Activities that require provider input will be sent to the provider, and will show
          as <CourseStatusTag status={CourseStatus.Request} small display='inline' /> on the Requests tab.</Text> : ''}

        {types.finalize ?
          <Text text='body'>Activities that require approval will be sent to the organizer, and will show
          as <CourseStatusTag status={CourseStatus.AwaitingApproval} small display='inline' /> on the Requests tab.</Text> : ''}

        {types.approve ?
          <Text text='body'>Activities that don't require provider input
          will show as <CourseStatusTag status={CourseStatus.Upcoming} small display='inline' /> on the Published tab.</Text> : ''}
      </VBox>
    </Modal>
  }

  async function onOk() {
    if (dontShowAgain) {
      authorizedPreferences.dontShowSaveAndTransition = true;
    }

    return props.onOk();
  }

  return render();
}

SaveAndTransitionModal.show = function(courses:Courses, onOk:() => void) {
  if (authorizedPreferences.dontShowSaveAndTransition) {
    return onOk();
  }
  else {
    return Modal.show(<SaveAndTransitionModal courses={courses} onOk={onOk} />);
  }
}

SaveAndTransitionModal.title = function(courses:Courses) {
  if (!courses) {
    return '';
  }

  const types = calcTransitionType(courses);

  if (types.request && !types.finalize && !types.approve) {
    return 'Save & send';
  }

  if (!types.request && !types.finalize && types.approve) {
    return 'Save & publish';
  }

  if (!types.request && types.finalize && !types.approve && !types.addTimeSlots) {
    return 'Save & send';
  }

  if (types.request || types.finalize || types.approve) {
    return 'Save & next';
  }

  return '';
}

export function calcTransitionType(courses:Courses) {
  const collection = (courses as DeepPartial<Course>).id ? [courses as DeepPartial<Course>] : collectionToArray(courses as ObservableCollection<DeepPartial<Course>>);
  const abilities = CourseUtils.getCourseAbilities(collection as any, true);

  return {request: abilities.sendable, finalize: abilities.finalizable, approve: abilities.approvable, addTimeSlots: abilities.addTimeSlots};
}