import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StudentsQueryVariables = Types.Exact<{
  site?: Types.InputMaybe<Types.Scalars['ID']>;
  company?: Types.InputMaybe<Types.Scalars['ID']>;
  term: Types.Scalars['String'];
  excludedCourses?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;

export type StudentsQuery = {
  studentsSearch?:
    | Array<{
        id: string;
        firstName: string;
        lastName: string;
        nickname?: string | null | undefined;
        parents: Array<{ name?: string | null | undefined; email: string; phone?: string | null | undefined }>;
        family?: { hasPaymentMethod?: boolean | null | undefined } | null | undefined;
      }>
    | null
    | undefined;
};

export const StudentsDocument = gql`
  query Students($site: ID, $company: ID, $term: String!, $excludedCourses: [ID!]) {
    studentsSearch(site: $site, company: $company, term: $term, excludedCourses: $excludedCourses) {
      id
      firstName
      lastName
      nickname
      parents {
        name
        email
        phone
      }
      family {
        hasPaymentMethod
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type StudentsArgs = MakeOptional<UseQueryArgs<StudentsQueryVariables, StudentsQuery>, 'query'>;

export function useStudentsQuery(options: StudentsArgs = {}) {
  return useQuery<StudentsQueryVariables, StudentsQuery>({ query: StudentsDocument, ...options });
}

export type StudentsOptions = ExecQueryOptions<StudentsQueryVariables>;

export function students(options: StudentsOptions) {
  return executeQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
}

export type StudentsSelections = StudentsQuery['studentsSearch'][0];

(StudentsDocument as any).additionalTypenames = ['Student'];
