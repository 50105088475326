import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AutoRenewalQueryVariables = Types.Exact<{
  family: Types.Scalars['ID'];
}>;

export type AutoRenewalQuery = { family?: { invoice: boolean } | null | undefined };

export const AutoRenewalDocument = gql`
  query AutoRenewal($family: ID!) {
    family(family: $family) {
      invoice
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AutoRenewalArgs = MakeOptional<UseQueryArgs<AutoRenewalQueryVariables, AutoRenewalQuery>, 'query'>;

export function useAutoRenewalQuery(options: AutoRenewalArgs = {}) {
  return useQuery<AutoRenewalQueryVariables, AutoRenewalQuery>({ query: AutoRenewalDocument, ...options });
}

export type AutoRenewalOptions = ExecQueryOptions<AutoRenewalQueryVariables>;

export function autoRenewal(options: AutoRenewalOptions) {
  return executeQuery<AutoRenewalQuery, AutoRenewalQueryVariables>(AutoRenewalDocument, options);
}

export type AutoRenewalSelections = AutoRenewalQuery['family'];
