import * as React from 'react';

import { CourseKind, CourseUtils } from 'app2/api';
import { Button, Modal } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { CourseSelections } from '../../../generated';

import { AddStudentModal } from './AddStudentModal';

interface Props {
  course: CourseSelections;
}

export function AddStudentAction(props: Props) {
  const behavior = courseKindBehavior[props.course?.kind];
  
  if (!props.course || behavior.management.addStudent === false || !props.course?.behavior?.enrollmentAdd || !CourseUtils.isEnrollable(props.course)) {
    return <></>;
  }

  function render() {
    return <Button icon="Plus" iconPosition='left' autoLoader={false} onClick={onClick}>
      {`Add ${behavior.terms.participant}`}
    </Button>
  }

  function onClick() {
    if (props.course.kind == CourseKind.LessonSet && !props.course?.children?.length) {
      Modal.error('Add student', 'You must add time slots to this lesson set before you can add students');
      return;
    }
    
    return <AddStudentModal course={props.course} />;
  }

  return render();
}
