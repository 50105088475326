import * as React from 'react';
import { css } from 'styled-components';
import { variant, compose } from './styled-system';
import { Property } from 'csstype';

import { ComponentProps, componentStyles, createComponent, property } from './utils';
import { createTextComponent, TextCustomProps } from './Text';

export type Layout = 'hbox' | 'vbox' | 'hbox-reverse' | 'vbox-reverse';
export type HAlign = 'left' | 'center' | 'right' | 'justify' | 'baseline' | 'stretch';
export type VAlign = 'top' | 'center' | 'bottom' | 'justify' | 'baseline' | 'stretch';

export interface BoxCustomProps extends TextCustomProps {
  layout?: Layout | Layout[];
  // this is css gap, its similar to hItemSpace and vItemSpace
  // 1 number applies to both horizontal and vertical
  // 2 numbers means the 1st is horizontal and the 2nd vertical
  // the benefit of gap over item space is that when using
  // flex wrap, it will apply the gap to things only in a row/col
  // unlike item space (which uses margins)
  gap?: Property.Margin | Property.Margin[];
  majorItemSpace?: string | string[];
  minorItemSpace?: string | string[];
  // similar to gap, but was added before we knew about gap
  // gap works independent of child margins, however item space
  // will replace item margins
  hItemSpace?: Property.Margin | Property.Margin[];
  vItemSpace?: Property.Margin | Property.Margin[];
  hAlign?: HAlign | HAlign[];
  vAlign?: VAlign | VAlign[];
  itemFlex?: Property.Flex | Property.Flex[];
  itemWidth?: Property.Width | Property.Width[];

  // customize the breakpoints used in this container
  // implemented in ComponentBase and BreakpointsContainer
  // see them for more details.
  breakpoints?:(number | string)[];

  // changes the breakpoints to be based on the container
  // dimensions, instead of the browsers dimensions.
  // implemented in ComponentBase and BreakpointsContainer
  // see them for more details.
  containerBreakpoints?:boolean;
}
export interface BoxProps
  extends BoxCustomProps,
    ComponentProps<Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'title' | 'onChange' | 'onReset' | 'defaultValue' | 'contextMenu'>> {}

const layout = variant({
  prop: 'layout',
  variants: {
    hbox: {
      display: 'flex',
      flexDirection: 'row'
    },
    'hbox-reverse': {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    vbox: {
      display: 'flex',
      flexDirection: 'column'
    },
    'vbox-reverse': {
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  }
});

const gap = property('gap', (gap: Property.Margin) => {
  return {
    gap
  }
});

const majorItemSpace = property(
  'majorItemSpace',
  (majorItemSpace: string, props: Partial<BoxProps>) => {
    return props.layout == 'hbox' || props.layout == 'hbox-reverse' ? getHItemSpace(majorItemSpace, props) : getVItemSpace(majorItemSpace, props);
  },
  ['layout']
);

const minorItemSpace = property(
  'minorItemSpace',
  (minorItemSpace: string, props: Partial<BoxProps>) => {
    return props.layout == 'hbox' || props.layout == 'hbox-reverse' ? getVItemSpace(minorItemSpace, props) : getHItemSpace(minorItemSpace, props);
  },
  ['layout']
);

const hItemSpace = property(
  'hItemSpace',
  (hItemSpace: string, props: Partial<BoxProps>) => getHItemSpace(hItemSpace, props),
  ['layout']
);

function getHItemSpace(hItemSpace: string, props: Partial<BoxProps>) {
    // note the extra space in the selectors is important else when using
    // both hItemSpace and vItemSpace, the selectors overwrite each other
    // they just need a slight difference to avoid that
    const styles: any = {
      ' > *': {
      mr: hItemSpace
    }
  };

  if (props.layout == 'hbox' || props.layout == 'hbox-reverse') {
    styles['  > *:last-child'] = {
      mr: '0'
    };
  }

  return styles;
}

const vItemSpace = property(
  'vItemSpace',
  (vItemSpace: string, props: Partial<BoxProps>) => getVItemSpace(vItemSpace, props),
  ['layout']
);

function getVItemSpace(vItemSpace: string, props: Partial<BoxProps>) {
  const styles: any = {
    '   > *': {
      mb: vItemSpace
    }
  };

  if (props.layout == 'vbox' || props.layout == 'vbox-reverse' || !props.layout) {
    styles['    > *:last-child'] = {
      mb: '0'
    };
  }

  return styles;
}

const alignMap = {
  left: 'flex-start',
  top: 'flex-start',
  center: 'center',
  right: 'flex-end',
  bottom: 'flex-end',
  justify: 'space-between',
  stretch: 'stretch',
  baseline: 'baseline'
};

const hAlign = property(
  'hAlign',
  (hAlign: HAlign, props: Partial<BoxProps>) => {
    return props.layout == 'hbox' || props.layout == 'hbox-reverse'
      ? { justifyContent: alignMap[hAlign] }
      : { alignItems: alignMap[hAlign] };
  },
  ['layout']
);

const vAlign = property(
  'vAlign',
  (vAlign: VAlign, props: Partial<BoxProps>) => {
    return props.layout == 'vbox' || props.layout == 'vbox-reverse'
      ? { justifyContent: alignMap[vAlign] }
      : { alignItems: alignMap[vAlign] };
  },
  ['layout']
);

const itemFlex = property('itemFlex', (itemFlex: Property.Flex) => {
  return {
    '     > *': {
      flex: itemFlex
    }
  };
});

const itemWidth = property('itemWidth', (itemWidth: Property.Width) => {
  return {
    '      > *': {
      width: itemWidth
    }
  };
});

export const boxPropsToExclude = [
  'majorItemSpace',
  'minorItemSpace',
  'gap', 
  'hItemSpace',
  'vItemSpace',
  'itemFlex',
  'itemWidth',
  'layout',
  'text',
  'hAlign',
  'vAlign'
];

export const Box = createTextComponent<BoxProps>('div', false, boxPropsToExclude, css`
    ${compose(
      layout,
      majorItemSpace,
      minorItemSpace,
      gap,
      hItemSpace,
      hAlign,
      vItemSpace,
      vAlign,
      itemFlex,
      itemWidth
    )}
    ${componentStyles}
  `
);

Box.displayName = 'Box';
//@ts-ignore
Box.fieldProps = {
  valueProperty:'children'
}

export const HBox = createComponent(Box)<BoxProps>({}) as React.ComponentType<BoxProps>;

HBox.defaultProps = {
  layout: 'hbox'
};

HBox.displayName = 'HBox';

export const VBox = createComponent(Box)<BoxProps>({}) as React.ComponentType<BoxProps>;

VBox.defaultProps = {
  layout: 'vbox'
};

VBox.displayName = 'VBox';
