import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSiteUpdateGeneralInfoMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  contactInfo?: Types.InputMaybe<Types.SiteAddressInput>;
  searchable?: Types.InputMaybe<Types.Scalars['Boolean']>;
  usingAch?: Types.InputMaybe<Types.Scalars['Boolean']>;
  usingAlipay?: Types.InputMaybe<Types.Scalars['Boolean']>;
  usingTrackingParameters?: Types.InputMaybe<Types.Scalars['Boolean']>;
  restrictedAccountRegistration?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type OrganizerSiteUpdateGeneralInfoMutation = { siteUpdateGeneralInfo?: { site?: { id: string } | null | undefined } | null | undefined };

export const OrganizerSiteUpdateGeneralInfoDocument = gql`
  mutation OrganizerSiteUpdateGeneralInfo(
    $siteId: ID!
    $contactInfo: SiteAddressInput
    $searchable: Boolean
    $usingAch: Boolean
    $usingAlipay: Boolean
    $usingTrackingParameters: Boolean
    $restrictedAccountRegistration: Boolean
  ) @urql(additionalTypenames: ["User"]) {
    siteUpdateGeneralInfo(
      site: $siteId
      address: $contactInfo
      searchable: $searchable
      usingAch: $usingAch
      usingAlipay: $usingAlipay
      usingTrackingParameters: $usingTrackingParameters
      restrictedAccountRegistration: $restrictedAccountRegistration
    ) {
      site {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type OrganizerSiteUpdateGeneralInfoOptions = ExecMutationOptions<OrganizerSiteUpdateGeneralInfoMutationVariables>;

export function organizerSiteUpdateGeneralInfo(options: OrganizerSiteUpdateGeneralInfoOptions) {
  return executeMutation<OrganizerSiteUpdateGeneralInfoMutation, OrganizerSiteUpdateGeneralInfoMutationVariables>(OrganizerSiteUpdateGeneralInfoDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['User']) }
  });
}
