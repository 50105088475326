import * as React from 'react';
import moment from 'moment';
import { lowerCase, round, startCase } from 'lodash-es';

import { Attendance, Enrollment, Student, CfQuestion, CheckedIn, CourseUtils, DAY_SORT_KEY, EnrollmentStatusFilter, EnrollmentUtils, formatAge, PaymentStatus, PaymentService, Season, Site, RosterItem, RosterFilterKind, RosterSortBy, RosterGroupingKind, CourseKind, CourseKindGroup, Rates, StudentUtils } from 'app2/api';
import { Body, BooleanRadioField, CurrencyField, DateInput, DateField, DataTableColumn, DataTableColumnWithStringName, DropdownField, EmailLabelField, FieldInfo, formatCurrency, formatDate, formatEmail, formatPhoneLink, HBox, iso8601Date, Link, Option, OptionButton, PhoneLabelField, TimeField, IconNames, Icon, mergeCols, transformCols, scaleWidths, removeCols, colId, DataTableColumnSort, arrayToString } from 'app2/components';
import { CourseLink, dayOptions, GradeField, courseKindBehavior } from 'app2/views/shared-public';

import { CourseDayField } from 'app2/views/shared/course-day';
import { convertHrColsToRegularCols, MIN_COL_WIDTH, POINTS_TO_PIXELS_RATIO, ReportView } from 'app2/views/shared/datatable';
import { PaymentStatusTag, paymentStatuses } from 'app2/views/shared/enrollment';
import { StudentModal, formatContacts, copyContactsCell } from 'app2/views/shared/student';

import { ScheduleField } from './ScheduleField';
import { StudentNameField } from './StudentNameField';
import { OtherEnrollments, copyOtherEnrollments } from './OtherEnrollments';
import { enrollmentsFilterOptions } from './generated';

export type SiteWithTimezone = Partial<Pick<Site, 'id' | 'timezone'> & {usingTrackingParameters:boolean, usingPickups:boolean, checkoutDestinations:string[], usingPriceTiers?:boolean}>;
export type RosterCourse = {id?:string, site?:SiteWithTimezone, cfQuestions?:CfQuestion[], kind?:CourseKind, season?:{id:string, courseKindGroups?:CourseKindGroup[], hasConfigurablePricing?:boolean}, rates?: Rates, ageMin?:number, ageMax?:number};
export type RosterEnrollment = {id?:string, course?:RosterCourse};

export interface RosterColOptions<T extends RosterEnrollment> {
  // provide either a course or site but not both as it
  // indicates the grouping kind
  site?: SiteWithTimezone;
  season?: Pick<Season, 'id' | 'courseKindGroups' | 'hasConfigurablePricing'>;
  course?: RosterCourse;
  groupingKind?:RosterGroupingKind;
  groupingId?:string;
  status?: EnrollmentStatusFilter;
  cols?:(string | DataTableColumn<T>)[];
  studentModal?:React.ComponentType<{id:string}>;
  date?:DateInput;
  byWeekday?:boolean;
  scaleCols?:boolean;
  cfCols?:DataTableColumn<T>[];
}

export function getRosterColsDefinitions<T extends RosterEnrollment>(options:RosterColOptions<T>) {
  const site = options.site || options.course?.site;
  const timezone = site?.timezone;
  const season = options.course?.season || options.season;
  let courseKinds:(CourseKind | CourseKindGroup)[] = (options.course?.season?.courseKindGroups ?? options.season?.courseKindGroups ?? (options.course?.kind ? [options.course.kind] : undefined));

  if (!courseKinds?.length) {
    courseKinds = [CourseKind.Enrichment];
  }
  const allStudentDetails = courseKinds?.some(k => courseKindBehavior[k].allStudentDetails);
  const hideGradeAndAges = courseKinds?.every(k => courseKindBehavior[k].fields?.grades === false);
  const hideDob = courseKinds?.every(k => courseKindBehavior[k].fields?.grades === false);
  const hideDismissal = courseKinds?.every(k => courseKindBehavior[k].fields?.dismissal === false);
  const usingAge = CourseUtils.usingAge(options.course);

  const activityCol = {
    label: 'Activity',
    name: 'course.name',
    width: 250,
    filterable: true,
    filterParam: 'byCourseId',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Course),
    sortable: true,
    sortParam: RosterSortBy.Course,
    component: CourseLink,
    readOnly: true
  }

  const classroom = {
    label: 'Classroom',
    name: 'student.classroom.name' as keyof T,
    width: MIN_COL_WIDTH,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Classroom),
    sortable: true,
    sortParam: RosterSortBy.Classroom,
    filterParam: 'byClassroom',
    readOnly: true,
    hidden: !allStudentDetails
  }

  const courseDays = {
    label: 'Days and times',
    width: 250,
    filterable: true,
    filterParam: 'byDay',
    getFilterOptions: () => dayOptions,
    sortable: true,
    sortParam: RosterSortBy.Day,
    sortValue: (value:string) => DAY_SORT_KEY[value],
    readOnly: true
  }

  const cols = [{
    ...DropdownField,
    label: 'Group',
    edit:{...DropdownField.edit, additions: true},
    name: 'groups' as keyof T,
    width: 150,
    filterable: true,
    filterParam: 'byGroup',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Group),
    sortable: true,
    sortParam: RosterSortBy.Group,
    // if you want this inline editable you must override and set readOnly false
    readOnly: true
  }, {
    label: 'First name',
    name: 'student.firstName' as keyof T,
    component: StudentNameField,
    nameKind: 'first',
    studentModal: options.studentModal || StudentModal,
    width: 185,
    filterable: true,
    filterParam: 'byStudentFirstName',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.StudentFirstName),
    sortable: true,
    sortParam: RosterSortBy.StudentFirstName,
    readOnly: true
  }, {
    label: 'Last name',
    name: 'student.lastName' as keyof T,
    component: StudentNameField,
    nameKind: 'last',
    studentModal: options.studentModal || StudentModal,
    width: 185,
    filterable: true,
    filterParam: 'byStudentLastName',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.StudentLastName),
    sortable: true,
    sortParam: RosterSortBy.StudentLastName,
    readOnly: true
  }, {
    // note that the student's grade will not display customized
    // grade labels if you do not also download the classroom siteId
    label: 'Grade',
    name: 'student.grade' as keyof T,
    width: MIN_COL_WIDTH,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Grade),
    filterParam: 'byGrade',
    readOnly: true,
    component: GradeField,
    site: site?.id,
    hidden: usingAge || hideGradeAndAges,
  }, {
    label: 'Age',
    name: 'student.age' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true,
    format: formatAge,
    hidden: !usingAge || hideGradeAndAges,
  }, {
    ...DateField,
    label: 'Date of birth',
    name: 'student.dob' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true,
    hidden: hideDob
  }, { 
    label: 'Status',
    name: 'balance' as keyof T,
    id: 'status',
    width: MIN_COL_WIDTH,
    format: (value:number) => value < 0 ? 'Pending' : 'Paid',
    readOnly: true
  }, {
    ...CurrencyField,
    label: 'Amount',
    name: 'balance' as keyof T,
    width: MIN_COL_WIDTH,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Balance),
    sortable: true,
    sortParam: RosterSortBy.Balance,
    filterParam: 'byBalance',
    format: (value:number) => formatCurrency(Math.abs(value)),
    readOnly: true
  },
  {
    label: 'Note', // billing note
    id: 'note',
    name: 'priceConfig.description' as keyof T,
    width: 300,
    readOnly: true
  },
  {
    ...CurrencyField,
    label: 'Discount',
    name: 'discountAmount' as keyof T,
    width: MIN_COL_WIDTH,
    format: (value:number) => formatCurrency(Math.abs(value)),
    readOnly: true,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.DiscountAmount),
    sortable: true,
    sortParam: RosterSortBy.DiscountAmount,
    filterParam: 'byDiscountAmount',
  }, {
    label: 'Discount codes',
    name: 'discountCodes' as keyof T,
    width: 200,
    format: arrayToString,
    readOnly: true,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.DiscountCode),
    filterParam: 'byDiscountCode',
  }, {
    ...CurrencyField,
    label: 'Amount refunded',
    name: 'refundsTotal' as keyof T,
    width: 200,
    format: (value:number) => formatCurrency(Math.abs(value)),
    readOnly: true
  }, { 
    label: 'Billing period',
    name: 'billPeriod' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true
  }, {
    ...DateField,
    label: 'Billing date',
    name: 'billedDate' as keyof T,
    timezone,
    width: 148,
    filterable: true,
    filterParam: 'byBilledDate',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.BilledDate),
    sortable: true,
    sortParam: RosterSortBy.BilledDate,
    readOnly: true
  },
  {
    ...DateField,
    label: 'Added',
    name: 'added' as keyof T,
    timezone,
    width: 148,
    filterable: true,
    filterParam: 'byAdded',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Added),
    sortable: true,
    sortParam: RosterSortBy.Added,
    readOnly: true
  }, { 
    label: 'Schedule',
    name: 'rosterPeriod' as keyof T,
    component: ScheduleField,
    width: MIN_COL_WIDTH,
    readOnly: true,
    hidden: season?.hasConfigurablePricing === false
  }, { 
    label: 'Schedule',
    name: 'rosterPeriods' as keyof T,
    component: ScheduleField,
    width: MIN_COL_WIDTH,
    readOnly: true,
    hidden: season?.hasConfigurablePricing === false
  }, { 
    label: 'Other activities',
    name: 'otherEnrollments' as keyof T,
    width: 350,
    readOnly: true,
    component: <OtherEnrollments />,
    copy: (enrollments:Enrollment[]) => copyOtherEnrollments(enrollments),
    hidden: !options.date && !options.byWeekday
  }, { 
    label: 'Status',
    name: 'paymentStatus',
    width: 200,
    display: { component: PaymentStatusTag },
    readOnly: true,
    filterable: true,
    filterParam: 'byPaymentStatus',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.PaymentStatus),
    copy: (value:PaymentStatus, _:any, info?:FieldInfo<any>) => paymentStatuses[info.value as PaymentStatus]?.label
  }, { 
    label: 'Payment method',
    name: 'paymentService',
    width: 200,
    readOnly: true,
    format: (value:PaymentService) => paymentServiceLabels[value]
  }, {
    label: 'Price tier',
    name: 'enrollmentPriceTierUse.cartPriceTierUse.name' as keyof T,
    id: 'priceTier',
    width: MIN_COL_WIDTH,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.PriceTier),
    sortable: true,
    sortParam: RosterSortBy.PriceTier,
    filterParam: 'byPriceTier',
    readOnly: true,
    hidden: !site?.usingPriceTiers
  }, 
    classroom, {
    ...classroom,
    name: 'student.classroom.displayName' as keyof T,
  }, {
    label: 'Before-class pickup',
    name: 'pickup',
    width: 194,
    hidden: !site?.usingPickups
  }, {
    label: 'Dismissal',
    name: 'dismissal' as keyof T,
    width: MIN_COL_WIDTH,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Dismissal),
    sortable: true,
    sortParam: RosterSortBy.Dismissal,
    filterParam: 'byDismissal',
    readOnly: true,
    hidden: hideDismissal
  }, {
    label: 'Family name',
    name: 'parent.name' as keyof T,
    width: MIN_COL_WIDTH,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Parent),
    sortable: true,
    sortParam: RosterSortBy.Parent,
    filterParam: 'byParent',
    format: (value:string, _:any, info?:FieldInfo<any>) => EnrollmentUtils.getParentName(info.values[0]),
    copy: (value:string, _:any, info?:FieldInfo<any>) => EnrollmentUtils.getParentName(info.values[0]),
    readOnly: true
  }, {
    label: 'Family phone',
    name: 'parent.phone' as keyof T,
    width: 150,
    component: PhoneLabelField, 
    format: (value:string, _:any, info?:FieldInfo<any>) => formatPhoneLink(EnrollmentUtils.getParentPhone(info.values[0])),
    copy: (value:string, _:any, info?:FieldInfo<any>) => EnrollmentUtils.getParentPhone(info.values[0]),
    filterable: false, 
    sortable: false,
    readOnly: true
  }, { 
    label: 'Family email', 
    name: 'parent.email' as keyof T, 
    width: MIN_COL_WIDTH, 
    component: EmailLabelField, 
    format: (value:string, _:any, info?:FieldInfo<any>) => formatEmail(EnrollmentUtils.getParentEmail(info.values[0])),
    copy: (value:string, _:any, info?:FieldInfo<any>) => EnrollmentUtils.getParentEmail(info.values[0]),
    filterable: false, 
    sortable: false,
    readOnly: true
  }, {
    label: 'Season',
    name: 'season.name',
    width: 250,
    filterable: true,
    filterParam: 'bySeason',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Season),
    sortable: true,
    sortParam: RosterSortBy.Season,
    readOnly: true
  }, 
  activityCol, {
    ...activityCol,
    name: 'course.disambiguatedName',
  }, {
    label: 'Location',
    name: 'course.room',
    width: 194,
    filterable: true,
    filterParam: 'byRoom',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Room),
    sortable: true,
    sortParam: RosterSortBy.Room,
    readOnly: true
  }, {
   ...courseDays,
   name: 'course.courseDays',
   component: CourseDayField,
  }, {
    ...courseDays,
    label: 'Days',
    name: 'formattedDays',
    hidden: options.date || options.byWeekday
  }, {
    label: 'Times',
    name: 'formattedTimes',
    width: MIN_COL_WIDTH,
    filterable: false,
    sortable: false,
    readOnly: true
  }, {
    label: 'Day',
    name: 'weekday',
    filterable: true,
    filterParam: 'byDay',
    getFilterOptions: () => dayOptions,
    sortable: true,
    sortParam: RosterSortBy.Weekday,
    sortValue: (value:string) => DAY_SORT_KEY[value],
    readOnly: true,
    hidden: !options.byWeekday || options.date
  }, {
    label: 'Allergies',
    name: 'student.medical.allergies',
    width: 250,
    readOnly: true,
    format: (allergies:Student['medical']['allergies']) => StudentUtils.getFormattedAllergies(allergies),
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.StudentMedicalAllergies),
    filterParam: 'byStudentMedicalAllergies',
  }, {
    label: 'EpiPen',
    name: 'student.medical.epiPen',
    width: 250,
    readOnly: true,
    component: BooleanRadioField,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.StudentMedicalEpiPen),
    filterParam: 'byStudentMedicalEpiPen',
  }, {
    label: 'Medications',
    name: 'student.medical.medications',
    width: 250,
    readOnly: true,
    format: (meds:Student['medical']['medications']) => StudentUtils.getFormattedMedications(meds),
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.StudentMedicalMedications),
    filterParam: 'byStudentMedicalMedications',
  }, {
    label: 'Conditions',
    name: 'student.medical.conditions',
    width: 250,
    readOnly: true,
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.StudentMedicalConditions),
    filterParam: 'byStudentMedicalConditions',
  }, {
    label: 'Comments',
    name: 'student.notes',
    width: 250,
    readOnly: true
  }, {
    label: 'Health & comments',
    name: 'student.formattedMedicalAndNotes',
    width: 250,
    readOnly: true
  }, {
    label: 'Contacts',
    name: 'student.formattedContacts',
    width: 300,
    readOnly: true
  }, {
    label: 'Emergency contact',
    name: 'student.emergencyContacts',
    width: 300,
    format: formatContacts,
    copy: copyContactsCell,
    readOnly: true
  }, {
    label: 'Authorized pickup contacts',
    name: 'student.authorizedPickupContacts',
    width: 300,
    format: formatContacts,
    copy: copyContactsCell,
    readOnly: true
  }, {
    label: 'CC emails',
    name: 'parent.ccContacts',
    width: 200,
    format: formatContacts,
    copy: copyContactsCell,
    readOnly: true
  }, {
    label: 'Overlapping enrollment',
    name: 'overlaps',
    width: 150,
    format: (overlaps:boolean) => {return overlaps ? 'Yes' : 'No'},
    filterable: true,
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.Overlaps),
    filterParam: 'byOverlaps',
    readOnly: true
  }, {
    ...DateField,
    label: 'Start date',
    name: 'startDate',
    timezone,
    width: 148,
    filterable: true,
    filterParam: 'byEnrollmentStartDate',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.EnrollmentStartDate),
    sortable: true,
    sortParam: RosterSortBy.EnrollmentStartDate,
    readOnly: true
  }, {
    ...DateField,
    label: 'End date',
    name: 'endDate',
    timezone,
    width: 148,
    filterable: true,
    filterParam: 'byEnrollmentEndDate',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.EnrollmentEndDate),
    sortable: true,
    sortParam: RosterSortBy.EnrollmentEndDate,
    readOnly: true
  }, 
  // atttendance specific cols
  {
    ...DateField,
    label: 'Activity start date',
    name: 'course.startDate',
    timezone,
    width: 148,
    filterable: true,
    filterParam: 'byStartDate',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.StartDate),
    sortable: true,
    sortParam: RosterSortBy.StartDate,
    readOnly: true
  }, {
    ...DateField,
    label: 'Activity end date',
    name: 'course.endDate',
    timezone,
    width: 148,
    filterable: true,
    filterParam: 'byEndDate',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.EndDate),
    sortable: true,
    sortParam: RosterSortBy.EndDate,
    readOnly: true
  }, {
    ...DropdownField,
    width: MIN_COL_WIDTH,
    name: 'checkedIn', 
    label:'Checked in', 
    options: checkInOptions,
    disallowNone: true,
    display: OptionButton,
    edit: <OptionButton padding='12px 12px 12px 12px' />,
    filterable: true,
    filterParam: 'byCheckedIn',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.CheckedIn),
    onChange: onChangeCheckIn,
    sortParam: RosterSortBy.CheckedIn,
    sortable: true,

  }, {
    ...TimeField,
    width: 150,
    name: 'checkedInAt', 
    label:'Checked in at', 
    timezone, 
    edit: {...TimeField.edit, placeholder: false},
    onChange: onChangeCheckInAt,
    sortParam: RosterSortBy.CheckedInTime,
    sortable: true,
  }, {
    width: 200,
    name: 'checkedInBy', 
    label:'Checked in by', 
    timezone, 
    readOnly: true,
  }, {
    ...DropdownField,
    width: MIN_COL_WIDTH,
    name: 'checkedOut', 
    label: 'Checked out', 
    options: checkOutOptions,
    disallowNone: true,
    onChange: onChangeCheckOut,
    display: OptionButton,
    edit: <OptionButton padding='12px 12px 12px 12px' />,
    filterable: true,
    filterParam: 'byCheckedOut',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.CheckedOut),
    sortParam: RosterSortBy.CheckedOut,
    sortable: true,
  }, {
    ...TimeField,
    width: 175,
    name: 'checkedOutAt', 
    label: 'Checked out at', 
    timezone, 
    edit: {...TimeField.edit, placeholder: false},
    onChange: onChangeCheckOutAt,
    sortParam: RosterSortBy.CheckedOutTime,
    sortable: true,
  }, {
    ...DropdownField,
    width: MIN_COL_WIDTH,
    name: 'checkoutDest', 
    label: 'Dismissal', 
    options: site?.checkoutDestinations,
    placeholder: (_:any, _2:any, info:FieldInfo<RosterItem>) => info.record.dismissal,
    edit: {
      ...DropdownField.edit,
      placeholder: (_:any, _2:any, info:FieldInfo<RosterItem>) => info.record.dismissal,
    }
  }, {
    width: 200,
    name: 'checkedOutBy', 
    label:'Checked out by', 
    timezone,
    readOnly: true
  }, {
    width: 200,
    name: 'attendanceHours', 
    label:'Hours', 
    format: (_:any, _2:any, info:FieldInfo<RosterItem>) => round(moment(info.record.checkedOutAt).diff(moment(info.record.checkedInAt), 'h', true), 2) || '',
    readOnly: true
  },
  {
    label: 'Rate',
    name: 'billingDescription' as keyof T,
    width: 200,
    readOnly: true
  },
  { 
    ...DateField,
    label: 'Billing date',
    id: 'billingDate',
    name: 'priceConfig.billingDate' as keyof T,
    timezone,
    width: 148,
    readOnly: true
  },
  {
    ...DateField,
    label: 'Billing date',
    id: 'usageBillingDate',
    name: 'priceConfig.billingDate',
    timezone,
    width: 148,
    readOnly: true,
    filterable: true,
    filterParam: 'byUsageBillingDate',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.UsageBillingDate),
    sortable: true,
    sortParam: RosterSortBy.UsageBillingDate,
  },
  {
    label: 'Attendance date',
    id: 'usageDate',
    name: 'priceConfig.attendance.date',
    timezone,
    width: 148,
    readOnly: true,
    filterable: true,
    filterParam: 'byAttendanceDate',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.AttendanceDate),
    sortable: true,
    sortParam: RosterSortBy.AttendanceDate,
    format: (_:any, _2:any, info:FieldInfo<Attendance>) => <Link to={`/activities/manage/${options.course.id}/attendance/${iso8601Date(info.record?.date)}`}>{formatDate(info.record?.date, 'long')}</Link>
  },
  {
    ...TimeField,
    width: 150,
    id: 'usageCheckedInAt',
    name: 'priceConfig.attendance.checkedInAt', 
    label:'Check-in time', 
    timezone,
    readOnly: true,
    sortable: true,
    sortParam: RosterSortBy.CheckedInTime,
  },
  {
    ...TimeField,
    width: 175,
    id: 'usageCheckedOutAt',
    name: 'priceConfig.attendance.checkedOutAt', 
    label: 'Checked-out time', 
    timezone, 
    readOnly: true,
    sortable: true,
    sortParam: RosterSortBy.CheckedOutTime,
  },
  {
    // session start date/time...only used in downloading session dates
    ...DateField,
    width: 175,
    id: 'startsAt',
    name: 'startsAt', 
    label: 'Date', 
    timezone, 
  },
  {
    ...CurrencyField,
    id: 'usageRate',
    label: 'Rate',
    name: 'priceConfig.rate' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true,
    filterable: true,
    filterParam: 'byUsageRate',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.UsageRate),
    sortable: true,
    sortParam: RosterSortBy.UsageRate,
  },
  {
    id: 'usageUnit',
    label: 'Unit',
    name: 'priceConfig.usageUnit' as keyof T,
    width: MIN_COL_WIDTH,
    format: (value:string) => startCase(lowerCase(value)),
    readOnly: true
  },
  {
    label: `${startCase(options.course?.rates?.usage?.[0]?.unit?.toLowerCase() || 'unit')}s billed`,
    id:   'usageUnitsBilled',
    name: 'priceConfig.unitsBilled' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true,
    filterable: true,
    filterParam: 'byUsageUnitsBilled',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.UsageUnitsBilled),
    sortable: true,
    sortParam: RosterSortBy.UsageUnitsBilled,
  },
  {
    label: 'Billing round-up',
    id:   'roundingIncrement',
    name: 'priceConfig.roundingIncrement' as keyof T,
    width: 180,
    readOnly: true,
    format: (value:number) => value ? `${value} min` : '',
    filterable: true,
    filterParam: 'byRoundingIncrement',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.RoundingIncrement),
    sortable: true,
    sortParam: RosterSortBy.RoundingIncrement,
  }, 
  {
    label: 'Grace period',
    id:   'gracePeriod',
    name: 'priceConfig.gracePeriod' as keyof T,
    width: 180,
    readOnly: true,
    format: (value:number) => value ? `${value} min` : '',
    filterable: true,
    filterParam: 'byGracePeriod',
    getFilterOptions: () => getFilterOptions(options, RosterFilterKind.GracePeriod),
    sortable: true,
    sortParam: RosterSortBy.GracePeriod,
  },
  {
    label: 'Tracking',
    name: 'trackingParameters',
    hidden: !site?.usingTrackingParameters
  }
].filter(c => !!c) as DataTableColumn<T>[]

  function onChangeCheckIn(value:CheckedIn, info:FieldInfo<RosterItem, 'checkedIn'>, settingMultipleValues:boolean) {
    if (value == CheckedIn.Present && !info.record.checkedInAt) {
      info.form.setValue('checkedInAt', moment() as any);
    }
    else 
    if (value != CheckedIn.Present && info.record.checkedInAt) {
      info.form.setValue('checkedInAt', null);
      info.form.setValue('checkedOut', false);
      info.form.setValue('checkedOutAt', null);
      info.form.setValue('checkoutDest', null);
    }
  }

  function onChangeCheckInAt(value:moment.Moment, info:FieldInfo<RosterItem, 'checkedInAt'>, settingMultipleValues:boolean) {
    if (value && info.record.checkedIn != CheckedIn.Present) {
      info.form.setValue('checkedIn', CheckedIn.Present);
    }
    else 
    if (!value && info.record.checkedIn == CheckedIn.Present) {
      info.form.setValue('checkedIn', null);
      info.form.setValue('checkedOut', false);
      info.form.setValue('checkedOutAt', null);
      info.form.setValue('checkoutDest', null);
    }
  }

  function onChangeCheckOut(value:CheckedIn, info:FieldInfo<RosterItem, 'checkedOut'>, settingMultipleValues:boolean) {
    if (value) {
      info.form.setValue('checkedIn', CheckedIn.Present);
      info.form.setValue('checkedOutAt', info.record.checkedOutAt || moment() as any);

      if (!info.record.checkoutDest) {
        info.form.setValue('checkoutDest', info.record.dismissal);
      }
    }
    else 
    if (!value) {
      info.form.setValue('checkedOutAt', null);
      info.form.setValue('checkoutDest', null);
    }
  }

  function onChangeCheckOutAt(value:moment.Moment, info:FieldInfo<RosterItem, 'checkedOutAt'>, settingMultipleValues:boolean) {
    if (value) {
      info.form.setValue('checkedIn', CheckedIn.Present);
      info.form.setValue('checkedOut', true);

      if (!info.record.checkoutDest) {
        info.form.setValue('checkoutDest', info.record.dismissal);
      }
    }
    else 
    if (!value) {
      info.form.setValue('checkedOut', false);
      info.form.setValue('checkoutDest', null);
    }
  }

  return cols;
}

export function extractFilterOptionVars<T extends RosterEnrollment>(options:Partial<RosterColOptions<T>>) {
  const groupingId = options.groupingId || options.season?.id || options.course?.id;
  const groupingKind = options.groupingKind || (options.season ? RosterGroupingKind.Season : RosterGroupingKind.Course);
  const enrollmentStatus = options.status || EnrollmentStatusFilter.Rostered;

  return {groupingId, groupingKind, enrollmentStatus}
}

export async function getFilterOptions<T>(options:Partial<RosterColOptions<T>>, filterKind: RosterFilterKind):Promise<Option[]> {
  const [_, result] = await enrollmentsFilterOptions({ variables: { ...extractFilterOptionVars(options), filterKind } });
  return result.data ? Object.values(result.data)[0] as Option[] : null;
}

function getRosterCols<T>(options:RosterColOptions<T>) {
  const defs = getRosterColsDefinitions<T>(options);
  const cols = mergeCols(options.cols, defs);

  // filter out any w/o a label because it menas the base collection
  // removed it because its no appropriate for the season (or something)
  cols.filter(c => !c.label).forEach(c => console.log(`Bad roster col ${colId(c)}`));

  return cols.filter(c => c.label != null);
}

// on the server we use a page of 760 points.  
// 1 point = 1.333 pixels
export const PAGE_WIDTH = Math.floor(760 * POINTS_TO_PIXELS_RATIO);

export function createRosterView<T>(options:RosterColOptions<T>, base?:ReportView) {
  const hidden = getRosterColsDefinitions(options).filter(c => c.hidden).map(c => colId(c.name));

  // note that any hidden columns will get removed because they aren't hidden, they 
  // shouldn't be part of the view (vs. the user hide the column).
  
  const groups = new Set(base?.groups?.map(g => colId(g)) || []);
  const cols = transformCols<T>([
    (cols => base?.groups ? base.groups.map(g => ({name:colId(g)} as DataTableColumn)).concat(cols as any) : cols),
    (cols => getRosterCols<T>({...options, cols:options.cols || cols as any}) as  any),
    (cols => cols.concat(options.cfCols as any)),
    (cols => cols.filter(c => !!c && !c.hidden)),
    (cols => cols.map(c => ({...c, hidden: c.hidden || groups.has(colId(c.name))}))),
    (cols => !base?.sorts ? cols : applySort(cols as any, base.sorts.map(c => colId(c)))),
    (cols => options.scaleCols ? scaleWidths(cols as any, PAGE_WIDTH) : cols),
  ], base?.cols || [] as any);

  const groupCols = base?.groups ? getRosterCols({...options, cols: removeCols(base?.groups as any, hidden) as any}) : undefined;

  return {
    title: base?.title?.map?.(t => ({cols: removeCols(t.cols, hidden).map(c => (options.date || options.byWeekday) && c == 'formattedDaysAndTimes' ? 'formattedTimes' : c), separator: t.separator})),
    groups: groupCols?.map(c => ({name: c.name as string})),
    cols: cols as unknown as DataTableColumnWithStringName<T>[]
  }
}

function applySort(cols: DataTableColumn[], sortCols:(string | DataTableColumn)[]) {
  // currently we only support sorting on one column so we need
  // to exclude missing and hidden columns so we can sort on the next one
  // because usually the hidden ones have rows with all the same data (so it 
  // would appear sort isn't working)
  sortCols = sortCols.filter(sortCol => {
    const col = cols.find(c => colId(c) == colId(sortCol));
    return col && col.sortable && !col.hidden;
  });

  return cols.map(c => ({...c, sort: colId(c) == colId(sortCols[0]) ? DataTableColumnSort.ascending : DataTableColumnSort.none}));
}

function icon(name:IconNames, color:string) {
  return <Icon name={name} size='small' color={color}
    border='solid 1px' width='24px' minWidth='24px' maxWidth='24px' minHeight='24px' hAlign='center' vAlign='center' layout='hbox'
    borderColor={color} borderRadius='12px' overflow='hidden' cursor='pointer'
    />
}

const checkInOptions = [{
  value: CheckedIn.Present,
  text: 'Checked in',
  label: {icon: icon('UserCheck', 'success'), color: 'success'}
}, {
  value: CheckedIn.Absent,
  text: 'Absent',
  label: {icon: icon('X', 'error'), color: 'error'},
}, {
  value: null,
  text: '',
  label: {icon: icon(null, 'grey'), color: 'grey'},
}]

const checkOutOptions = [{
  value: true,
  text: 'Checked out',
  label: {icon: icon('LogOut', 'success'), color:'success'}
}, {
  value: false,
  text: '',
  label: {icon: icon(null, 'grey'), color: 'grey'},
},
// {
//   value: null,
//   text: '',
//   label: {icon: icon(null, 'grey'), color: 'grey'},
// }
]

// this is only used for downloading reports
export const rosterColsSortFilterParams = convertHrColsToRegularCols(getRosterColsDefinitions({site:{}, course:{}})).sortFilterMap;

export const paymentServiceLabels = {
  [PaymentService.Card]: 'Card',
  [PaymentService.Ach]: 'Bank',
  [PaymentService.Affirm]: 'Affirm',
  [PaymentService.Alipay]: 'Alipay',
  [PaymentService.Free]: '',
}
