import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentMedicalFieldsFragmentDoc } from '../../../shared/student/tabs/gql/generated/studentMedicalFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteStudentsQueryVariables = Types.Exact<{
  site: Types.Scalars['ID'];
  sorts?: Types.InputMaybe<Array<Types.Sort> | Types.Sort>;
  filters?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
}>;

export type SiteStudentsQuery = {
  site?:
    | {
        studentsQuery?:
          | {
              count?: number | null | undefined;
              items?:
                | Array<{
                    id: string;
                    firstName: string;
                    lastName: string;
                    firstNameWithNickname: string;
                    name: string;
                    grade: string;
                    age?: number | null | undefined;
                    dob?: string | null | undefined;
                    enrollmentCount?: number | null | undefined;
                    nickname?: string | null | undefined;
                    formattedContacts: string;
                    notes?: string | null | undefined;
                    classroom?: { siteId: string; displayName: string } | null | undefined;
                    parents: Array<{ id: string; name?: string | null | undefined; phone?: string | null | undefined; email: string }>;
                    emergencyContacts: Array<{ name?: string | null | undefined; email?: string | null | undefined; phone?: string | null | undefined }>;
                    authorizedPickupContacts: Array<{ name?: string | null | undefined; email?: string | null | undefined; phone?: string | null | undefined } | null | undefined>;
                    medical?:
                      | {
                          epiPen?: boolean | null | undefined;
                          conditions?: string | null | undefined;
                          allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                          medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const SiteStudentsDocument = gql`
  query SiteStudents($site: ID!, $sorts: [Sort!], $filters: [Filter!]) {
    site(site: $site) {
      studentsQuery(pageSize: 1000, sorts: $sorts, filters: $filters) {
        count
        items {
          id
          firstName
          lastName
          firstNameWithNickname
          name
          grade
          age
          dob
          enrollmentCount
          ...studentMedicalFields
          nickname
          classroom {
            siteId
            displayName
          }
          parents {
            id
            name
            phone
            email
          }
          formattedContacts
          emergencyContacts {
            name
            email
            phone
          }
          authorizedPickupContacts {
            name
            email
            phone
          }
        }
      }
    }
  }
  ${StudentMedicalFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteStudentsArgs = MakeOptional<UseQueryArgs<SiteStudentsQueryVariables, SiteStudentsQuery>, 'query'>;

export function useSiteStudentsQuery(options: SiteStudentsArgs = {}) {
  return useQuery<SiteStudentsQueryVariables, SiteStudentsQuery>({ query: SiteStudentsDocument, ...options });
}

export type SiteStudentsOptions = ExecQueryOptions<SiteStudentsQueryVariables>;

export function siteStudents(options: SiteStudentsOptions) {
  return executeQuery<SiteStudentsQuery, SiteStudentsQueryVariables>(SiteStudentsDocument, options);
}

export type SiteStudentsSelections = SiteStudentsQuery['site'];
