import * as React from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { PaymentService } from 'app2/api';
import { Modal } from 'app2/components';
import { StripeElements2 } from 'app/scriptLoading';
import { showStripeModal } from 'app2/views/shared';

import { parentUpdateCartPaymentService } from '../generated';

import { ParentUserWithCartSetupIntentSelections, parentUserWithCartSetupIntent } from './generated'

interface Props {
  user:ParentUserWithCartSetupIntentSelections;
}

export function AchModal(props:Props) {
  const stripe = useStripe();
  const elements = useElements();
  const defaultValues = {
    billingDetails: {
      name: props.user.name,
      email: props.user.email,
    }
  }

  function render() {
    return <Modal title="Add a bank account" onOk={onSaveAch}>
      <PaymentElement options={{defaultValues}} />
    </Modal>
  }

  async function onSaveAch() {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.href}`,
      },
      redirect: "if_required"
    });

    if (!result.error) {
      parentUpdateCartPaymentService({ variables: { paymentService: PaymentService.Ach }});
    }

    return !result.error;
  }

  return render();
}

export async function showAchModal() {
  const [_, userResult] = await parentUserWithCartSetupIntent({variables: {}});
  const user = userResult?.data?.user;
  const cart = user?.cart;
  const options = {
    clientSecret: cart?.stripeSetupIntentToken
  };

  const result = await showStripeModal(<AchModal user={user} />, options);

  return result.action;
}