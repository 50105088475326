import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FamilySetInvoiceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  invoice: Types.Scalars['Boolean'];
}>;

export type FamilySetInvoiceMutation = { familySetInvoice?: { invoice: boolean } | null | undefined };

export const FamilySetInvoiceDocument = gql`
  mutation FamilySetInvoice($id: ID!, $invoice: Boolean!) @urql(additionalTypenames: ["Family"]) {
    familySetInvoice(id: $id, invoice: $invoice) {
      invoice
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type FamilySetInvoiceOptions = ExecMutationOptions<FamilySetInvoiceMutationVariables>;

export function familySetInvoice(options: FamilySetInvoiceOptions) {
  return executeMutation<FamilySetInvoiceMutation, FamilySetInvoiceMutationVariables>(FamilySetInvoiceDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Family']) }
  });
}
