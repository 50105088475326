import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PublicUserWithCartQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PublicUserWithCartQuery = {
  user?:
    | {
        cart?:
          | {
              total: number;
              enrollments: Array<{
                __typename: 'Enrollment';
                status: Types.EnrollmentStatusEnum;
                templateId?: string | null | undefined;
                id: string;
                amount?: number | null | undefined;
                priceConfig?:
                  | { kind: Types.PriceConfigKind }
                  | { kind: Types.PriceConfigKind }
                  | { kind: Types.PriceConfigKind }
                  | { template: boolean; deposit?: boolean | null | undefined; depositAmount?: number | null | undefined; installmentDates?: Array<string> | null | undefined; kind: Types.PriceConfigKind }
                  | { kind: Types.PriceConfigKind }
                  | null
                  | undefined;
                course: {
                  id: string;
                  name?: string | null | undefined;
                  classesCount: number;
                  courseFrameworkId?: string | null | undefined;
                  courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
                  company?: { slug: string; name?: string | null | undefined; id: string } | null | undefined;
                  site: { restrictedAccountRegistration?: boolean | null | undefined };
                };
              }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const PublicUserWithCartDocument = gql`
  query PublicUserWithCart {
    user {
      cart {
        total
        enrollments {
          id
          amount
          __typename
          ... on Enrollment {
            status
            templateId
            priceConfig {
              kind
              ... on SeasonPriceConfig {
                template
                deposit
                depositAmount
                installmentDates
              }
            }
            course {
              id
              name
              classesCount
              courseDays {
                days
                start
                finish
              }
              courseFrameworkId
              company {
                slug
                name
                id
              }
              site {
                restrictedAccountRegistration
              }
            }
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type PublicUserWithCartArgs = MakeOptional<UseQueryArgs<PublicUserWithCartQueryVariables, PublicUserWithCartQuery>, 'query'>;

export function usePublicUserWithCartQuery(options: PublicUserWithCartArgs = {}) {
  return useQuery<PublicUserWithCartQueryVariables, PublicUserWithCartQuery>({ query: PublicUserWithCartDocument, ...options });
}

export type PublicUserWithCartOptions = ExecQueryOptions<PublicUserWithCartQueryVariables>;

export function publicUserWithCart(options: PublicUserWithCartOptions) {
  return executeQuery<PublicUserWithCartQuery, PublicUserWithCartQueryVariables>(PublicUserWithCartDocument, options);
}

export type PublicUserWithCartSelections = PublicUserWithCartQuery['user'];
