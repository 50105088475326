import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSitePayoutsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminSitePayoutsQuery = {
  site?:
    | {
        id: string;
        payouts?:
          | Array<{
              id: string;
              scheduledAt?: string | null | undefined;
              status?: string | null | undefined;
              amount?: number | null | undefined;
              paymentServiceId?: string | null | undefined;
              paused?: boolean | null | undefined;
              lastError?: string | null | undefined;
              sites?: Array<{ name?: string | null | undefined }> | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminSitePayoutsDocument = gql`
  query AdminSitePayouts($id: ID!) {
    site(site: $id) {
      id
      payouts {
        id
        scheduledAt
        status
        amount
        paymentServiceId
        paused
        lastError
        sites {
          name
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminSitePayoutsArgs = MakeOptional<UseQueryArgs<AdminSitePayoutsQueryVariables, AdminSitePayoutsQuery>, 'query'>;

export function useAdminSitePayoutsQuery(options: AdminSitePayoutsArgs = {}) {
  return useQuery<AdminSitePayoutsQueryVariables, AdminSitePayoutsQuery>({ query: AdminSitePayoutsDocument, ...options });
}

export type AdminSitePayoutsOptions = ExecQueryOptions<AdminSitePayoutsQueryVariables>;

export function adminSitePayouts(options: AdminSitePayoutsOptions) {
  return executeQuery<AdminSitePayoutsQuery, AdminSitePayoutsQueryVariables>(AdminSitePayoutsDocument, options);
}

export type AdminSitePayoutsSelections = AdminSitePayoutsQuery['site'];
