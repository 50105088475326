import * as React from 'react'

import { DeepPartial, User } from 'app2/api';
import { ActionButton, dataTableData, DataTableHandle, MenuItem } from 'app2/components';
import { copyEmails } from 'app2/views/shared';

type ParentWithEmail = {parent?: DeepPartial<Pick<User, 'email' | 'ccContacts'>>};

interface Props<T extends ParentWithEmail> {
  button?:boolean;
  table:React.RefObject<DataTableHandle<T>>;
}

export function CopyParentEmailsAction<T extends ParentWithEmail>(props:Props<T>) {
  function render() {
    return props.button
      ? <ActionButton selection={false} icon='User' onClick={handleCopyEmails}>Copy family emails</ActionButton>
      : <MenuItem label="Copy family emails" onClick={handleCopyEmails} />;
  }

  function handleCopyEmails() {
    const enrollments = dataTableData<T>(props.table.current) as ParentWithEmail[];
    copyEmails(enrollments.map(e => [e.parent.email, ...(e.parent.ccContacts?.map(c => c.email) || [])]))
  }

  return render();
}
