import { DataTableColumn } from 'app2/components';

import { discountDefinitionFields } from '../discounts';
import { participantFields, studentFields } from '../student';

export type EntityType = 'Site' | 'Season' | 'Course' | 'Company' | 'DiscountDefinition' | 'Student' | 'Contact';

type FieldsFunction = (props:any) => DataTableColumn[];

const fields:Partial<Record<EntityType, FieldsFunction>> = {
  'DiscountDefinition': discountDefinitionFields,
  'Student': studentFields,
  'Contact': participantFields,
}

export function getFields(type:EntityType, props:any) {
  return fields[type](props);
}
