import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentWithdrawMutationVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID'];
}>;

export type ParentWithdrawMutation = { parentWithdrawEnrollment?: { enrollment?: { id: string } | null | undefined } | null | undefined };

export const ParentWithdrawDocument = gql`
  mutation ParentWithdraw($enrollmentId: ID!) @urql(additionalTypenames: ["Receipt", "Refund"]) {
    parentWithdrawEnrollment(enrollmentId: $enrollmentId) {
      enrollment {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentWithdrawOptions = ExecMutationOptions<ParentWithdrawMutationVariables>;

export function parentWithdraw(options: ParentWithdrawOptions) {
  return executeMutation<ParentWithdrawMutation, ParentWithdrawMutationVariables>(ParentWithdrawDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Receipt', 'Refund']) }
  });
}
