import * as React from 'react';

import { Order, PaymentStatus } from 'app2/api';
import { Beta } from 'app2/views';
import { Body, VBox, Button, Modal, NotificationManager, NotificationType } from 'app2/components';

import { parentProcessPayment } from './generated';

type ProcessNowOrder = Pick<Order, 'id' | 'canProcessNow'>;

interface Props {
  order: ProcessNowOrder;
}

export function PayNowAction(props: Props) {
  const order = props.order;

  function render() {
    if (!order?.canProcessNow) {
      return <></>;
    }

    return (
      <Beta>
        <VBox hAlign="center" vAlign="center">
          <Button small onClick={renderModal}>
            Pay now
          </Button>
        </VBox>
      </Beta>
    );
  }

  function renderModal() {
    return (
      <Modal title="Pay now?" ok="Yes, pay now" onOk={onClickPayNow}>
        <Body>Are you sure you want to pay now?</Body>
      </Modal>
    );
  }

  async function onClickPayNow() {
    const [success, result] = await parentProcessPayment({ variables: { id: order.id } });

    if (success) {
      let type: NotificationType;
      
      switch (result.data.parentProcessPayment.status) {
        case PaymentStatus.Paid:
          type = 'success';
          break;
        case PaymentStatus.RetriableError:
        case PaymentStatus.Failed:
          type = 'error';
          break;
        default:
          type = 'warning';
          break;
      };
      NotificationManager.add({ type, message: result.data.parentProcessPayment.message });
    }

    return success;
  }

  return render();
}

PayNowAction.fieldProps = {
  valueProperty: 'order'
};
