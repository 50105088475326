import * as React from 'react'
import { useHistory } from 'react-router';

import { register } from 'app2/api';
import { CheckboxField, EmailField, FormModel, HBox, InputField, Link, Panel, Section, useForm } from 'app2/components';
import { marketingUrl, PublicPage, useRestrictedSiteAccountRegistration, getLoginUrl } from 'app2/views/shared-public';

import { handleAuthResponse } from './handleAuthResponse';

export function Register() {
  const history = useHistory();
  const form = useForm({}, []);

  useRestrictedSiteAccountRegistration();

  function render() {
    return <PublicPage title='Enter your info' useMaxWidth>
      <Panel title='We need just a few pieces of basic information' type='edit' onOk={submit} ok='Register' footerActions={renderFooter()} form={form} onNavigation='nothing'>
        <Section name='firstName' label='First name' required component={InputField} />
        <Section name='lastName' label='Last name' required component={InputField} />
        <Section name='email' label='Email address' required component={EmailField} />
        <Section name='password' label='Password' required component={InputField} type='password' />
        <Section name='terms' label={<>I agree to the&nbsp;<Link to={marketingUrl('/tou')} target="_blank">terms and conditions</Link></>} required boolean component={CheckboxField} />
      </Panel>
    </PublicPage>
  }

  function renderFooter() {
    return <HBox text='body' vAlign='center' gap='$8'>Already have an account? <Link to={getLoginUrl()}>Log in here</Link></HBox>
  }

  async function submit(form:FormModel) {
    const response = await register(form.values);
    return handleAuthResponse(form, response, history);
  }

  return render();
}
