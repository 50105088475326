import * as React from 'react';

import { formatAge, Student, StudentUtils } from 'app2/api';
import { Body, BooleanRadioField, DateField, HBox, EmailLabelField, FieldInfo, InputField, Link, PhoneLabelField } from 'app2/components';
import { GradeField } from 'app2/views/shared-public';
import { StudentModal } from 'app2/views/shared';

import { MIN_COL_WIDTH } from '../datatable';

import { StudentNameField } from '../roster/StudentNameField';

interface Props {
  site:string;
}

export function studentFields<T extends Student = any>(props:Props) {
  return [{
    label: "ID",
    name: 'id' as keyof T,
    sortable: true,
    filterable: true
  }, {
    label: "First name",
    name: 'firstName' as keyof T,
    required: true,
    edit: InputField,
    display: <StudentNameField studentModal={StudentModal} nameKind='first' />,
    sortable: true,
    filterable: true
  }, {
    label: "Last name",
    name: 'lastName' as keyof T,
    required: true,
    edit: InputField,
    display: <StudentNameField studentModal={StudentModal} nameKind='last' />,
    sortable: true,
    filterable: true
  }, {
    label: "Name",
    name: 'name' as keyof T,
    required: true,
    edit: InputField,
    display: <StudentNameField studentModal={StudentModal} nameKind='full' />,
    sortable: true,
    filterable: true
  }, {
    // note that the student's grade will not display customized
    // grade labels if you do not also download the classroom siteId
    label: 'Grade',
    name: 'grade' as keyof T,
    width: MIN_COL_WIDTH,
    filterable: true,
    readOnly: true,
    component: GradeField,
    site: props.site
  }, {
    label: 'Age',
    name: 'age' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true,
    format: formatAge,
  }, {
    ...DateField,
    label: 'Date of birth',
    name: 'dob' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true
  }, {
    label: 'Enrollment count',
    name: 'enrollmentCount' as keyof T,
    width: MIN_COL_WIDTH,
    sortable: true,
    filterable: true,
    readOnly: true
  }, {
    label: 'Classroom',
    name: 'classroom.displayName' as keyof T,
    width: MIN_COL_WIDTH,
    filterable: true,
    sortable: true,
    readOnly: true,
  }, {
    label: 'Allergies',
    name: 'medical.allergies',
    width: 250,
    readOnly: true,
    format: (allergies:Student['medical']['allergies']) => StudentUtils.getFormattedAllergies(allergies),
    filterable: true,
    filterParam: 'byStudentMedicalAllergies',
  }, {
    label: 'EpiPen',
    name: 'medical.epiPen',
    width: 250,
    readOnly: true,
    component: BooleanRadioField,
    filterable: true,
  }, {
    label: 'Medications',
    name: 'medical.medications',
    width: 250,
    readOnly: true,
    format: (meds:Student['medical']['medications']) => StudentUtils.getFormattedMedications(meds),
    filterable: true,
  }, {
    label: 'Conditions',
    name: 'medical.conditions',
    width: 250,
    readOnly: true,
    filterable: true,
  }, {
    label: 'Comments',
    name: 'notes',
    width: 250,
    readOnly: true
  }, {
    label: 'Health & comments',
    name: 'formattedMedicalAndNotes',
    width: 250,
    readOnly: true
  }, {
    label: 'Family name',
    name: 'parents.0.name' as keyof T,
    width: MIN_COL_WIDTH,
    filterable: true,
    sortable: true,
    readOnly: true
  }, {
    label: 'Family phone',
    name: 'parenst.0.phone' as keyof T,
    width: 150,
    component: PhoneLabelField, 
    filterable: true, 
    sortable: true,
    readOnly: true
  }, { 
    label: 'Family email', 
    name: 'parents.0.email' as keyof T, 
    width: MIN_COL_WIDTH, 
    component: EmailLabelField, 
    filterable: true, 
    sortable: true,
    readOnly: true
  }, {
    label: 'Contacts',
    name: 'formattedContacts',
    width: 300,
    readOnly: true
  }, {
    label: 'Emergency contact',
    name: 'emergencyContacts',
    width: 300,
    format: formatContacts,
    copy: copyContactsCell,
    readOnly: true
  }, {
    label: 'Authorized pickup contacts',
    name: 'authorizedPickupContacts',
    width: 300,
    format: formatContacts,
    copy: copyContactsCell,
    readOnly: true
  }]
}

type StudentContact = Student['emergencyContacts'][0] | Student['authorizedPickupContacts'][0];

export function formatContacts(contacts:StudentContact[]) {
  return contacts?.map((contact, index) => {
    return <HBox key={index} maxLines={1}>
      <span>
        {contact.name && <Body mr="$8">{contact.name}</Body>}
        {contact.phone && <Link mr="$8" text="body" to={`tel:${contact.phone}`}>{contact.phone}</Link>}
        {contact.email && <Link text="body" to={`mailto:${contact.email}`}>{contact.email}</Link>}
      </span>
    </HBox>
  })
}

export function copyContactsCell(contacts:StudentContact[]) {
  if (!contacts) {
    return '';
  }

  return contacts.map(contact => contact.name + ';' + (contact.phone || '') + ';' + (contact.email || '')).join('\n')
}
