import * as React from 'react'

import { CourseKind } from 'app2/api'
import { DropdownField, OptionPicker, OptionPickerProps } from 'app2/components';
import { courseKindBehavior, purchaseableKinds } from 'app2/views/shared-public';

interface CourseKindPickerProps extends OptionPickerProps {
  kinds?:CourseKind[];
}

export function CourseKindPicker(props:CourseKindPickerProps) {
  const {kinds, selected, options:propsOptions, ...remaining} = props;

  // there's limits to what you can change an activity kind to
  // see T6928 and https://joinhomeroom.slack.com/archives/CSLDJE0HE/p1733875004849569
  // Lesson sets and time slots are locked and can't be changed
  // others can not be changed to lesson set or time slot
  const options = React.useMemo(() => optionsForKinds(kinds, !props.multiple, selected), [kinds, selected]);

  return <OptionPicker options={options} selected={selected} {...remaining} />
}

function optionsForKinds(kinds:CourseKind[], restrictToCanChangeTo?:boolean, selected?:CourseKind | CourseKind[]) {
  return courseKindOptions.filter(o => (!kinds || kinds.includes(o.value)) && (!restrictToCanChangeTo || !selected || Array.isArray(selected) || canChangeTo(selected, o.value)));
}

function canChangeTo(selected:CourseKind, changeTo:CourseKind) {
  const changeableTo = courseKindBehavior[selected].changeableTo;

  return changeableTo.includes(changeTo);
}


CourseKindPicker.fieldProps = OptionPicker.fieldProps;
CourseKindPicker.defaultProps = {
  type: 'button-strip'
}

const courseKindOptions = [{
  label: 'Enrichment',
  value: CourseKind.Enrichment,
}, {
  label: 'Extended care',
  value: CourseKind.ExtendedCare,
}, {
  label: 'Camp',
  value: CourseKind.Camp,
}, {
  label: 'Lessons',
  value: CourseKind.LessonSet,
}, {
  label: 'Time slot',
  value: CourseKind.TimeSlot,
}, {
  label: 'Team fee',
  value: CourseKind.AthleticReg,
}, {
  label: 'Individual fee',
  value: CourseKind.AthleticIndividualReg,
}, {
  label: 'Team',
  value: CourseKind.Team,
}, {
  label: 'Game',
  value: CourseKind.Game,
}];

export function courseKindField(kinds:CourseKind[], props?:Partial<CourseKindPickerProps>) {
  return {...DropdownField, edit: {...DropdownField.edit, type: 'dropdown', component: CourseKindPicker, kinds, ...props}, display: DropdownField.display, options: optionsForKinds(kinds), ...props}
}

export const CourseKindField = courseKindField(null);
export const AthleticsCourseKindField = courseKindField([CourseKind.AthleticReg, CourseKind.AthleticIndividualReg])
export const PurchaseableCourseKindField = courseKindField(purchaseableKinds, {multiple: true})
