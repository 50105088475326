import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteParticipantsQueryVariables = Types.Exact<{
  site: Types.Scalars['ID'];
  sorts?: Types.InputMaybe<Array<Types.Sort> | Types.Sort>;
  filters?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
}>;

export type SiteParticipantsQuery = {
  site?:
    | {
        familyParticipantsQuery?:
          | {
              count?: number | null | undefined;
              items?:
                | Array<
                    | {
                        id: string;
                        name?: string | null | undefined;
                        email?: string | null | undefined;
                        phone?: string | null | undefined;
                        position?: string | null | undefined;
                        relation?: string | null | undefined;
                        attendanceCodeCode?: string | null | undefined;
                        owner?:
                          | { name?: string | null | undefined }
                          | { name?: string | null | undefined }
                          | { name: string }
                          | { name?: string | null | undefined }
                          | { name: string }
                          | { name?: string | null | undefined }
                          | null
                          | undefined;
                      }
                    | {
                        id: string;
                        name?: string | null | undefined;
                        email?: string | null | undefined;
                        phone?: string | null | undefined;
                        position?: string | null | undefined;
                        relation?: string | null | undefined;
                        attendanceCodeCode?: string | null | undefined;
                        owner?:
                          | { name?: string | null | undefined }
                          | { name?: string | null | undefined }
                          | { name: string }
                          | { name?: string | null | undefined }
                          | { name: string }
                          | { name?: string | null | undefined }
                          | null
                          | undefined;
                      }
                    | {
                        id: string;
                        name?: string | null | undefined;
                        email?: string | null | undefined;
                        phone?: string | null | undefined;
                        position?: string | null | undefined;
                        relation?: string | null | undefined;
                        attendanceCodeCode?: string | null | undefined;
                        owner?:
                          | { name?: string | null | undefined }
                          | { name?: string | null | undefined }
                          | { name: string }
                          | { name?: string | null | undefined }
                          | { name: string }
                          | { name?: string | null | undefined }
                          | null
                          | undefined;
                      }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const SiteParticipantsDocument = gql`
  query SiteParticipants($site: ID!, $sorts: [Sort!], $filters: [Filter!]) {
    site(site: $site) {
      familyParticipantsQuery(pageSize: 1000, sorts: $sorts, filters: $filters) {
        count
        items {
          id
          name
          email
          phone
          position
          relation
          attendanceCodeCode
          owner {
            name
          }
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteParticipantsArgs = MakeOptional<UseQueryArgs<SiteParticipantsQueryVariables, SiteParticipantsQuery>, 'query'>;

export function useSiteParticipantsQuery(options: SiteParticipantsArgs = {}) {
  return useQuery<SiteParticipantsQueryVariables, SiteParticipantsQuery>({ query: SiteParticipantsDocument, ...options });
}

export type SiteParticipantsOptions = ExecQueryOptions<SiteParticipantsQueryVariables>;

export function siteParticipants(options: SiteParticipantsOptions) {
  return executeQuery<SiteParticipantsQuery, SiteParticipantsQueryVariables>(SiteParticipantsDocument, options);
}

export type SiteParticipantsSelections = SiteParticipantsQuery['site'];
