import { CourseStatus } from 'app2/api';
import { orderCols } from 'app2/components';

import { CoursesTableTab } from './CoursesTableTab';

export const PublishedCourseStatuses = [CourseStatus.Active, CourseStatus.AwaitingApproval, CourseStatus.Cancelled, CourseStatus.Completed, CourseStatus.Enrolling, CourseStatus.Request, CourseStatus.Upcoming];
export const PublishedCourseStatusesNoRequests = [CourseStatus.Active, CourseStatus.Cancelled, CourseStatus.Completed, CourseStatus.Enrolling, CourseStatus.Upcoming];

const hrRateConfigs = ['rates.season.homeroomFeeRoundingIncrementCents', 'rates.seasons.homeroomFeeRoundingIncrementCents', 'rates.dropIn.homeroomFeeRoundingIncrementCents', 'rates.recurring.homeroomFeeRoundingIncrementCents', 'rates.usage.homeroomFeeRoundingIncrementCents'];

const enrichment = {
  requests:{
    label:'Requests',
    name:'requests',
    hideEmpty:true,
    statuses: [CourseStatus.AwaitingApproval, CourseStatus.Request],
    defaultStatusFilter: [CourseStatus.AwaitingApproval, CourseStatus.Request],
    cols: {
      base: ['name', 'status', 'vendor', 'startDate', 'endDate', 'courseDays', 'enrolledCount', 'waitlistedCount', 'teacher', 'policies', 'registration']
    }
  },
  published:{
    label:'Published',
    name:'published' as CoursesTableTab['name'],
    statuses: PublishedCourseStatusesNoRequests,
    cols: {
      base: ['name', 'status', 'vendor', 'enrolledCount', 'waitlistedCount', 'courseDays', 'room', 'grades', 'minCapacity', 'maxCapacity', 'rates.season.rate', 'prices'],
      advanced:['ageMin', 'ageMax', 'hasParentOptionCourses', 'courseFramework', 'teacher', 'courseTags', 'description', 'supplies', 'startDate', 'endDate', 'noEnrichmentDays', 'enrollmentOpens.date', 'enrollmentOpens.time', 'enrollmentCloses.date', 'enrollmentCloses.time', 'classesCount', 'removedCount', 'searchable', 'rates.season.materialsRate', 'rates.season.depositAmount', 'rates.season.installmentDates', 'rates.seasons', 'rates.dropIn.rate', 'rates.recurring', 'rates.usage.rate', 'rates.usage.unit', 'rates.usage.roundingIncrement', 'rates.usage.gracePeriod', 'policies', 'registration', 'vendor.email', ...hrRateConfigs]
    }
  },
  draft: {
    label:'Drafts',
    name:'drafts' as CoursesTableTab['name'],
    statuses: [CourseStatus.Draft],
    save: 'Save drafts',
    cols:   {
      base: ['vendor', 'name', 'room', 'courseDays', 'startDate', 'endDate', 'noEnrichmentDays', 'grades', 'classesCount'],
      advanced: ['ageMin', 'ageMax', 'minCapacity', 'maxCapacity', 'rates.season.rate', 'rates.season.materialsRate', 'vendor.email', ...hrRateConfigs],
      orgProvider: ['vendor', 'courseFramework', 'name', 'teacher', 'courseTags', 'grades', 'description', 'room', 'courseDays', 'startDate', 'endDate', 'noEnrichmentDays', 'enrollmentOpens.date', 'enrollmentOpens.time', 'enrollmentCloses.date', 'enrollmentCloses.time', 'minCapacity', 'maxCapacity', 'rates.season.rate', 'classesCount'],
      orgProviderAdvanced: ['ageMin', 'ageMax', 'supplies', 'rates.season.materialsRate', 'rates.season.depositAmount', 'rates.season.installmentDates', 'rates.seasons', 'rates.dropIn.rate', 'rates.recurring', 'rates.usage.rate', 'rates.usage.unit', 'rates.usage.roundingIncrement', 'rates.usage.gracePeriod', 'vendor.email', ...hrRateConfigs]
    }
  }
}

const extendedCare = {
  published:{
    ...enrichment.published,
    statuses: PublishedCourseStatuses,
    cols: {
      base: ['name', 'status', 'enrolledCount', 'waitlistedCount', 'courseDays', 'room', 'grades', 'minCapacity', 'maxCapacity', 'rates.season.rate', 'rates.seasons', 'rates.dropIn.rate', 'rates.recurring', 'rates.usage.rate', 'rates.usage.unit', 'rates.usage.roundingIncrement', 'rates.usage.gracePeriod', 'prices'],
      advanced: ['vendor', 'ageMin', 'ageMax', 'hasParentOptionCourses', 'courseFramework', 'teacher', 'courseTags', 'description', 'supplies', 'startDate', 'endDate', 'noEnrichmentDays', 'enrollmentOpens.date', 'enrollmentOpens.time', 'enrollmentCloses.date', 'enrollmentCloses.time', 'classesCount', 'removedCount', 'searchable', 'rates.season.materialsRate', 'rates.season.depositAmount', 'rates.season.installmentDates', 'policies', 'registration', 'vendor.email', ...hrRateConfigs],
    }
  },
  draft: {
    ...enrichment.draft,
    cols: {
      base: enrichment.draft.cols.base,
      advanced: enrichment.draft.cols.advanced,
      orgProvider: ['vendor', 'courseFramework', 'name', 'teacher', 'courseTags', 'grades', 'description', 'room', 'courseDays', 'startDate', 'endDate', 'noEnrichmentDays', 'enrollmentOpens.date', 'enrollmentOpens.time', 'enrollmentCloses.date', 'enrollmentCloses.time', 'minCapacity', 'maxCapacity', 'rates.season.rate', 'rates.seasons', 'rates.dropIn.rate', 'rates.recurring', 'rates.usage.rate', 'rates.usage.unit', 'rates.usage.roundingIncrement', 'classesCount'],
      orgProviderAdvanced: ['ageMin', 'ageMax', 'supplies', 'rates.season.materialsRate', 'rates.season.depositAmount', 'rates.season.installmentDates', ...hrRateConfigs],
    }
  }
}

const camp = {
  published:{
    ...enrichment.published,
    statuses: PublishedCourseStatuses,
    cols: {
      base: ['startDate', 'endDate', 'name', 'grades', 'ageMin', 'ageMax', 'status', 'hasParentOptionCourses', 'vendor', 'enrolledCount', 'waitlistedCount', 'courseDays', 'room', 'minCapacity', 'maxCapacity', 'rates.season.rate', 'rates.seasons', 'prices'],
      advanced: ['vendor', 'ageMin', 'ageMax', 'hasParentOptionCourses', 'courseFramework', 'teacher', 'courseTags', 'description', 'supplies', 'noEnrichmentDays', 'enrollmentOpens.date', 'enrollmentOpens.time', 'enrollmentCloses.date', 'enrollmentCloses.time', 'classesCount', 'removedCount', 'searchable', 'rates.season.materialsRate', 'rates.season.depositAmount', 'rates.season.installmentDates', 'policies', 'registration', 'vendor.email', ...hrRateConfigs],
    }
  },
  draft: {
    ...enrichment.draft,
    cols: {
      base: orderCols(['startDate', 'endDate'], enrichment.draft.cols.base, false),
      advanced: enrichment.draft.cols.advanced,
      orgProvider: ['startDate', 'endDate', 'vendor', 'courseFramework', 'name', 'teacher', 'courseTags', 'grades', 'description', 'room', 'courseDays', 'noEnrichmentDays', 'enrollmentOpens.date', 'enrollmentOpens.time', 'enrollmentCloses.date', 'enrollmentCloses.time', 'minCapacity', 'maxCapacity', 'rates.season.rate', 'rates.season.depositAmount', 'rates.season.installmentDates', 'classesCount'],
      orgProviderAdvanced: ['ageMin', 'ageMax', 'supplies', 'rates.season.materialsRate', 'rates.seasons', 'rates.dropIn.rate', 'rates.recurring', 'rates.usage.rate', 'rates.usage.unit', 'rates.usage.roundingIncrement', 'rates.usage.gracePeriod', ...hrRateConfigs],
    }
  }
}

const lessons = {
  requests: {
    ...enrichment.requests
  },
  published:{
    ...enrichment.published,
  },
  draft: {
    ...enrichment.draft,
  }
}

const registrationCols = ['name', 'status', 'vendor', 'kind', 'rates.season.rate', 'prices', 'description', 'grades', 'minCapacity', 'maxCapacity', 'teamMaxCapacity', 'enrollmentOpens.date', 'enrollmentOpens.time', 'enrollmentCloses.date', 'enrollmentCloses.time', 'enrolledCount', 'searchable'];
const registration = {
  published:{
    ...enrichment.published, 
    useAdvanced: false,
    statuses: PublishedCourseStatuses,
    cols: { base: registrationCols }
  },
  draft: { 
    ...enrichment.draft, 
    useAdvanced: false,
    cols: { base: removeAthleticsPublishedCols(registrationCols) }
  }
}

const teamCols = ['name', 'status', 'enrolledCount', 'vendor', 'teacher', 'teacher.email', 'teacher.phone', 'grades', 'courseTags', 'minCapacity', 'maxCapacity'];
const teams = {
  published:{
    ...enrichment.published, 
    useAdvanced: false,
    statuses: PublishedCourseStatuses,
    cols: { base: teamCols }
  },
  draft: { 
    ...enrichment.draft, 
    useAdvanced: false,
    cols: { base: removeAthleticsPublishedCols(teamCols) }
  }
}

const gamesCols = ['homeTeam', 'awayTeam', 'name', 'status', 'startDate', 'endDate', 'startTime', 'endTime', 'room', 'homeScore', 'awayScore'];
const games = {
  published:{
    ...enrichment.published, 
    useAdvanced: false,
    statuses: PublishedCourseStatuses,
    cols: { base: gamesCols }
  },
  draft: { 
    ...enrichment.draft, 
    useAdvanced: false,
    cols: { base: removeAthleticsPublishedCols(gamesCols) }
  }
}

function removeAthleticsPublishedCols(cols:string[]) {
  return cols.filter(c => c != 'enrolledCount' && c != 'status' && c != 'prices');
}

export const courseColumnSets = {
  enrichment,
  'extended-care':extendedCare,
  camp,
  lessons,
  'registration-fees': registration,
  teams,
  games
} 