import * as React from 'react'

import { CartUtils, compact, PaymentService } from 'app2/api';
import { ButtonStrip, FieldInfo, FormContent, FormModel, Img, Modal, Section, TextProps, useFormInfo, useLifecycle, VBox } from 'app2/components'
import { StripeLoader } from 'app/scriptLoading';

import { showCardModal, showAchModal, CardForm, AchForm } from '../../shared';
import { parentUpdateCartPaymentService } from '../../shared/generated';

import { CheckoutModel } from '../CheckoutModel';
import AffirmImage from './affirm.svg'
import AlipayImage from './alipay.svg'
import { AffirmForm } from './AffirmForm';
import { OnSessionPayment } from './OnSessionPayment';


export function PaymentMethod() {
  useLifecycle({onMount});

  function onMount() {
    StripeLoader.initiateStripeLoading();
  }

  const form = useFormInfo<CheckoutModel>().form;
  const {allowAffirm, allowAch, allowAlipay, enableAlipay, affirmTooltip, aliPayToolTip, allWaitlist} = getPaymentMethodOptions(form);

  function render() {
    return <FormContent>
      {renderPaymentOptions(true)}
      {renderPaymentOptions(false)}
    </FormContent>
  }

  function renderPaymentOptions(primary:boolean) {
    //@ts-ignore
    if (!primary && form.values?.type != 'tuition') {
      return;
    }

    const propName = primary ? 'type' : 'secondaryType';
    const label = primary ? 'Payment method' : 'Secondary payment method';
    const labelStyle:TextProps = primary ? undefined : {text: 'formlabel'};
    const commonButton = {maxHeight:'38px'};

    return <VBox gap='$12'>
      <Section<CheckoutModel> labelProps={labelStyle} name={propName} label={label} required={form.values.cart.paymentMethodRequired} validators={validatePaymentMethod} component={ButtonStrip} separate small={false} flexWrap='wrap' onChange={onChangeMethod} options={[{
        ...commonButton,
        label: 'Card', 
        value: PaymentService.Card,
        icon:'CreditCard',
        iconPosition: 'left',
        maxWidth:'50%'
      }, allowAch && {
        ...commonButton,
        label: 'Bank account', 
        value: PaymentService.Ach,
      }, compact({
        ...commonButton,
        label: <Img src={AffirmImage} width='100%' minWidth='110px' maxWidth={['110px', '200px', '200px']} opacity={!allowAffirm ? .5 : 1} />, 
        value: PaymentService.Affirm,
        onClick: !allowAffirm || !primary ? () => Modal.error('Affirm', affirmTooltip) : undefined,
        tooltip: affirmTooltip,
        opacity: primary ? 1 : 0,
        px: '8px'
      }), allowAlipay && compact({
        ...commonButton,
        label: <Img src={AlipayImage} maxHeight='20px' width='100%' minWidth='110px' maxWidth={['110px', '200px', '200px']} opacity={!enableAlipay ? .5 : 1} />, 
        value: PaymentService.Alipay,
        onClick: !enableAlipay || !primary ? () => Modal.error('Affirm', affirmTooltip) : undefined,
        tooltip: aliPayToolTip,
        opacity: primary ? 1 : 0,
      })]} />
      {renderSelectedPaymentType(propName)}
    </VBox>
  }

  async function onChangeMethod(type:string) {
    if (!type) {
      return;
    }

    const existingPaymentMethod = ((type == PaymentService.Card) && (form.values.existingCard)) || ((type == PaymentService.Ach) && (form.values.existingUsBankAccount) || (type == PaymentService.Affirm) || (type == PaymentService.Alipay))

    if (existingPaymentMethod) {
      parentUpdateCartPaymentService({ variables: { paymentService: type }});

      return;
    }

    let success;

    if (type == PaymentService.Card) {
      success = await showCardModal();
    }
    else
    if (type == PaymentService.Ach) {
      success = await showAchModal();
    }
    else {
      return;
    }

    if (!success) {
      form.setValue('type', null);
    }

    return;
  }

  function renderSelectedPaymentType(propName:keyof CheckoutModel) {
    if (form.values[propName] == PaymentService.Card) {
      return form.values.existingCard ? <CardForm required={form.values.cart?.paymentMethodRequired} /> : <></>
    }

    if (form.values[propName] == PaymentService.Ach && allowAch) {
      return form.values.existingUsBankAccount ? <AchForm /> : <></>
    }

    if (form.values[propName] == PaymentService.Affirm && allowAffirm) {
      return <AffirmForm waitlist={allWaitlist} />
    }

    if (form.values[propName] == PaymentService.Alipay && allowAlipay) {
      return <OnSessionPayment waitlist={allWaitlist} />
    }

    return '';
  }


  return render();
}

export function validatePaymentMethod(value: PaymentService, info: FieldInfo<CheckoutModel, 'type'>) {
  const form = info.form;
  return getPaymentMethodOptions(form).validation;
}

function getPaymentMethodOptions(form:FormModel<CheckoutModel>) {
  const {cart, type} = form.values;
  const hasInstallments = CartUtils.hasInstallments(cart);
  const allWaitlist = CartUtils.allWaitlist(cart);
  const hasOngoing = CartUtils.hasOngoing(cart);

  const allowAch = cart?.sites?.every?.(s => s.usingAch);

  const over50 = cart?.total >= 50;
  const allowAffirm = !hasInstallments && (allWaitlist || (over50 &&  !hasOngoing));
  const affirmTooltip = !allowAffirm 
    ? (!over50 && !hasOngoing ? 'Order total must be at least $50 to pay with Affirm.' : 'Subscription, usage and installment enrollments can not be paid with Affirm.') 
    : undefined;

    const allowFree = cart.paymentMethodRequired ? 'Please select a payment method' : undefined;

    const allowAlipay = cart?.sites?.every?.(s => s.usingAlipay);
    const enableAlipay = allowAlipay && !hasInstallments && (allWaitlist || !hasOngoing);
    const aliPayToolTip = !enableAlipay 
    ? 'Subscription, usage and installment enrollments can not be paid with Alipay.' 
    : undefined;

  const validation = {
    [PaymentService.Free]: allowFree,
    [undefined as PaymentService]: allowFree,
    [null as PaymentService]: allowFree,
    [PaymentService.Card]: undefined,
    [PaymentService.Affirm]: allowAffirm ? undefined : affirmTooltip,
    [PaymentService.Ach]: allowAch ? undefined : 'Please select a payment method',
    [PaymentService.Affirm]: allowAffirm ? undefined : affirmTooltip,
    [PaymentService.Alipay]: allowAlipay ? undefined : aliPayToolTip,
  }[type];

  return {allowAffirm, allowAch, allWaitlist, affirmTooltip, allowAlipay, enableAlipay, aliPayToolTip, validation}
}
