import * as React from 'react'
import { useStripe } from '@stripe/react-stripe-js';

import { Info, Modal, Text } from 'app2/components';
import { showStripeModal } from 'app2/views/shared';

import { ParentUserWithCartSelections } from '../generated';

export async function alipayCheckout(cart:ParentUserWithCartSelections['cart']) {
  return showStripeModal(<AlipayModal cart={cart} />)
}

interface Props {
  cart:ParentUserWithCartSelections['cart'];
}

export function AlipayModal(props:Props) {
  const stripe = useStripe();

  const [error, setError] = React.useState('')

  function render() {
    return <Modal title='Checkout with Alipay' ok='Continue' onOk={onOk}>
      <Text>You will be redirected Alipay's checkout</Text>
      {error && <Info type='error'>{error}</Info>}
    </Modal>
  }

  async function onOk() {
    const result = await stripe.confirmAlipayPayment(props.cart.alipayToken.token, {
      return_url: `${window.location.href}`,
    });

    const error = result?.error;

    if (error) {
      setError(error.message);
      return false;
    }

    return result?.paymentIntent?.status == 'succeeded' ? result.paymentIntent.client_secret : false;
  }

  return render();
}
