import { clone } from "lodash-es";

// EditableFormCollection does the following:
//  - proxies the data collection
//  - provides a FormModel interface ontop of the data collection so that
//    a) standard form fields can be used as cell display and cell editors
//    b) cells can be configered to have validations
//    c) its possible to share code with a form that mimics the table (such as a master/detail ui)
//  - tracks unsaved changes

import { UndoManager } from "../../undo";
import { FieldInfo, FormModel, FieldModel, FormValidateOptions, createForm, UpdateOptions, UpdateType, FormResetOptions, isFormEmptyOrDefault } from "../../form";
import { ErrorWithPath, ErrorPathSegmentType, errorHasPath, popErrorPath, errorToString } from "../../error";

import { ObservableCollectionCommand, ObservableCollection, ObservableCollectionObserver, CollectionEventType, ObservableCollectionArray, hasNewId, assignId } from "../collection";
import { DataTable } from "../DataTable";
import { DataTableColumn } from "../column/DataTableColumn";

import { FormCollection, ChangeMap } from './FormCollection';

export class EditableFormCollection<T> extends ObservableCollectionArray<FormModel<T>> implements FormCollection<T> {
  table:DataTable;
  cols:DataTableColumn<T>[];
  undoManager:UndoManager;

  constructor(table:DataTable, cols:DataTableColumn<T>[], source?:ObservableCollection<any>, undoManager?:UndoManager) {
    super([], 'id');
    this.table = table;
    this.cols = cols;
    this.undoManager = undoManager;
    this.data = [];

    for (let pos = 0; pos < source.length; ++pos) {
      this.data.push(this.createForm(source.getItem(pos)));
    }
  }

  private createForm(values:T) {
    const fields = this.cols.map(field => clone(field) as FieldModel<any, any>);
    const form = createForm<T>({values, validate:false, fields, onReset: this.table.props.onRowReset});
    form.subscribe(this.onFormChange);

    return form;
  }

  updateFields(cols:DataTableColumn<T>[]) {
    this.cols = cols;

    for (let form of this.data) {
      const fields = this.cols.map(field => clone(field) as FieldModel<any, any>);
      form.resetFields(fields)
      form.onReset = this.table.props.onRowReset;
    }
  }

  release() {
    for (let form of this.data) {
      form.unsubscribe(this.onFormChange);
    }
  }

  getForm(row:number):FormModel<T> {
    return this.data[row];
  }

  addField?<P extends keyof T>(parents:(string | number)[], field:FieldModel<T, P>, validate?:boolean):FieldModel<T, P> {
    const remainingParents = Array.isArray(parents) ? parents.slice(1) : [];
    return this.data[Number(parents[0])]?.getField(remainingParents, field.name);
  }

  updateField?<P extends keyof T>(parents:(string | number)[], name:P, field:Partial<FieldModel<T, P>>):FieldModel<T, P> {
    const remainingParents = Array.isArray(parents) ? parents.slice(1) : [];
    return this.data[Number(parents[0])]?.getField(remainingParents, name);
  }

  getField?<P extends keyof T>(parents:(string | number)[], name:P):FieldModel<T, P>;
  getField?<P extends keyof T>(row:number, name:P):FieldModel<T, P>;
  getField?<P extends keyof T>(parentsOrPosition:(string | number)[] | number, name:P):FieldModel<T, P> {
    // for columns that are using a dotted path, the field we want to get is the 
    // the column field, so that means using just the first part of the dotted path
    const rootName = name.toString().split('.')[0];
    const row = Array.isArray(parentsOrPosition) ? parentsOrPosition[0] as number : parentsOrPosition;
    const remainingParents = Array.isArray(parentsOrPosition) ? parentsOrPosition.slice(1) : [];
    const field = Object.assign({}, this.data[row]?.getField(remainingParents, rootName as P), {name});

    return field;
  }

  getFields(row:number) {
    return this.data[row].fields;
  }

  //@ts-ignore
  getItem(row:number):T {
    return this.data[row]?.values as T;
  }

  getDirty(row:number):boolean {
    return this.data[row]?.dirty;
  }

  getValue(position:number, property:string):any;
  getValue<P extends keyof T>(parents:(string | number)[], fieldName: P, defaultValue?: T[P]): T[P];
  getValue(parentsOrPosition:(string | number)[] | number, fieldName: string, defaultValue?: any): any {
    const row = Array.isArray(parentsOrPosition) ? parentsOrPosition[0] as number : parentsOrPosition;
    const remainingParents = Array.isArray(parentsOrPosition) ? parentsOrPosition.slice(1) : [];

    return this.data[row]?.getValue(remainingParents, fieldName as any, defaultValue)
  }

  getInfo(parents:(string | number)[], fieldName: any, defaultValue?: any):FieldInfo<any> {
    const row = parents[0] as number;
    const remainingParents = Array.isArray(parents) ? parents.slice(1) : [];

    return this.data[row]?.getInfo(remainingParents, fieldName, defaultValue)
  }

  setValue(fieldName: any, fieldValue: any, options?:UpdateOptions):void;
  setValue(parents:(string | number)[], fieldName: any, fieldValue: any, options?:UpdateOptions):void;
  setValue(position:number, property:string, value:any, options?:UpdateOptions):void;
  setValue(parentsOrPosition:(string | number)[] | number | any, property:string | any, value?:any, options?:UpdateOptions):void {
    const row = Array.isArray(parentsOrPosition) ? parentsOrPosition[0] as number : parentsOrPosition;
    const remainingParents = Array.isArray(parentsOrPosition) ? parentsOrPosition.slice(1) : [];
    this.data[row]?.setValue(remainingParents, property as any, value, options);
  }

  setValues(parents:(string | number)[], values:Partial<T>, options?:UpdateOptions):void;
  setValues(position:number, values:Partial<T>, options?:UpdateOptions):void;
  //@ts-ignore
  setValues?(values:Partial<T>, options?:UpdateOptions):void;//not used
  setValues(parentsOrPosition:(string | number)[] | number, values:Partial<T>, options?:UpdateOptions):void {
    const row = Array.isArray(parentsOrPosition) ? parentsOrPosition[0] as number : parentsOrPosition;
    const remainingParents = Array.isArray(parentsOrPosition) ? parentsOrPosition.slice(1) : [];
    this.data[row]?.setValues(remainingParents, values, options);
  }

  reset(options?:FormResetOptions<T>):void;
  reset(row:number, options:FormResetOptions<T>):void;
  reset(row:number | FormResetOptions<T>, options?:FormResetOptions<T>):void {
    if (!options) {
      throw new Error('Missing options');
    }

    this.data[row as number].reset(options);
  }

  touch(parents:(string | number)[], fieldName: any):void {
    const row = parents[0] as number;
    const remainingParents = Array.isArray(parents) ? parents.slice(1) : [];

    this.data[row].touch(remainingParents, fieldName);
  }

  //@ts-ignore
  insert(position:number, item:T):void {
    assignId(item, this.idProperty);

    const form = this.createForm(item);
    form.dirty = true;

    this.data.splice(position, 0, form);
    this.notifyObservers({type:CollectionEventType.create, position: position, item:item as any});
  }

  //@ts-ignore
  remove(position:number):T {
    const removed = this.data.splice(position, 1)[0];
    removed.unsubscribe(this.onFormChange);

    this.notifyObservers({type:CollectionEventType.delete, position, item: removed.values as any});

    return removed.values as T;
  }

  getRecordErrors(row:number) {
    return this.data[row].errors;
  }

  handleErrors(errors:ErrorWithPath[], clearPrevious?:boolean):ErrorWithPath[] {
    if (clearPrevious || clearPrevious === undefined) {
      this.clearErrors();
    }

    const unhandled = [];

    for (let error of errors) {
      if (!errorHasPath(error) || !this.applyErrorToRow(error)) {
        unhandled.push(error);
      }
    }

    return unhandled;
  }

  applyErrorToRow(error:ErrorWithPath) {
    const row = this.rowFromErrorPath(error);

    if (row == -1 || row >= this.data.length) {
      return false;
    }

    const errorWithoutRowPos = popErrorPath(error);
    const rowUnhandled = this.data[row as number].handleErrors([errorWithoutRowPos], false);

    if (rowUnhandled.length) {
      if (!this.data[row].errors) {
        this.data[row].errors = [];
      }

      // remove the row id from the path since we found the row
      this.data[row].errors.push(errorToString({...error, path:error.path.slice(1)}));
      this.onFormError(row);
    }

    return true;
  }

  rowFromErrorPath(error:ErrorWithPath) {
    const part = error.path[0];
    const row = part.type == ErrorPathSegmentType.property || part.type == ErrorPathSegmentType.index
      ? Number(part.value) 
      : this.getIndex(part.value);

    return isNaN(row) ? -1 : row;
  }

  clearErrors():void {
    this.data.forEach(form => form.clearErrors());
    this.table.onDataUpdate({collection: this, type: CollectionEventType.reset});
  }

  onFormError(row:number) {
    const form = this.data[row];
    this.table.onDataUpdate({collection: this, type: CollectionEventType.reset, position: row, item:form, id: (form.values as any).id});
  }

  onFormChange = (form:FormModel, type:UpdateType) => {
    if (!type.anyValue) {
      return;
    }

    const row = this.data.indexOf(form);
    let fields = Object.keys(type.fields || {});

    if (!fields.length) {
      fields = [undefined];
    }

    for (const field of fields) {
      this.table.onDataUpdate({collection: this, type: CollectionEventType.update, position: row, item:form, id: form.values.id, property: field});
    }
  }

  cancelChanges(restoreData:boolean = false) {
    EditableFormCollection.cancelChanges(this.table.data, this.undoManager, restoreData);

    this.table.makeSelectionValid();
  }

  isNewRow(pos:number | T) {
    const item = typeof pos == 'number' ? this.getItem(pos) : pos;
    return hasNewId(item, this.idProperty);
  }
  
  async presubmit(options:FormValidateOptions = {skipValidatingEmpty: true}):Promise<boolean> {
    const promises = this.data.map(form => form.dirty ? form.presubmit(options) : true);
    const results = await Promise.all(promises);

    return promises.length == 0 || results.filter(result => !result).length == 0;
  }

  getItems(onlyChanged?:boolean, emptyRows:boolean = false) {
    const items:T[] = [];

    const defaultValues = this.table.defaultRecord;

    for (const row of this.data) {
      if ((!onlyChanged || row.dirty) && (emptyRows || !isFormEmptyOrDefault(row.values, defaultValues))) {
        items.push(row.values as T);
      }
    }

    return items;
  }

  get dirty() {
    return this.data.find(r => r.dirty) != null;
  }

  markClean() {
    for (const row of this.data) {
      row.dirty = false;
    }
  }

  getChanges(removeEmptyNewRows:boolean = true) {
    const changeMap:ChangeMap<T> = {};

    const defaultValues = this.table.defaultRecord;

    for (let pos = 0; pos < this.data.length; ++pos) {
      const row = this.data[pos];
      if (row.dirty && (!removeEmptyNewRows || !isFormEmptyOrDefault(row.values, defaultValues))) {
        const item = row.values as T;
        const id = (item as any)[this.idProperty];
        
        changeMap[id] = {
          type: hasNewId(item, this.idProperty) ? CollectionEventType.create : CollectionEventType.update,
          item,
          updatedAt: row.updatedAt,
          pos,
          errors: row.errors
        }
      }
    }
    
    return changeMap;
  }

  // currently getChanges doesn't include removed items
  getRemovedItems(removedFlag:string = undefined) {
    const data = this.table.data;
    const undoManager = this.undoManager || UndoManager.instance;
    const commands = undoManager.undoableCommands;
    const removed = [];

    for (let pos = 0; pos < commands.length; ++pos) {
      const command = commands[pos] as unknown as ObservableCollectionCommand<T>;
      const items = command.isCollectionCommand 
        ? command.collectionEvents.map(event => event.collection == data && event.type == CollectionEventType.delete && !this.isNewRow(event.item) ? event.item : null).filter(i => !!i)
        : [];
      
      if (removedFlag) {
        items.forEach(i => (i as any)[removedFlag] = true);
      }

      removed.push(...items);
    }

    return removed;
  }

  static cancelChanges<T>(data:ObservableCollection<T>, undoManager?:UndoManager, restoreData:boolean = false) {
    undoManager = undoManager || UndoManager.instance;

    while (undoManager.canUndo) {
      const command = undoManager.nextUndoCommand as unknown as ObservableCollectionCommand<T>;

      if (restoreData && command.isCollectionCommand && command.collectionEvents.filter(event => event.collection == data).length > 0) {
        undoManager.undo(true, false);
      }
      else {
        undoManager.skipUndo();
      }
    }

    undoManager.truncateRedo();    
  }

  //@ts-ignore
  observe(observer:ObservableCollectionObserver<T>):void {
    //@ts-ignore
    super.observe(observer);
  }

  //@ts-ignore
  unobserve(observer:ObservableCollectionObserver<T>):void {
    //@ts-ignore
    super.unobserve(observer);
  }
}
