import * as React from 'react';

import { Beta } from 'app2/views';
import { CheckboxField, FormModel, Panel, Section, useForm } from 'app2/components';

import { useCurrentSite } from '../shared';

import { QrCode } from './QrCode';
import { organizerUpdateParentAttendance } from './gql';

export function Attendance() {
  const { site } = useCurrentSite();
  const form = useForm<FormValues>(site, [site]);

  function render() {
    return (
      <Panel icon="UserCheck" title="Attendance" type="toggle" form={form} onOk={onOk}>
        <Section label="Enable attendance codes" name="parentAttendanceConfig.enabled" component={CheckboxField} />
        {renderQrCode()}
        <Section label="Notify families when their students are checked in, checked out, or marked absent." name="parentAttendanceConfig.notify" component={CheckboxField} />
        <Beta>
          <Section label="Allow families to check-in early" name="parentAttendanceConfig.earlyCheckIn" component={CheckboxField} />
        </Beta>
      </Panel>
    );
  }

  function renderQrCode() {
    if (!form.values?.parentAttendanceConfig?.enabled) {
      return null;
    }

    return <QrCode qrCode={site?.attendanceQrCode} link={{to: site?.attendanceUrl, children:'Family attendance page'}} />
  }

  async function onOk(form: Form) {
    const variables = { siteId: site.id, attendanceConfig: form.values.parentAttendanceConfig };
    const [success] = await organizerUpdateParentAttendance({ variables, successMsg: 'Attendance settings updated', error: { handler: form } });
    return success;
  }

  return render();
}

interface FormValues {
  parentAttendanceConfig: {
    enabled: boolean;
    notify: boolean;
  };
}

type Form = FormModel<FormValues>;
