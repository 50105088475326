import * as React from 'react'

import { PaymentService } from 'app2/api';
import { HBox, Icon, Text, useFormInfo, VBox } from 'app2/components';

import { CheckoutModel } from '../CheckoutModel';

export interface OnSessionPaymentProps {
  waitlist?:boolean;
}

export function OnSessionPayment(props:OnSessionPaymentProps) {
  const form = useFormInfo<CheckoutModel>().form;
  const name = Object.keys(PaymentService).find(key => PaymentService[key as keyof typeof PaymentService] === form.values.type);

  return <VBox>
    <HBox vAlign='center' gap='$8'>
      <Icon name='CheckCircle' size='medium' />
      <Text text='body'>You're all set! Click {!props.waitlist ? "confirm and pay when you're ready to complete your purchase." : "join waitlist when you're ready to complete joining the waitlist."} </Text>
    </HBox>
    <HBox vAlign='center' gap='$8'>
      <Icon name='CheckCircle' size='medium' opacity={0} />
      <Text text='body' disabled>Note: Donations cannot be added when paying with {name}.</Text>
    </HBox>
  </VBox>
}
