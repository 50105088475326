import * as React from 'react';

import { Beta } from 'app2/views';
import { Panel, Section, FormModel, CheckboxField } from 'app2/components';

import { useFamilyQuery } from '../shared';

import { familySetInvoice, useAutoRenewalQuery } from './generated';

export function AutomaticCharges() {
  const { family, id: familyId } = useFamilyQuery(useAutoRenewalQuery);

  function render() {
    return (
      <Beta position='top'>
        <Panel
          icon="RefreshCw"
          title="Automatic charges"
          subtitle="We will automatically charge your payment method for ongoing activities until the activity ends or you opt out."
          initialValues={family}
          onOk={onOk}
          type="edit-no-save-button"
          autoSave
          onNavigation="nothing"
        >
          <Section label="Opt out" name="invoice" infoTip="Make payments manually. You will receive an email when payments are due." component={CheckboxField} />
        </Panel>
      </Beta>
    );
  }

  async function onOk(form: Form) {
    const { invoice } = form.values;

    const [success] = await familySetInvoice({ variables: { id: familyId, invoice } });
    return success;
  }

  return render();
}

interface FormValues {
  invoice: boolean;
}

type Form = FormModel<FormValues>;
