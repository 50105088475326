import * as React from 'react'

import { useScrollWatcher } from '../dom-utils';
import { Icon } from '../icons';
import { BoxProps, VBox } from '../Box';
import { Link } from '../Link';
import { MenuDropdown } from '../menu';
import { convertElements, useLifecycle, useRef } from '../utils';

import { NavigationSection } from './NavigationSection';
import { NavigationLink } from './NavigationLink';

export interface NavigationProps extends BoxProps {
  footer?:React.ReactNode;
  popup?:boolean;
  onClickClose?:() => void;
}

let curScrollTop = 0

export function Navigation(props:React.PropsWithChildren<NavigationProps>) {
  let {footer, popup, onClickClose, children, ...remaining} = props;

  // the scroll pos hack is for trying to maintain the scroll position
  // between navigations because this compnent is getting unmounted and remounted
  // do to it not being externalized from the page component. 

  const scroller = useRef<HTMLDivElement>(null);
  const mountTime = React.useRef(0);
  useScrollWatcher(scroller.current, {onScroll});
  useLifecycle({onMount});

  function render() {
    const additionalStyles = props.popup ? {position:'absolute' as any, top:'0', zIndex:1} : undefined;
    const width = ['100%', '75%', '230px'];
    const spacing = ['$30', '$60', '$30'];

    children = convertElements(children, NavigationLink, NavigationLink, {text: 'subtitle1', fontWeight: 'bold'});
    children = convertElements(children, Link, NavigationLink, {text: 'subtitle1', fontWeight: 'bold'});
    children = convertElements(children, MenuDropdown, NavigationSection);

    return <VBox minHeight='100%' maxHeight='100%' width={width} minWidth={width} maxWidth={width} position='relative' {...additionalStyles} {...remaining}>
      <VBox ref={scroller} padding={spacing} bg='navigation' vItemSpace={['$30', '$30', '$20']} flex={1} overflowY='auto' overflowX='hidden'>
        {onClickClose && <Icon name='X' button color='white' position='absolute' zIndex={1} right={spacing} top={spacing} onClick={onClickClose} />}
        {children}
      </VBox>
      {props.footer}
    </VBox>
  }

  function onMount() {
    mountTime.current = Date.now();
    scroller.current.scrollTop = curScrollTop;
  }

  function onScroll() {
    if (Date.now() - mountTime.current < 1000 && !scroller.current.scrollTop) {
      scroller.current.scrollTop = curScrollTop;
      return;
    }

    curScrollTop = scroller.current.scrollTop;
  }

  return render();
}
