import * as React from 'react';

import { DataTableColumn, showCols } from 'app2/components';

import { EntityType, getFields } from './fields';

export function useCols<T = any>(type:EntityType, cols:string[], props?:any):DataTableColumn<T>[] {
  return React.useMemo(() => {
    const allCols = getFields(type, props);
    return showCols(allCols, cols, true);
  }, [props]);
}
