import * as React from 'react'
import { useForceUpdate, useLifecycle, useResizeObserver, useTransitionListener } from './utils';
import { useThemeValue } from './theme';
import { scrollTo, getScrollParent, smoothScrollingEnabled } from './dom-utils';

import { VBox, BoxProps, HBox } from './Box';
import { Button } from './Button';
import { TextType } from './Text';

interface Props extends Omit<BoxProps, 'top' | 'onChange'> {
  label?:React.ReactNode;
  hideLabel?:string;
  labelType?:TextType;
  initialOpen?:boolean;
  open?:boolean;
  controls?:boolean;
  onChange?:(open:boolean) => void;
  type:'form' | 'box'; //type form wraps the children in FormContent

  // this refers to where the expand/collapse mechanism sits
  // by default its at the bottom
  kind:'top' | 'bottom';
  // when using the top style, this allows you to place content
  // to the left of the expand/collapse.  if this is specified
  // it automatically implies kind=top. 
  top?:React.ReactNode;
}

export function Collapsible(props:Props) {
  let {label, hideLabel, labelType, initialOpen, open, controls, onChange, type, kind, top, children, minHeight, maxHeight, maxWidth, minWidth, width, m, mt, mb, ml, mr, mx, my, disabled, ...remaining} = props;
  const [openState, setOpen] = React.useState<boolean>(initialOpen);

  open = open === undefined ? openState : open;
  kind = top !== undefined ? 'top' : kind;

  let showTopControls = kind == 'top' && props.controls;
  let showBottomControls = kind == 'bottom' && props.controls;

  const spacing = type == 'form' ? 'form-content' : undefined;
  const visible = React.useRef<HTMLElement>();
  const content = React.useRef<HTMLElement>();
  const showMore = React.useRef<HTMLElement>();
  
  const forceUpdate = useForceUpdate();
  useResizeObserver(content, onResize);
  useLifecycle({onMount, onUpdate});

  const animating = useTransitionListener(visible.current, {onTransitionStart, onTransitionEnd});
  const breakpointMinHeight = parseFloat(useThemeValue('space', minHeight) || '0px');
  const [actualMinHeight, setActualMinHeight] = React.useState(breakpointMinHeight);
  const minHeightToUse = Math.min(actualMinHeight, breakpointMinHeight);

  checkIfNoScroll();

  function onMount() {
    // need to update to ensure we can measure things with the refs
    forceUpdate();
  }

  function onUpdate() {
    const before = content.current.style.minHeight;
    content.current.style.minHeight = ''

    const minHeight = content.current.getBoundingClientRect().height;
    content.current.style.minHeight = before;

    if (minHeight != actualMinHeight) {
      setActualMinHeight(minHeight);
    }
  }

  function onResize() {
    forceUpdate();
  }

  function onTransitionStart() {
    forceUpdate();
  }

  function onTransitionEnd() {
    forceUpdate();
  }

  function checkIfNoScroll() {
    if (!open && !animating.current && content && content.current?.clientHeight <= visible.current?.clientHeight) {
      showTopControls = false;
      showBottomControls = false;
    }
  }

  function render() {
    if (showTopControls) {
      children = <VBox mt='$8'>{children}</VBox>
    }

    return <VBox maxWidth={maxWidth} minWidth={minWidth} width={width}>
      {open ? renderOpen() : renderClosed()}
    </VBox>
  }

  function renderOpen() {
    return <VBox>
      {showTopControls && <HBox>{top}<Button ref={showMore} kind='tertiary' icon='DropdownClose' text={labelType} onClick={onClose} disabled={disabled}>{hideLabel}</Button></HBox>}
      <VBox ref={visible} mb={spacing} transition={transitionAnimation()} overflow='hidden' minHeight={minHeightToUse} maxHeight={content.current?.clientHeight || maxHeight} {...{m, mt, mb, ml, mr, mx, my}}><VBox ref={content} vItemSpace={spacing} {...remaining}>{children}</VBox></VBox>
      {showBottomControls && <div><Button ref={showMore} kind='tertiary' icon='DropdownClose' text={labelType} onClick={onClose} mt='$8' disabled={disabled}>{hideLabel}</Button></div>}
    </VBox>
  }

  function renderClosed() {
    return <VBox>
      {showTopControls && <HBox>{top}<Button ref={showMore} kind='tertiary' icon='DropdownOpen' text={labelType} onClick={onOpen} disabled={disabled}>{label}</Button></HBox>}
      <VBox ref={visible} transition={transitionAnimation()} overflow='hidden' minHeight={minHeightToUse} maxHeight={content.current ? minHeightToUse || '0px' : undefined} {...{m, mt, mb, ml, mr, mx, my}}><VBox ref={content} vItemSpace={spacing} {...remaining}>{children}</VBox></VBox>
      {showBottomControls && <div><Button ref={showMore} kind='tertiary' icon='DropdownOpen' text={labelType} onClick={onOpen} disabled={disabled}>{label}</Button></div>}
    </VBox>
  }

  function transitionAnimation() {
    return smoothScrollingEnabled() ? 'max-height 1s, margin-bottom .5s' : undefined
  }

  function onOpen() {
    animating.current = true;
    onChange?.(true);
    setOpen(true);
  }

  function onClose() {
    const scroller = getScrollParent(visible.current, true);
    const showMoreRect = showMore.current.getBoundingClientRect();

    scrollTo(scroller, {top: scroller.scrollTop - content.current.getBoundingClientRect().height + minHeightToUse + showMoreRect.height, behavior: 'smooth'});
    animating.current = true;
    onChange?.(false);
    setOpen(false);
  }
  
  return render();
}

Collapsible.defaultProps = {
  label:'Show more',
  hideLabel: 'Show less',
  layout: 'vbox',
  type: 'form',
  kind: 'bottom',
  controls: true,
  maxHeight: '2500px' // this matters for the animation, it must be at least as great as the expectd max content
}
