import * as React from 'react'

import { Student, StudentUtils, caselessEqual } from 'app2/api';
import { Link, Text } from 'app2/components';
import { SearchDropdown, SearchDropdownProps } from 'app2/views/shared';
import { findQueryOption } from 'app2/views/shared-public';

import { StudentModal } from '../modal';

import { students, useStudentsQuery, StudentsSelections } from './generated';

export interface StudentDropdownProps extends SearchDropdownProps<StudentsSelections> {
  site?:string;
  company?:string;
  entityKind?:string;
  entityId?:string;
  // excludes students that are enrolled in the specified course
  excludedCourses?:string[];
  showPaymentMethod?:boolean;
}

export function StudentDropdown(props:StudentDropdownProps) {
  const {site, company, entityKind, entityId, excludedCourses, showPaymentMethod, ...remaining} = props;

  function render() {
    const removeFields = !showPaymentMethod ? ['family.hasPaymentMethod'] : [];
    return <SearchDropdown query={useStudentsQuery} queryOptions={{removeFields}} queryVars={{...entityPropsToSiteOrCompany(props), excludedCourses: props.excludedCourses}} renderOption={renderOption} {...remaining} />
  }

  function renderOption(item:StudentsSelections, editing:boolean) {
    const text = StudentUtils.getStudentName(item);
    let label:React.ReactNode = editing || !item ? text : <Link onClick={<StudentModal id={item.id} />}>{text}</Link>;
    
    if (showPaymentMethod && item?.family?.hasPaymentMethod) {
      label = <Text>{label} <Text color='form'>(payment method on file)</Text></Text>
    }

    return {text, label, value: item};
  }

  return render();
}

StudentDropdown.copy = StudentUtils.getStudentName;

StudentDropdown.find = async function(student:string | Partial<Student>, props:StudentDropdownProps):Promise<Partial<Student>> {
  if (typeof student == 'object') {
    return student.id ? student : null;
  }

  student = student.trim();

  // remove consecutive spaces
  student = student.replace(/\s+/, ' ');
  
  // the server doesn't support searching last, first
  if (student.indexOf(',') != -1) {
    const [last, first] = student.split(',').map(s => s.trim());
    student = `${first} ${last}`
  }

  // remove nicknames
  const parens = /\b([^()\s]+)\s*\([^()\s]+\)\s*([^()\s]+)\b/.exec(student)
  if (parens) {
    student = `${parens[1]} ${parens[2]}`
  }

  const vars = entityPropsToSiteOrCompany(props);
  return findQueryOption(students, {...vars, term: student}, student, compareStudentNames);
}

function compareStudentNames(pasted:string, student:Partial<Student>) {
  // getStudentName sometimes adds in nickname and we also want to support
  // finding by first last
  return caselessEqual(pasted, StudentUtils.getStudentName(student as any)) ||
    (student.name && caselessEqual(pasted, student.name)) || 
    (student.firstName && student.lastName && caselessEqual(pasted, `${student.firstName} ${student.lastName}`))
}

function entityPropsToSiteOrCompany(props:StudentDropdownProps) {
  if (props.entityKind?.toLocaleLowerCase() == 'site') {
    return {site: props.entityId}
  }

  if (props.entityKind?.toLocaleLowerCase() == 'company') {
    return {company: props.entityId}
  }

  return {site: props.site, company: props.company};
}
