import moment from 'moment';
import pluralize from 'pluralize';
import { first, flatten, last, castArray, intersection, groupBy } from 'lodash-es';

import { Site, CourseDay, Course, CourseKind, CourseStatus, EntityKind, Grade, MemberRole, Rates, Prices, PriceConfigKind, RecurringPriceConfig, RecurringRate, RecurringPrice, RecurringUnit, SeasonPriceConfig, SeasonRate, UsagePriceConfig, UsageRate, UsagePrice, UsageUnit, SeasonPrice } from '../graphql';
import { DeepPartial } from '../'

import { CourseDayUtils, COURSE_TIME_FORMAT } from './CourseDayUtils';
import { CourseDayName } from './CourseDayName';
import { EnrollmentUtils } from './EnrollmentUtils';
import { GRADE_LABEL_TO_POSITION, WEEKDAYS, DAY_ABBREVIATION, DAY_SORT } from './constants';
import { formatAges } from './formatAge';

// hack to allow returning session dates with course day info
// because a lot of the code was written before this was needed (see getSessionDays)
export type SessionDate = moment.Moment & {courseDay:Partial<CourseDay>};
export type CourseWithDates = Pick<Course, 'courseDays'> & Partial<Pick<Course, 'enrollmentOpens' | 'enrollmentCloses' | 'noEnrichmentDays' | 'startDate' | 'endDate'>>;
export type CourseWithRatesOrPrices = Partial<Pick<Course, 'courseDays'>> & ({rates?:Partial<Rates>, prices?:Partial<Prices>});
export type CourseWithOptions = DeepPartial<Pick<Course, 'hasOptions' | 'options'>>;
export type CourseWithRatesAndOptions = Pick<Course, 'status' | 'rates'> & CourseWithOptions;
export type CourseWithCompanyRole = {vendor?:{company?:{id:string, userCompanyRole?:MemberRole}}, company?:{id:string, userCompanyRole?:MemberRole}};
export type CourseWithSiteRole = {site?:{id:string, userSiteRole?:MemberRole}};
export type CourseWithRoles = CourseWithCompanyRole & CourseWithSiteRole;
export type CourseWithAbilities = Pick<Course, 'status' | 'rates' | 'kind'> & CourseWithRoles & {site?: Pick<Site, 'partner'>};

export class CourseUtils {
  static getCourseAbilities(courseOrCourses:CourseWithAbilities | CourseWithAbilities[], all?:boolean) {
    let sendable:boolean;
    let cancelable:boolean;
    let deletable:boolean;
    let finalizable:boolean;
    let approvable:boolean;
    let enrollable:boolean;
    let moveable:boolean;
    let promoteable:boolean;
    let seasonChangeable:boolean;
    let optionsChangeable:boolean;
    let addTimeSlots:boolean;

    function compare(a:boolean, b:boolean) {
      return a === undefined ? b : (all ? a && b : a || b);
    }

    if (courseOrCourses) {
      const courses = castArray(courseOrCourses);

      courses?.forEach(c => {
        sendable = compare(sendable, cu.isSendable(c));
        cancelable = compare(cancelable, cu.isCancelable(c));
        deletable = compare(deletable, cu.isDeletable(c));
        finalizable = compare(finalizable, cu.isFinalizable(c));
        approvable = compare(approvable, cu.isApprovable(c));
        enrollable = compare(enrollable, cu.isEnrollable(c));
        moveable = compare(moveable, cu.isMoveable(c));
        promoteable = compare(promoteable, cu.isPromoteable(c));
        seasonChangeable = compare(seasonChangeable, cu.isSeasonChangeable(c));
        optionsChangeable = compare(optionsChangeable, cu.isOptionChangeable(c));
        addTimeSlots = compare(addTimeSlots, c.kind == CourseKind.LessonSet);
      });
    }

    return {sendable, cancelableOrDeletable: cancelable || deletable, cancelable, deletable, finalizable, approvable, enrollable, moveable, promoteable, seasonChangeable, optionsChangeable, addTimeSlots};
  }

  static hasSiteRole(course: CourseWithRoles) {
    return !!course?.site?.userSiteRole;
  }

  static hasCompanyRole(course: CourseWithRoles) {
    return !!course?.vendor?.company?.userCompanyRole || !!course?.company?.userCompanyRole;
  }

  static hasActionableRole(course: CourseWithRoles) {
    return cu.hasSiteRole(course) || cu.hasCompanyRole(course);
  }

  static isRequest(course: Pick<Course, 'status'>) {
    return [CourseStatus.Draft, CourseStatus.Request, CourseStatus.AwaitingApproval].includes(course?.status);
  }

  static isSendable(course: Pick<Course, 'status'> & CourseWithAbilities) {
    return (!course?.status || course?.status == CourseStatus.Draft) && cu.hasSiteRole(course) && !cu.hasCompanyRole(course) && course.site?.partner;
  }

  static isCancelable(course: Pick<Course, 'status'> & CourseWithRoles) {
    return [CourseStatus.Active, CourseStatus.Enrolling, CourseStatus.Upcoming].includes(course?.status) && cu.hasActionableRole(course);
  }

  static isDeletable(course: Pick<Course, 'status'> & CourseWithRoles) {
    return [CourseStatus.AwaitingApproval, CourseStatus.Request, CourseStatus.Draft].includes(course?.status) && cu.hasActionableRole(course);
  }

  static isFinalizable(course: Pick<Course, 'status'> & CourseWithAbilities) {
    return course.status == CourseStatus.Request && cu.hasCompanyRole(course) && course.site?.partner;
  }

  static isApprovable(course: Pick<Course, 'status'> & CourseWithAbilities) {
        // organizer
    return (cu.hasSiteRole(course) && course.status == CourseStatus.AwaitingApproval) || 
        // non-partner provider
      (!course.site?.partner && cu.hasCompanyRole(course) && cu.isApprovableStatus(course)) || 
        // org + provider
       (course.site?.partner && cu.hasCompanyRole(course) && cu.hasSiteRole(course) && cu.isApprovableStatus(course));
  }

  static isApprovableStatus(course: Pick<Course, 'status'> & CourseWithRoles) {
    return [CourseStatus.Draft, CourseStatus.Request, CourseStatus.AwaitingApproval].includes(course.status);
  }

  static isEnrollable(course: Pick<Course, 'status'>) {
    return [CourseStatus.Active, CourseStatus.Enrolling, CourseStatus.Upcoming].includes(course?.status);
  }

  static isEnrollmentUpdateable(course: Pick<Course, 'status'>) {
    return [CourseStatus.Active, CourseStatus.Enrolling, CourseStatus.Upcoming].includes(course?.status);
  }

  static isMoveable(course: Pick<Course, 'status' | 'rates'> & Partial<Pick<Course, 'kind'>>) {
    return [CourseStatus.Active, CourseStatus.Enrolling, CourseStatus.Upcoming].includes(course?.status) && 
      (cu.usingSeasonRateOrPrice(course.rates) || (cu.usingRecurringRatesOrPrices(course.rates) && [CourseKind.LessonSet, CourseKind.TimeSlot].includes(course.kind)));
  }

  static isPromoteable(course: Pick<Course, 'status'>) {
    return [CourseStatus.Active, CourseStatus.Enrolling, CourseStatus.Upcoming].includes(course?.status);
  }

  static isScheduled(course: Pick<Course, 'status'>) {
    return [CourseStatus.Active, CourseStatus.Completed, CourseStatus.Enrolling, CourseStatus.Upcoming].includes(course?.status);
  }

  static isOrWasScheduled(course: Pick<Course, 'status'>) {
    return cu.isScheduled(course) || [CourseStatus.Cancelled].includes(course?.status);
  }

  static isRecurringChangeable(course: Pick<Course, 'status' | 'rates'>) {
    return cu.usingRecurringRatesOrPrices(course.rates) &&
      [CourseStatus.Active, CourseStatus.Enrolling, CourseStatus.Upcoming].includes(course?.status) && cu.getRateOrPriceType(course.rates) == RateType.advanced;
  }

  static isSeasonChangeable(course: Pick<Course, 'status' | 'rates'>) {
    return cu.usingConfigurableSeasonRatesOrPrices(course.rates) &&
      [CourseStatus.Active, CourseStatus.Enrolling, CourseStatus.Upcoming].includes(course?.status) && cu.getRateOrPriceType(course.rates) == RateType.advanced;
  }

  static getEntityInfo(course:CourseWithRoles) {
    return cu.hasSiteRole(course) 
      ? {entityKind: EntityKind.Site, entityId: course.site.id} 
      : cu.hasCompanyRole(course) 
        ? {entityKind: EntityKind.Company, entityId: course.vendor?.company?.id || course.company.id }
        : null;
  }

  static hasOptions(course:CourseWithOptions) {
    return course.hasOptions || Boolean(course.options?.length);
  }

  static isOptionChangeable(course:CourseWithRatesAndOptions) {
    return [CourseStatus.Active, CourseStatus.Enrolling, CourseStatus.Upcoming].includes(course?.status) && (cu.hasOptions(course));
  }

  // returns the days in a course based on start, end and course days.  omits any no enrichment days.
  // days are sorted earliest to latest.
  // if you override start/end date, they should be dates in the schools current timezone
  // be careful about mixing season start/end date (which are a time component) and course start/end date 
  // which do not have a time component)
  static getSessionDays(course:CourseWithDates, startDate?:string, endDate?:string, days?:string[]):SessionDate[] {
    if (!course) {
      return [];
    }

    const courseDates: SessionDate[] = [];
    const cur = moment(startDate || course.startDate);
    const end = moment(endDate || course.endDate);
    const courseDays = cu.getCourseDaysSet(course);

    if (days) {
      courseDays.forEach((_, day) => {
        if (!days.includes(day)) {
          courseDays.delete(day);
        }
      })
    }

    const noEnrichmentDates = cu.getNoEnrichmentDaysSet(course);

    // use exact times when comparing because that gets around
    // moment using local time (8 am) when comparing just days

    while (cur.isSameOrBefore(end)) {
      const day = cur.format('dddd');
      const date = cur.format('MM/DD/YYYY');

      if (courseDays.has(day) && !noEnrichmentDates.has(date)) {
        const cd = cur.clone() as SessionDate;
        cd.courseDay = courseDays.get(day);
        courseDates.push(cd);
      }

      cur.add(1, 'day');
    }

    return courseDates;
  }

  static getNextSession(courseOrSessions: SessionDate[] | CourseWithDates, weekdays?:string[]):moment.Moment {
    const sessionDates = Array.isArray(courseOrSessions) ? courseOrSessions : this.getSessionDays(courseOrSessions);
    const now = moment();
    const next = sessionDates.find(m => m.isAfter(now, 'day') && (!weekdays || intersection(WEEKDAYS, weekdays).length));

    return next;
  }

  static getPeriods(course:CourseWithDates, unit: RecurringUnit | UsageUnit.Day) {
    const sessions = this.getSessionDays(course);
    const today = moment().startOf('day');
    const momentUnit = unit == 'WEEK' ? 'isoWeek' : 'month';
    let prev:SessionDate;
  
    for (const session of sessions) {
      if (session.isAfter(today, momentUnit)) {
        return cu.getPeriodInfo(prev, session, momentUnit);
      }

      prev = session;
    }

    return cu.getPeriodInfo(null, null, momentUnit);
  }

  static getPeriodInfo(cur:moment.Moment, next:moment.Moment, unit: 'month' | 'isoWeek') {
    return {
      cur: {start: cur?.clone().startOf(unit), end: cur?.clone().endOf(unit)},
      next: {start: next?.clone().startOf(unit), end: next?.clone().endOf(unit)}
    }
  }

  // returns an activities course dates settings for the legend for a calendar/date picker
  // pass in days to restrict to a subset of days, such as when subscribing to only a few days
  
  static getCourseDates(course:CourseWithDates, days?:string[]) {
    if (!course) {
      return {sessions:[] as SessionDate[]};
    }

    const sessions = cu.getSessionDays(course, null, null, days);
    const start = sessions?.[0];

    return {
      sessions,
      start,
      end: last(sessions),
      legend: [
      { name: 'Enrollment', bg: 'enrollment', start: course?.enrollmentOpens, end: course?.enrollmentCloses },
      { name: 'Activity days', bg: 'courseDay', days: sessions },
      { name: 'No activity days', bg: 'noEnrichment', days: cu.filterNoEnrichmentDatesToCourseDays(course) }
    ]}
  }

  static getCourseWeekdays(course: Pick<Course, 'courseDays'>): string[] {
    return Array.from(cu.getCourseDaysSet(course).keys());
  }

  static getCourseDaysSet(course: Pick<Course, 'courseDays'> & Partial<Pick<Course, 'children'>>): Map<string, Partial<CourseDay>> {
    const set = new Map<string, Partial<CourseDay>>();

    const courseDays = cu.getNestedCourseDays(course);
    courseDays.forEach(courseDay => courseDay?.days?.forEach(d => set.set(d, courseDay)));

    return set;
  }

  static getNestedCourseDays(course: DeepPartial<Pick<Course, 'courseDays'>> & Partial<Pick<Course, 'children'>>) {
    return (course?.children?.length ? flatten(course.children.map(c => c.courseDays)) :  course?.courseDays) || [];
  }

  // returns a dictionary of dates
  static getNoEnrichmentDaysSet(course: Partial<Pick<Course, 'noEnrichmentDays'>>): Set<string> {
    return new Set((course.noEnrichmentDays || []).map(noe => moment(noe).format('MM/DD/YYYY')));
  }

  static filterNoEnrichmentDatesToCourseDays(course: Partial<Pick<Course, 'noEnrichmentDays' | 'courseDays'>>):string[] {
    return course?.noEnrichmentDays?.filter(noe => {
      const day = moment(noe).format('dddd');

      return course.courseDays.find(cd => cd.days.includes(day));
    })
  }

  static filterCourses<T extends SortableFilterableCourse>(courses:T[], filterOptions: CourseFilterOptions): T[] {
    const optimized = {
      tags: new Set(castArray(filterOptions.tags || [])),
      grades: new Set(castArray(filterOptions.grades || [])),
      days: new Set(castArray(filterOptions.days || [])),
      ages: castArray(filterOptions.ages || [])
    }

    if (!courses) {
      return [];
    }

    return courses
      .filter(cu.containsAnyTag(optimized.tags))
      .filter(cu.containsAnyGrade(optimized.grades))
      .filter(cu.containsAnyAge(optimized.ages))
      .filter(cu.containsAnyDay(optimized.days))
      .filter(cu.isBetween(filterOptions.dates?.[0], filterOptions.dates?.[1]))
      .sort(cu.compareCourses)
      ;
    }
  
  static containsAnyTag(tags: Set<string>) {
    return function(course: SortableFilterableCourse) {
      const hasTagFilter = tags && tags.size != 0;
      const hasTags = course.courseTags?.length != 0;
      return (
        !hasTagFilter ||
        (!hasTags && tags.has('Other')) ||
        (hasTags && course.courseTags.find(tag => tags.has(tag.name)) != null)
      );
    };
  }
  
  static containsAnyGrade(grades: Set<string>) {
    return function(course: SortableFilterableCourse) {
      return (
        !grades ||
        grades.size == 0 ||
        (course.grades && course.grades.find(grade => grades.has(grade)) != null)
      );
    };
  }
  
  static containsAnyAge(ages:string[]) {
    return function(course: SortableFilterableCourse) {
      return !ages?.length || ages.some((ageIndex:string) => {
        if (typeof course.ageMin !== 'number') {
          return false;
        }

        const ageRange = ageRanges[Number(ageIndex)].value;
        const from = Math.max(ageRange.from, course.ageMin);
        const to = Math.min(ageRange.to, course.ageMax || ageRange.to);
        
        return from <= to;
      })
    };
  }

  static containsAnyDay(days: Set<string>) {
    return function(course: SortableFilterableCourse) {
      return (
        !days ||
        days.size == 0 ||
        (cu.getNestedCourseDays(course).find(
            courseDay => courseDay.days.find(day => days.has(day)) != null
          ) != null)
      );
    };
  }
  
  static isBetween(start:moment.Moment, end:moment.Moment) {
    return function(course: SortableFilterableCourse) {
      return (
        !start || !end || !course.startDate || !course.endDate ||
        (moment(course.startDate).isSameOrBefore(end) && moment(course.endDate).isSameOrAfter(start))
      );
    };
  }

  // groups courses by day of the week (not day of year)
  // then within each group they are sorted by time
  // then returns them in an array sorted by day of week
  static groupCoursesByDay<T extends SortableFilterableCourse>(courses: T[]): DayCourses<T>[] {
    const dayCourses: DayCoursesMap<T> = WEEKDAYS.reduce(
      (courses, day) => {
        courses[day] = { day, courses: [] };
        return courses;
      },
      {} as DayCoursesMap<T>
    );
  
    // group by day
    courses.forEach(course => {
      cu.getNestedCourseDays(course).forEach((courseDay: Partial<CourseDay>) => {
        courseDay.days.forEach(day => {
          const coursesForDay = dayCourses[day as CourseDayName].courses;

          if (coursesForDay.indexOf(course) == -1) {
            coursesForDay.push(course)
          }
        });
      });
    });
  
    // sort by time
    WEEKDAYS.forEach(day =>
      dayCourses[day].courses.sort((a, b) => cu.compareCourseTimes(a, b, day))
    );
  
    // convert the map to an array sorted by day
    return WEEKDAYS.map(day => dayCourses[day]);
  }
  
  static getCourseDayTime(course: SortableFilterableCourse, day: CourseDayName) {
    return cu.getNestedCourseDays(course).find(courseDay => courseDay.days.indexOf(day) != -1);
  }
  
  static compareCourseTimes(courseA: SortableFilterableCourse, courseB: SortableFilterableCourse, day: CourseDayName): number {
    const courseDayA = cu.getCourseDayTime(courseA, day);
    const courseDayB = cu.getCourseDayTime(courseB, day);
    const timeA = moment(courseDayA.start, COURSE_TIME_FORMAT);
    const timeB = moment(courseDayB.start, COURSE_TIME_FORMAT);
  
    if (timeA.isSame(timeB)) {
      return 0;
    }
  
    return timeA.isBefore(timeB) ? -1 : 1;
  }
    
  // for a given course it collects course times
  // that are the same across days, and returns them
  // such that the days for each time are sorted
  // and the days across the times are sorted
  // such as:
  //  Su: 12pm - 1pm
  //  M, W, F: 2pm - 3pm
  //  T, Th: 3pm - 4pm
  //  Sa: 11am - 1pm
  static getMeetingTimes(site:Pick<Site, 'timezone'>, course: SortableFilterableCourse): { days: string[]; time: string }[] {
    const times = cu.groupCourseDaysByTime(site, course);
    const ret: { days: CourseDayName[]; time: string }[] = [];
  
    // convert map returned by groupCourseDaysByTime to an array
    times.forEach((days, time) => ret.push({ days, time }));
  
    // sort the days within the array
    ret.sort((a, b) => DAY_SORT[a.days[0]] - DAY_SORT[b.days[0]]);
  
    // map the days to the abbreviations
    return ret.map(meets => {
      return {
        days: meets.days.map(day => DAY_ABBREVIATION[day]),
        time: meets.time
      };
    });
  }
  
  // helper for getMeetingTimes that groups courses by time
  static groupCourseDaysByTime(site:Pick<Site, 'timezone'>, course: SortableFilterableCourse): Map<string, CourseDayName[]> {
    const times: Map<string, CourseDayName[]> = new Map();
  
    cu.getNestedCourseDays(course).forEach((courseDay: Partial<CourseDay>) => {
      courseDay.days.forEach(day => {
        const start = cu.convertTime(courseDay.start, site.timezone);
        const end = cu.convertTime(courseDay.finish, site.timezone);
        const time = start + ' - ' + end;
  
        if (!times.has(time)) {
          times.set(time, []);
        }
  
        times.get(time).push(day as CourseDayName);
      });
    });
  
    //sorts the days for each time
    times.forEach((days, time) => {
      times.set(time, days.sort((a, b) => DAY_SORT[a] - DAY_SORT[b]));
    });
  
    return times;
  }
  
  // converts time from site time to current time and formats
  // it as short as possible (drops the minutes if not needed)
  static convertTime(time: string, timezone: string) {
    const FORMAT_LONG = 'h:mm a';
    const FORMAT_SHORT = 'ha';
  
    const momentTime = moment.tz(time, 'HH:mm A', timezone);
    return momentTime.format(
      momentTime.minutes() == 0 ? FORMAT_SHORT : FORMAT_LONG
    );
  }  
  
  static groupCoursesByGrade<T extends SortableFilterableCourse>(courses:T[], siteGrades?:Grade[]): GroupedCourses<T>[] {
    const groups = groupBy(courses, course => cu.formatCourseGrades(course, siteGrades));
    const grouped:GroupedCourses<T>[] = [];
  
    for (let group in groups) {
      grouped.push({label:group, courses:groups[group].sort(cu.compareCourses)});
    }
  
    return grouped.sort((a:GroupedCourses<T>, b:GroupedCourses<T>) => cu.compareGrades(a.courses[0], b.courses[0]));
  }

  static formatCourseGrades(course:Pick<Course, 'grades'> & {site?:{grades?:Grade[]}}, siteGrades?:Grade[], includePrefix?:boolean) {
    const prefix = includePrefix !== false ? pluralize('Grade', course?.grades?.length): '';
    return !course || !course.grades || !course.grades.length
      ? ''
      : course.grades?.length < 2
        ? prefix + ' ' + cu.formatCourseGrade(0, course, siteGrades)
        : `${prefix} ${cu.formatCourseGrade(0, course, siteGrades)} - ${cu.formatCourseGrade(course.grades.length - 1, course, siteGrades)}`;
  }

  static groupCoursesByAge<T extends SortableFilterableCourse>(courses:T[]): GroupedCourses<T>[] {
    const groups = groupBy(courses, course => formatAges(course.ageMin, course.ageMax));
    const grouped:GroupedCourses<T>[] = [];
  
    for (let group in groups) {
      grouped.push({label:group, courses:groups[group].sort(cu.compareCourses)});
    }
  
    return grouped.sort((a:GroupedCourses<T>, b:GroupedCourses<T>) => cu.compareAges(a.courses[0], b.courses[0]));
  }

  static groupCoursesByWeek<T extends SortableFilterableCourse>(courses:T[], sortByCourseDays:boolean, includeWeekends:boolean = false): GroupedCourses<T>[] {
    const earliestStartDate = moment.min(courses.map(course => moment(course.startDate)));
    const startWeek = earliestStartDate.clone().startOf(includeWeekends ? 'week' : 'isoWeek');

    const hash = groupBy(courses, course => this.getWeekLabel(startWeek, course, includeWeekends));
    const grouped = Object.keys(hash).map(label => ({label, courses:hash[label]})).sort((a, b) => this.getWeek(startWeek, a.courses[0]) - this.getWeek(startWeek, b.courses[0]));

    grouped.forEach(group => group.courses.sort(sortByCourseDays ? this.compareCoursesDayTime : this.compareCourses));

    return grouped;
  }

  static getWeek<T extends SortableFilterableCourse>(startWeek:moment.Moment, course:T):number {
    const startDate = moment(course.startDate);
    const week = startDate.diff(startWeek, 'weeks');

    return week;
  }

  static getWeekLabel<T extends SortableFilterableCourse>(startWeek:moment.Moment, course:T, includeWeekends:boolean = false):string {
    const week = this.getWeek(startWeek, course);
    const weekStart = startWeek.clone().add(week, 'weeks');
    const weekEnd = weekStart.clone().endOf('week').subtract(includeWeekends ? 0 : 1, 'day');

    return `Week ${week + 1} (${weekStart.format('MMM D')} - ${weekEnd.format('MMM D, YYYY')})`
  }
  
  static formatCourseGrade(index:number, course:Pick<Course, 'grades'> & {site?:{grades?:Grade[]}}, siteGrades?:Grade[]) {
    const grade = course.grades[index];
    const gradeLabels = siteGrades || course.site?.grades;

    return !gradeLabels
      ? grade
      : gradeLabels.find(gradeInfo => gradeInfo.value == grade)?.label || grade
  }

  static sortCoursesByDate<T extends SortableCourse = SortableCourse>(courses:T[]) {
    return courses.slice().sort(cu.compareCourses);
  }

  static sortCoursesByGrade<T extends SortableCourse = SortableCourse>(courses:T[]) {
    return courses.slice().sort((c1:T, c2:T) => {
      return cu.usingAge(c1)
        ? cu.compareAges(c1, c2) || c1.name?.localeCompare(c2.name) || (moment(c1.startDate).valueOf() - moment(c2.startDate).valueOf())
        : cu.compareGrades(c1, c2) || c1.name?.localeCompare(c2.name) || (moment(c1.startDate).valueOf() - moment(c2.startDate).valueOf())
    });
  }

  static sortCoursesByCourseDayTime<T extends SortableCourse = SortableCourse>(courses:T[]) {
    return courses.slice().sort(cu.compareCoursesDayTime);
  }

  // TODO cleanup the old usage that is using time not days and is very confusing
  // so confusing that i didnt want to change the names and copied and created new functions

  static sortCoursesByCourseDayTime2<T extends SortableCourse = SortableCourse>(courses:T[]) {
    return courses.slice().sort(cu.compareCoursesDayTime2);
  }

  static compareCoursesDayTime2<T extends SortableCourse = SortableCourse>(c1:T, c2:T) {
    return CourseDayUtils.compareCourseDays2(c1.courseDays, c2.courseDays) || c1.name?.localeCompare(c2.name) || (moment(c1.startDate).valueOf() - moment(c2.startDate).valueOf());
  }

  static compareCourses<T extends SortableCourse = SortableCourse>(c1:T, c2:T) {
    return (moment(c1.startDate).valueOf() - moment(c2.startDate).valueOf()) || CourseDayUtils.compareCourseDays(c1.courseDays, c2.courseDays) || c1.name?.localeCompare(c2.name);
  }

  static compareCoursesDayTime<T extends SortableCourse = SortableCourse>(c1:T, c2:T) {
    return CourseDayUtils.compareCourseDays(c1.courseDays, c2.courseDays) || c1.name?.localeCompare(c2.name) || (moment(c1.startDate).valueOf() - moment(c2.startDate).valueOf());
  }

  static compareGrades<T extends SortableCourse = SortableCourse>(c1:T, c2:T) {
    const aValues = [GRADE_LABEL_TO_POSITION.get(first(c1.grades)), GRADE_LABEL_TO_POSITION.get(last(c1.grades))];
    const bValues = [GRADE_LABEL_TO_POSITION.get(first(c2.grades)), GRADE_LABEL_TO_POSITION.get(last(c2.grades))];

    return (aValues[0] - bValues[0]) || (aValues[1] - bValues[1]);
  }

  static compareAges<T extends SortableCourse = SortableCourse>(c1:T, c2:T) {
    const aValues = [c1.ageMin, c1.ageMax];
    const bValues = [c2.ageMin, c2.ageMax];

    return (aValues[0] - bValues[0]) || (aValues[1] - bValues[1]);
  }

  static usingAge(course:Pick<Course, 'ageMin' | 'ageMax'>) {
    return Boolean(course?.ageMin ?? course?.ageMax)
  }

  static getRateOrPriceState(rates:Partial<Rates> | Partial<Prices>) {
    const type = cu.getRateOrPriceType(rates);

    const enabled = {
      recurring: cu.usingRecurringRatesOrPrices(rates),
      dropIns: cu.usingDropInRateOrPrices(rates),
      season: cu.usingBasicSeasonRateOrPrice(rates),
      seasons: cu.usingConfigurableSeasonRatesOrPrices(rates),
      usage: cu.usingUsageRateOrPrice(rates)
    }

    // if nothing is configured then we set all rates to required
    const noRatesConfigured = (type == RateType.basic && !enabled.season);

    const required = {
      season: (enabled.season && !enabled.seasons) || noRatesConfigured,
      seasons: enabled.seasons || noRatesConfigured,
      recurring: enabled.recurring || noRatesConfigured,
      dropIn: enabled.dropIns || noRatesConfigured,
      usage: type == RateType.usage || noRatesConfigured
    }

    return { type, enabled, required, notConfigured: noRatesConfigured };
  }

  static schedulableAndNeedsPrompt(course:CourseWithRatesOrPrices) {
    return cu.usingDropInRateOrPrices(course.rates || course.prices) ||
      cu.usingMultipleUsageRatesOrPrices(course.rates || course.prices) ||
      cu.usingMultipleAdvancedRates(course.rates || course.prices) ||
      cu.usingConfigurationRequiredRecurringRatesOrPrices(course) ||
      cu.usingConfigurationRequiredSeasonRatesOrPrices(course)
  }

  static usingMultipleAdvancedRates(rates:Partial<Rates> | Partial<Prices>) {
    return Number(cu.usingRecurringRatesOrPrices(rates)) + Number(cu.usingDropInRateOrPrices(rates)) + Number(cu.usingSeasonRateOrPrice(rates)) > 1;
  }

  static usingConfigurableRates(rates:Partial<Rates> | Partial<Prices>) {
    return cu.usingDropInRateOrPrices(rates) || cu.usingRecurringRatesOrPrices(rates) || cu.usingConfigurableSeasonRatesOrPrices(rates)
  }

  static usingIncompatibleRates(rates:Partial<Rates> | Partial<Prices>) {
    return cu.usingUsageRateOrPrice(rates) && (cu.usingRecurringRatesOrPrices(rates) || cu.usingDropInRateOrPrices(rates) || cu.usingSeasonRateOrPrice(rates));
  }

  static usingMultipleRecurringUnitTypes(rates:Partial<Rates> | Partial<Prices>) {
    return new Set(cu.getValidRecurringRatesOrPrices(rates)?.map(r => r.unit)).size > 1;
  }

  static usingConfigurationRequiredRecurringRatesOrPrices(course:CourseWithRatesOrPrices) {
    const recurring = cu.getValidRecurringRatesOrPrices(course.rates || course.prices);

    if (!recurring?.length) {
      return false;
    }

    if (recurring.length > 1) {
      return true;
    }

    return !course.courseDays || recurring[0].days != cu.getCourseWeekdays(course as Pick<Course, 'courseDays'>).length;
  }

  static usingConfigurationRequiredSeasonRatesOrPrices(course:CourseWithRatesOrPrices) {
    const ratesOrPrices = cu.getValidConfigurableSeasonRatesOrPrices(course.rates || course.prices);

    if (!ratesOrPrices?.length) {
      return false;
    }

    if (ratesOrPrices.length > 1) {
      return true;
    }

    return !course.courseDays || ratesOrPrices[0].days != cu.getCourseWeekdays(course as Pick<Course, 'courseDays'>).length;
  }

  static usingRecurringRatesOrPrices(rates:Partial<Rates> | Partial<Prices>) {
    return cu.getValidRecurringRatesOrPrices(rates)?.length != 0;
  }

  static usingDropInRateOrPrices(rates:Partial<Rates> | Partial<Prices>) {
    return cu.isValidRateOrPrice(rates?.dropIn)
  }

  static usingSeasonRateOrPrice(rates:Partial<Rates> | Partial<Prices>) {
    return cu.usingBasicSeasonRateOrPrice(rates) || cu.usingConfigurableSeasonRatesOrPrices(rates);
  }

  static usingMultipleSeasonRatesOrPrices(rates:Partial<Rates> | Partial<Prices>) {
    let count = 0;
    count += cu.usingBasicSeasonRateOrPrice(rates) ? 1 : 0;
    count += cu.getValidConfigurableSeasonRatesOrPrices(rates).length;
    return count;
  }

  static usingBasicSeasonRateOrPrice(rates:Partial<Rates> | Partial<Prices>) {
    return cu.isValidRateOrPrice(rates?.season) || Number.isFinite((rates?.season as SeasonRate)?.materialsRate)
  }

  static usingConfigurableSeasonRatesOrPrices(rates:Partial<Rates> | Partial<Prices>) {
    return cu.getValidConfigurableSeasonRatesOrPrices(rates).length != 0;
  }

  static usingMultipleUsageRatesOrPrices(rates:Partial<Rates> | Partial<Prices>) {
    return (cu.getValidUsageRatesOrPrices(rates).length || 0) > 1;
  }

  static usingUsageRateOrPrice(rates:Partial<Rates> | Partial<Prices>) {
    return cu.getValidUsageRatesOrPrices(rates).length != 0;
  }

  // if there's an umabiguious price kind this will return it
  // only for use in the context of enrollment configuration–the only place PriceConfigKind.ConfigurableSeason is used
  static getDefaultPriceConfigKindForPrices(rates:Partial<Rates> | Partial<Prices>):PriceConfigKind | undefined {
    const season = cu.usingBasicSeasonRateOrPrice(rates);
    const seasons = cu.usingConfigurableSeasonRatesOrPrices(rates);
    const recurring = cu.usingRecurringRatesOrPrices(rates);
    const dropIn = cu.usingDropInRateOrPrices(rates);
    const usage = cu.usingUsageRateOrPrice(rates);

    if (season && !seasons && !recurring && !dropIn && !usage) {
      return PriceConfigKind.Season;
    } else if (seasons && !season && !recurring && !dropIn && !usage) {
      return PriceConfigKind.ConfigurableSeason;
    } else if (recurring && !season && !seasons && !dropIn && !usage) {
      return PriceConfigKind.Recurring;
    } else if (dropIn && !recurring && !season && !seasons && !usage) {
      return PriceConfigKind.DropIn;
    } else if (usage && !recurring && !dropIn && !season && !seasons) {
      return PriceConfigKind.Usage;
    } else {
      return;
    }
  }

  static hasBilledLaterRates(rates:Partial<Rates>) {
    return cu.getValidRecurringRatesOrPrices(rates).length || cu.getValidUsageRatesOrPrices(rates).length || cu.usingInstallments(rates);
  }

  static hasBilledLaterEnrollments(course: DeepPartial<Course>) {
    return course?.priceConfigs?.find(pc => pc.kind == PriceConfigKind.Recurring || pc.kind == PriceConfigKind.Usage || (pc.kind == PriceConfigKind.Season && (pc as SeasonPriceConfig).usingInstallments));
  }

  static getRateOrPriceType(rates:Partial<Rates> | Partial<Prices>) {
    if (cu.getValidUsageRatesOrPrices(rates).length) {
      return RateType.usage;
    }

    if (cu.getValidRecurringRatesOrPrices(rates).length || cu.isValidRateOrPrice(rates?.dropIn) || cu.getValidConfigurableSeasonRatesOrPrices(rates).length) {
      return RateType.advanced;
    }

    return RateType.basic;
  }

  static isValidRateOrPrice(config:{rate?:number, price?:number}) {
    return Number.isFinite(config?.rate) || Number.isFinite(config?.price);
  }

  static getValidRecurringRatesOrPrices(rates:Partial<Rates> | Partial<Prices>) {
    return (rates?.recurring as (RecurringRate | RecurringPrice)[])?.filter(s => !!s.days || cu.isValidRateOrPrice(s) || !!s.unit) || [];
  }

  static getValidConfigurableSeasonRatesOrPrices(rates:Partial<Rates> | Partial<Prices>) {
    return (rates?.seasons as (SeasonRate | SeasonPrice)[])?.filter(s => !!s.days || cu.isValidRateOrPrice(s)) || [];
  }

  static getValidUsageRatesOrPrices(rates:Partial<Rates> | Partial<Prices>) {
    return (rates?.usage as (UsageRate | UsagePrice)[])?.filter(u => cu.isValidRateOrPrice(u) || !!u.unit || !!(u as UsageRate).roundingIncrement) || [];
  }

  static findRecurringPriceForConfig(prices:Partial<Prices>, priceConfig:Partial<RecurringPriceConfig>) {
    if (!priceConfig) {
      return null;
    }

    return prices.recurring?.find(p => p.unit == EnrollmentUtils.unit({priceConfig}) && p.days == priceConfig.weekdays?.length);
  }

  static findConfigurableSeasonPriceForConfig(prices:Partial<Prices>, priceConfig:Partial<SeasonPriceConfig>) {
    if (!priceConfig) {
      return null;
    }

    return prices.seasons?.find(p => p.days == priceConfig.weekdays?.length);
  }

  static findUsagePriceForConfig(prices:Partial<Prices>, priceConfig:UsagePriceConfig) {
    return prices.usage?.find(p => p.unit == EnrollmentUtils.unit({priceConfig}));
  }

  // slightly different than usingInstallments in that usingInstallments is for
  // detecting priceConfigs from the server that aren;t using installments
  // and this is looking for any temp values (for the benefit of the UI knowing
  // that we shouldn't disable things so the user can remove these values if they want)

  static hasInstallmentValues(rates:Partial<Rates>) {
    return (rates?.season?.depositAmount !== undefined && rates?.season?.depositAmount !== null) || Boolean(rates?.season?.installmentDates?.length);
  }

  static usingInstallments(courseOrRates: DeepPartial<Course> | Partial<Rates>) {
    if (!courseOrRates) {
      return false;
    }

    const rates = "rates" in courseOrRates ? courseOrRates.rates : courseOrRates as Partial<Rates>;
    const seasonRates = [rates.season].concat(rates?.seasons || []).filter(s => s);
    return seasonRates.some(s => s.installmentDates?.length);
  }

  static usingDeposit(courseOrRates: DeepPartial<Course> | Partial<Rates>) {
    if (!courseOrRates) {
      return false;
    }

    const rates = "rates" in courseOrRates ? courseOrRates.rates : courseOrRates as Partial<Rates>;
    const seasonRates = [rates.season].concat(rates?.seasons || []).filter(s => s);
    return seasonRates.some(s => s.depositAmount !== undefined && s.depositAmount !== null);
  }

  static freeCourse(course:CourseWithRatesOrPrices) {
    const ratesOrPrices = course.rates || course.prices;
    const rates = [ratesOrPrices.dropIn].concat(...ratesOrPrices.recurring || []).concat(ratesOrPrices.season).concat(ratesOrPrices.seasons || []).concat(ratesOrPrices.usage || []).filter(r => r);

    return rates.every(r => !(r as any).rate && !(r as any).price);
  }

  static generatedGameName(game:{homeTeam?:Pick<Course, 'name' | 'disambiguatedName'>, awayTeam?:Pick<Course, 'name' | 'disambiguatedName'>}) {
    return `${game?.awayTeam?.disambiguatedName || game?.awayTeam?.name || ''} at ${game?.homeTeam?.disambiguatedName || game?.homeTeam?.name || ''}`;
  }
}

// normally you can use this.fnName() inside of CourseUtils code, but we've seen
// cases where the this pointer is not bound correctly, so this allows shorthand
// over CourseUtils.fnName() which can get long.
const cu = CourseUtils;

export enum RateType {
    basic,
    advanced,
    usage
}

// from/to are in months to match course
const ageRanges = [
  {label: 'under 1', value: {from: 0, to: 11}},
  {label: '1', value: {from: 12, to: 23}},
  {label: '2', value: {from: 24, to: 35}},
  {label: '3', value: {from: 36, to: 47}},
  {label: '4', value: {from: 48, to: 59}},
  {label: '5', value: {from: 60, to: 71}},
  {label: '6', value: {from: 72, to: 83}},
  {label: '7', value: {from: 84, to: 95}},
  {label: '8', value: {from: 96, to: 107}},
  {label: '9', value: {from: 108, to: 119}},
  {label: '10', value: {from: 120, to: 131}},
  {label: '11', value: {from: 132, to: 143}},
  {label: '12', value: {from: 144, to: 155}},
  {label: '13', value: {from: 156, to: 167}},
  {label: '14', value: {from: 168, to: 179}},
  {label: '15', value: {from: 180, to: 191}},
  {label: '16', value: {from: 192, to: 203}},
  {label: '17', value: {from: 204, to: 215}},
  {label: 'Adult (18+)', value: {from: 215, to: 11988}}
]

export const ageOptions = ageRanges.map((r, index) => ({label: r.label, value: index.toString()}));

export interface CourseFilterOptions {
  grades?:string | string[];
  ages?:string | string[];
  tags?:string | string[];
  days?:string | string[];
  dates?:moment.Moment[];
  byGrade?:boolean;
  byAge?:boolean;
}

type SortableCourse = DeepPartial<Pick<Course, 'name' | 'courseDays' | 'grades' | 'startDate'| 'ageMin' | 'ageMax'>>;
type SortableFilterableCourse = DeepPartial<Pick<Course, 'id' | 'name' | 'courseTags' | 'courseDays' | 'grades' | 'startDate' | 'endDate' | 'ageMin' | 'ageMax'>>;

export interface DayCourses<T extends SortableFilterableCourse> {
  day: CourseDayName;
  courses: T[];
}

export type DayCoursesMap<T extends SortableFilterableCourse> = {
  [k in CourseDayName]?: DayCourses<T>;
};


export interface GroupedCourses<T extends SortableFilterableCourse> {
  label: string;
  courses: T[];
}

